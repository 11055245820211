/* eslint-enable */
import React from 'react'
import { Navigate } from 'react-router-dom';
import { useStateAuthContext, useStateHostContext } from '../context'
import { spin as Spin, formatRupiah } from '../utils';
import { success, error } from '../notify';
import { FaCheck, FaCheckCircle, FaFile, FaTimesCircle } from 'react-icons/fa';
const Opd_kendaraan_verifikasi_pkb = () => {
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [data_verifikasi, set_data_verifikasi] = React.useState([])
  const [loading, set_loading] = React.useState(false)

  const getPKBReport = React.useCallback(async () => {
    set_loading(true)

    const requ = await fetch(`${HOST}/user/kendaraan/pkb/verifikasi/menunggu`, {
      method: 'GET',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      }
    })
    const resl = await requ.json()
    if (resl.s) {
      set_data_verifikasi(resl.d)
      set_loading(false)
    }
  }, [HOST, STATE_AUTH])


  const handleAccept = async (id_pkb) => {
    const formData = new FormData()
    formData.append('ID_PKB', id_pkb)
    const requ = await fetch(`${HOST}/user/kendaraan/pkb/verifikasi/terima`, {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      },
      body: formData
    })
    const resl = await requ.json()
    if (resl.s) {
      success({ msg: resl.m })
      getPKBReport()
    } else {
      error({ msg: resl.m })
    }
  }

  const handleReject = async (id_pkb) => {
    const formData = new FormData()
    formData.append('ID_PKB', id_pkb)
    const requ = await fetch(`${HOST}/user/kendaraan/pkb/verifikasi/tolak`, {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      },
      body: formData
    })
    const resl = await requ.json()
    if (resl.s) {
      success({ msg: resl.m })
      getPKBReport()
    } else {
      error({ msg: resl.m })
    }
  }

  React.useEffect(() => {
    getPKBReport()
  }, [getPKBReport])

  if (!STATE_AUTH.is_login) {
    return <Navigate to='/' />
  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><FaCheck /> VERIFIKASI PEMBAYARAN PKB</strong></h3>
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className='tb-daftar-dinas' style={{ fontSize: '13px', width: '100%' }} cellPadding={5} cellSpacing={0}>
                <thead>
                  <tr>
                    <th width="2%">#</th>
                    <th>KODE KENDARAAN</th>
                    <th>NAMA KENDARAAN</th>
                    <th>TANGGAL JATUH TEMPO</th>
                    <th>JUMLAH PKB</th>
                    <th>FILE BUKTI PEMBAYARAN</th>
                    <th>VERIFIKASI</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !loading
                      ?
                      data_verifikasi.length !== 0
                        ?
                        data_verifikasi.map((val, ind) => {
                          return (<tr key={ind}>
                            <td>{ind + 1}</td>
                            <td>{val.kode_barang}</td>
                            <td>{val.nama_barang}</td>
                            <td>{val.tgl_jatuh_tempo}</td>
                            <td>{formatRupiah(val.rp_pkb)}</td>
                            <td><a className='btn btn-sm btn-info d-flex align-items-center' href={val.file_pkb_terbayar} target="_blank" rel="noreferrer"><FaFile /> &nbsp; Preview</a></td>
                            <td>
                              <button className='btn btn-sm btn-success d-flex align-items-center' onClick={handleAccept.bind(this, val.id_pkb)}><FaCheckCircle />&nbsp; Terima</button>
                              <button className='btn btn-sm btn-danger d-flex align-items-center' onClick={handleReject.bind(this, val.id_pkb)}><FaTimesCircle />&nbsp; Tolak</button>
                            </td>
                          </tr>)
                        })
                        :
                        <tr>
                          <td colSpan={7} className="text-center">(Data PKB Tidak Tersedia.)</td>
                        </tr>
                      :
                      <tr>
                        <td colSpan={7} className="text-center"><Spin /></td>
                      </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Opd_kendaraan_verifikasi_pkb