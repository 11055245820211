import React from 'react'
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { FaCarAlt, FaCog, FaFileAlt, FaSearch, FaUserAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useStateAuthContext } from '../context';

const Admin_dashboard = () => {
    const direct = useNavigate();
    const { STATE_AUTH } = useStateAuthContext();

    const daftar_modul = [
        {
            id: 1,
            icon: <FaCarAlt size={42} />,
            title: 'Kendaraan',
            url: '/admin/kendaraan'
        },
        {
            id: 2,
            icon: <FaCarAlt size={42} />,
            title: 'Kendaraan Lelang',
            url: '/admin/kendaraan_lelang'
        },
        {
            id: 3,
            icon: <FaCarAlt size={42} />,
            title: 'Kendaraan Mutasi',
            url: '/admin/kendaraan_mutasi'
        },
        {
            id: 4,
            icon: <FaSearch size={42} />,
            title: 'Cari Kendaraan',
            url: '/admin/kendaraan_cari'
        },
        {
            id: 5,
            icon: <FaFileAlt size={42} />,
            title: 'Laporan Kendaraan',
            url: '/admin/laporan/kendaraan'
        },
        {
            id: 6,
            icon: <FaFileAlt size={42} />,
            title: 'Laporan PKB',
            url: '/admin/laporan/pkb'
        },
        {
            id: 7,
            icon: <FaUserAlt size={42} />,
            title: 'Pengguna Sistem',
            url: '/admin/pengguna'
        },
        {
            id: 8,
            icon: <FaCog size={42} />,
            title: 'Pengaturan',
            url: '/admin/pengaturan'
        }
    ];
    return (
        <main>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-12 mb-1">
                        <div className='alert alert-info p-1 d-flex m-0' style={{ borderRadius: 0 }}>
                            <BsFillPersonCheckFill size={42} />
                            <div className="mx-3">
                                <p className='p-0 m-0' style={{ fontSize: '14px' }}> Pengguna sistem : <strong>{STATE_AUTH.nama_lengkap}</strong> </p>
                                {STATE_AUTH.is_super_admin && <><p style={{ fontSize: '14px' }} className='p-0 m-0'>Status akses : <span className='p-0 m-0 fw-bold' style={{ fontSize: '14px' }}>SUPER ADMIN</span></p></>}
                                {STATE_AUTH.is_admin && <><p style={{ fontSize: '14px' }} className='p-0 m-0'>Status akses : <span className='p-0 m-0 fw-bold' style={{ fontSize: '14px' }}>ADMIN</span></p></>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="alert alert-warning">
                            <div style={{ lineHeight: 0.8 }}>
                                <h3>Selamat datang di <strong>SiKENDIS (Sistem Informasi Kendaraan Dinas) versi 2.1</strong></h3>
                                <p>Sistem ini digunakan untuk Manajemen Aset Kendaraan Dinas, Pemantauan dan Pelaporan Pajak Kendaraan Bermotor</p>
                                <p>Untuk pengecekan pajak kendaraan silahkan klik tautan dibawah</p>
                            </div>
                            <a className='btn btn-sm btn-primary' target="_blank" rel="noreferrer" href="https://play.google.com/store/search?q=info+pajak+kendaraan+sulut&c=apps"><i className='fab fa-google-play'></i> Download Playstore</a>
                            <a className='btn btn-sm btn-success mx-2' target="_blank" rel="noreferrer" href="https://bapenda.sulutprov.go.id/index.php?page=cekpajak"><i className='fa fa-globe'></i> Online BAPENDA</a>
                            <a className='btn btn-sm btn-warning' target="_blank" rel="noreferrer" href="https://cekpajak.com/sulawasi-utara"><i className='fa fa-globe'></i> Online e-SAMSAT</a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    {
                        daftar_modul.map((mod, index) => {
                            return (
                                <div className="col-md-3 mt-3" key={index}>
                                    <div className="card">
                                        <button className='btn btn-outline-secondary' onClick={() => direct(mod.url)}>
                                            <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                <div className=' flex-fill'>
                                                    <p>{mod.title}</p>
                                                    {/* <h2>{data.length !== 0 ? data.d[mod.key] : 0}</h2> */}
                                                </div>
                                                <div className=' flex-fill text-end'>
                                                    {mod.icon}
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
        </main>
    )
}

export default Admin_dashboard
