import React from 'react'
import logo from '../assets/img/logo.png'
import { useStateAuthContext } from '../context';
import { FaBars } from 'react-icons/fa';
import { getBreakpoint } from '../utils';

const Navbar = () => {
      const { STATE_AUTH } = useStateAuthContext()

      let bgc;
      if (STATE_AUTH.is_super_admin) {
            bgc = '#072227'
      } else if (STATE_AUTH.is_admin) {
            bgc = '#072227'
      } else if (STATE_AUTH.is_opd) {
            bgc = '#393E46'
      } else {
            bgc = '#333'
      }


      return (
            <nav className="sb-topnav navbar navbar-expand navbar-dark" style={{ backgroundColor: bgc, borderBottom: '2px solid red' }}>
                  <div className="container-fluid">
                        <a className="navbar-brand ps-3" href="#!">
                              <div className='d-flex'>
                                    <div className='mx-1'>
                                          <img src={logo} alt="logo" width="30" />
                                    </div>
                                    <div className='d-flex flex-column'>
                                          <span style={{ fontSize: 14, fontWeight: 'bold' }}>SiKENDIS</span>
                                          <span className='text-muted' style={{ fontSize: '10px' }}>{getBreakpoint().mode === "mobile" || getBreakpoint().mode === "tablet" ? null : 'KENDARAAN DINAS | v.2.1'}</span>
                                    </div>
                              </div>
                        </a>
                        <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><FaBars /></button>
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                              <li className="nav-item text-light fw-bold">{getBreakpoint().mode === "mobile" || getBreakpoint().mode === "tablet" ? STATE_AUTH.nama_opd_singkat : STATE_AUTH.nama_opd}</li>
                        </ul>
                  </div>
            </nav>
      )
}

export default Navbar;

