import React from 'react'
import { useStateAuthContext, useStateHostContext } from '../context';
import { FaCarAlt, FaFile } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { error } from '../notify';

const Admin_kendaraan_mutasi = () => {
  window.document.title = "Kendaraan mutasi"

  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();

  const [data_kendaraan, set_kendaraan] = React.useState([])
  const [loading, set_loading] = React.useState(false)

  const handleGetDataKendaraan = React.useCallback(async () => {
    set_loading(true)
    try {
      const request = await fetch(HOST + '/admin/kendaraan/mutasi', {
        method: 'GET',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        }
      })
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          set_kendaraan(response.d)
          set_loading(false)
        } else {
          error({ msg: response.m })
          set_loading(false)
        }
      } else {
        set_loading(false)
        throw new Error(response.m);
      }
    } catch (error) {
      error({ msg: error.message })
      set_loading(false)
    }
  }, [HOST, STATE_AUTH])

  React.useEffect(() => {
    handleGetDataKendaraan()
  }, [handleGetDataKendaraan])


  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><FaCarAlt /> KENDARAAN MUTASI</strong></h3>
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          <div class="col-md-12">
            {
              loading ?
                <div className="text-center">
                  <BeatLoader
                    size={15}
                  />
                </div>
                :
                <div className="table-responsive">
                  <table className='tb-daftar-dinas mt-1' >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nama Kendaraan</th>
                        <th>Merk Type</th>
                        <th>OPD Sebelum</th>
                        <th>OPD Sesudah</th>
                        <th>File BAST</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data_kendaraan.length > 0 ?
                          data_kendaraan.map((val, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {val.nama_barang} <br />
                                  <small className='text-muted'>{val.kode_barang}</small>
                                </td>
                                <td>{val.merk_type}</td>
                                <td>{val.opd_sebelum}</td>
                                <td>{val.opd_sesudah}</td>
                                <td>
                                <div className='btn-group' role="group" aria-label="Basic example" >
                                  <button
                                    className='btn btn-sm btn-info'
                                    onClick={() => window.open(`${val.file_bast_mutasi}`, '_blank')}><FaFile className='me-1' size={15}/>
                                  </button>
                                </div>
                                </td>
                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td colSpan={6} className="text-center">Tidak ada data</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>
            }

          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_kendaraan_mutasi;