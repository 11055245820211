import Navbar from '../layouts/Navbar';
import Sidebar from '../layouts/Sidebar';
import React from 'react';
import '../assets/css/styles.css'
import $ from 'jquery'
import { NavLink } from 'react-router-dom';

$(document).ready(() => {
      $("#sidebarToggle").on("click", function (e) {
            e.preventDefault();
            $("body").toggleClass("sb-sidenav-toggled");
      })
});

const Page = (props) => {
      const { mod } = props;
      return (
            <div>
                  <div className="sb-nav-fixed">
                        <Navbar />
                        <div id="layoutSidenav">
                              <div id="layoutSidenav_nav">
                                    <Sidebar />
                              </div>
                              <div id="layoutSidenav_content" style={{ backgroundColor: '#FDFAF6' }}>
                                    <main>
                                          <div className="container-fluid ">
                                                {mod}
                                          </div>
                                    </main>
                                    <footer className="py-4 bg-light mt-auto">
                                          <div className="container-fluid">
                                                <div className="d-flex align-items-center justify-content-between small">
                                                      <div className="text-muted">Copyright &copy; SiKENDIS 2023 | Badan Pengelolah Keuangan Daerah (BPKD) Kota Kotamobagu</div>
                                                      <div>
                                                            <NavLink to="#privacy">Privacy Policy</NavLink>
                                                            &middot;
                                                            <NavLink to="#kondision">Terms &amp; Conditions</NavLink>
                                                      </div>
                                                </div>
                                          </div>
                                    </footer>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default Page;
