import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { BsTrash, BsFillEyeFill, BsFillInfoCircleFill, BsArrowLeftCircleFill, BsPencilFill } from 'react-icons/bs';
import { FaCheck, FaFileAlt, FaInfoCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { FaSyncAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStateAuthContext, useStateHostContext } from '../context'
import { error, success } from '../notify';
import { formatRupiah } from '../utils'
import { BeatLoader } from 'react-spinners';

const Opd_kendaraan_detail = () => {
  const direct = useNavigate();
  const { state } = useLocation()
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const form_mutasi = React.useRef()
  const [daftar_menu, set_menu] = React.useState({})
  const [data_detail, set_data_detail] = React.useState([])
  const [show_modal_bpkb, set_show_bpkb] = React.useState(false)
  const [show_modal_stnk, set_show_stnk] = React.useState(false)
  const [show_modal_fotken, set_show_fotken] = React.useState(false)
  const [show_modal_mutasi, set_show_mutasi] = React.useState(false)
  const [file_bpkb, set_file_bpkb] = React.useState("")
  const [file_stnk, set_file_stnk] = React.useState("")
  const [nama_file_fotken, set_nama_file_fotken] = React.useState("")
  const [file_fotken, set_file_fotken] = React.useState("")
  const [is_upload, set_upload] = React.useState(false)
  const [is_mutasi, set_mutasi] = React.useState(false)
  const [opd_tujuan, set_opd_tujuan] = React.useState("")
  const [file_bast, set_file_bast] = React.useState("")
  const [opd, set_opd] = React.useState([])
  const [nama_pihak_pertama, set_nama_pihak_pertama] = React.useState("")
  const [nip_pihak_pertama, set_nip_pihak_pertama] = React.useState("")
  const [nama_pihak_kedua, set_nama_pihak_kedua] = React.useState("")
  const [nip_pihak_kedua, set_nip_pihak_kedua] = React.useState("")

  /**
   * -------------------------------------------------------------------------
   * VARIABLE BARU
   * -------------------------------------------------------------------------
   * */
  const [lelang, set_lelang] = React.useState("")
  const [status_kondisi, set_status_kondisi] = React.useState("baik")
  const [file_status_kondisi, set_file_status_kondisi] = React.useState(null)
  const [status_barang, set_status_barang] = React.useState("tersedia")
  const [keterangan_pinjam_pakai, set_keterangan_pinjam_pakai] = React.useState("")
  const [file_status_barang, set_file_status_barang] = React.useState(null)
  const [file_penilian_lelang, set_file_penilaian_lelang] = React.useState(null)
  const [loading_status_kondisi, set_loading_status_kondisi] = React.useState(false)
  const [loading_status_barang, set_loading_status_barang] = React.useState(false)
  const [loading_status_penilaian, set_loading_status_penilaian] = React.useState(false)
  const ref_file_status_barang = React.useRef();

  /**
   * ------------------------------------------------------------------------
   * BATAS VARIABLE BARU
   * ------------------------------------------------------------------------
   */

  const getDetailKendaraan = React.useCallback(async () => {
    const param = {
      method: 'GET',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      }
    }
    const requ = await fetch(`${HOST}/user/kendaraan/detail/${state.id_kendaraan}`, param)
    const resl = await requ.json()
    set_data_detail(resl.d)
  }, [HOST, STATE_AUTH, state])

  const getAksesMenu = React.useCallback(async () => {
    const requ = await fetch(`${HOST}/admin/pengguna/menu/ambil/${STATE_AUTH.id_pengguna}`, { method: 'GET', headers: { "REST-TOKEN": STATE_AUTH.token } })
    try {
      const resu = await requ.json()
      if (resu.s) {
        set_menu(resu.d)
      }
    } catch (err) {
      error({ msg: err.message })
    }
  }, [HOST, STATE_AUTH])

  const getOpd = React.useCallback(async () => {
    const param = {
      method: 'get',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      }
    }

    const requ = await fetch(HOST + '/opd', param);
    const resp = await requ.json()
    const resl = resp;
    if (resl.s) {
      set_opd(resl.d)
    } else {
      error({ msg: resl.m })
    }
  }, [HOST, STATE_AUTH])

  React.useEffect(() => {
    getDetailKendaraan()
    getAksesMenu()
    getOpd()
    console.log(file_bast)
  }, [getOpd, getDetailKendaraan, getAksesMenu, file_bast])
  /**
   * 
   *  */
  const handleBpkbShow = () => { set_show_bpkb(e => !e) }
  const handleBpkbClose = () => { set_show_bpkb(e => !e) }

  const handleStnkShow = () => { set_show_stnk(e => !e) }
  const handleStnkClose = () => { set_show_stnk(e => !e) }

  const handleFotKenClose = () => { set_show_fotken(e => !e) }
  const handleFotKenShow = () => { set_show_fotken(e => !e) }

  const handleMutasiClose = () => { set_show_mutasi(e => !e) }
  const handleMutasiShow = () => { set_show_mutasi(e => !e) }

  const handleUploadBpkb = async (e) => {
    e.preventDefault()
    set_upload(e => !e)
    const formData = new FormData()
    formData.append('FILE_BPKB', file_bpkb)
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      },
      body: formData
    }
    const requ = await fetch(`${HOST}/user/kendaraan/upload/bpkb`, param)
    const resu = await requ.json()
    if (resu.s) {
      success({ msg: resu.m })
      set_upload(e => !e)
      handleBpkbClose()
      getDetailKendaraan()
    } else {
      error({ msg: resu.m })
    }
  }
  const handleDeleteBpkb = async (e) => {
    const formData = new FormData()
    formData.append('ID_KENDARAAN', e.id_kendaraan)
    formData.append('FILE_BPKB', e.file_bpkb)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token
      },
      body: formData
    }
    if (window.confirm('Inign menghapus data ini.?')) {
      const requ = await fetch(`${HOST}/user/kendaraan/hapus/bpkb`, param)
      const resu = await requ.json()
      if (resu.s) {
        success({ msg: resu.m })
        getDetailKendaraan()
      } else {
        error({ msg: resu.m })
      }
    }
  }
  const handleUploadStnk = async (e) => {
    e.preventDefault()
    set_upload(e => !e)
    const formData = new FormData()
    formData.append('FILE_STNK', file_stnk)
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      },
      body: formData
    }
    const requ = await fetch(`${HOST}/user/kendaraan/upload/stnk`, param)
    const resu = await requ.json()
    if (resu.s) {
      success({ msg: resu.m })
      set_upload(e => !e)
      handleStnkClose()
      getDetailKendaraan()
    } else {
      error({ msg: resu.m })
    }
  }
  const handleDeleteStnk = async (e) => {
    const formData = new FormData()
    formData.append('ID_KENDARAAN', e.id_kendaraan)
    formData.append('FILE_STNK', e.file_stnk)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token
      },
      body: formData
    }
    if (window.confirm('Inign menghapus data ini.?')) {
      const requ = await fetch(`${HOST}/user/kendaraan/hapus/stnk`, param)
      const resu = await requ.json()
      if (resu.s) {
        success({ msg: resu.m })
        getDetailKendaraan()
      } else {
        error({ msg: resu.m })
      }
    }
  }
  const handleUploadFotKen = async (e) => {
    e.preventDefault()
    set_upload(e => !e)
    const formData = new FormData()
    formData.append('NAMA_FILE_FOTKEN', nama_file_fotken)
    formData.append('FILE_FOTKEN', file_fotken)
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      },
      body: formData
    }
    const requ = await fetch(`${HOST}/user/kendaraan/upload/fotken`, param)
    const resu = await requ.json()
    if (resu.s) {
      success({ msg: resu.m })
      set_upload(e => !e)
      handleFotKenClose()
      getDetailKendaraan()
    } else {
      error({ msg: resu.m })
    }
  }
  const handleDeleteFotken = async (e) => {
    const formData = new FormData()
    formData.append('ID_FOTO_KENDARAAN', e.id)
    formData.append('FILE_GAMBAR', e.file_gambar)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token
      },
      body: formData
    }
    if (window.confirm('Inign menghapus data ini.?')) {
      const requ = await fetch(`${HOST}/user/kendaraan/hapus/fotken`, param)
      const resu = await requ.json()
      if (resu.s) {
        success({ msg: resu.m })
        getDetailKendaraan()
      } else {
        error({ msg: resu.m })
      }
    }
  }
  const handleSubmitMutasi = async (e) => {
    e.preventDefault()
    set_mutasi(true)
    const fd = new FormData()
    fd.append("NAMA_PIHAK_PERTAMA", nama_pihak_pertama)
    fd.append("NIP_PIHAK_PERTAMA", nip_pihak_pertama)
    fd.append("NAMA_PIHAK_KEDUA", nama_pihak_kedua)
    fd.append("NIP_PIHAK_KEDUA", nip_pihak_kedua)
    fd.append("ID_KENDARAAN", state.id_kendaraan)
    fd.append("OPD_SEBELUM", STATE_AUTH.nama_opd)
    fd.append("OPD_TUJUAN", opd_tujuan)
    fd.append("FILE_BAST_MUTASI", file_bast)
    const requ = await fetch(`${HOST}/user/kendaraan/mutasi`, {
      method: "POST",
      headers: {
        "REST-TOKEN": STATE_AUTH.token,
        "REST-ID-OPD": STATE_AUTH.id_opd
      },
      body: fd
    })
    try {
      const resu = await requ.json()
      if (resu.s) {
        success({ msg: resu.m })
        set_mutasi(false)
        form_mutasi.current.reset()
        direct('/opd/kendaraan')
      } else {
        error({ msg: resu.m })
        set_mutasi(false)
      }
    } catch (err) {
      error({ msg: err.message })
      set_mutasi(false)
    }
  }

  /**
    * --------------------------------------------------------------------
    * BATAS AWAL PENAMBAHAN MODUL BARU VERSI 2.1
    * AUTHOR BOBI R SIMBALA
    * --------------------------------------------------------------------
    */

  const handleLelang = async (e) => {
    e.preventDefault()
    set_loading_status_penilaian(true)
    const formData = new FormData()
    formData.append('STATUS_LELANG', lelang)
    formData.append('FILE_PENILAIAN_LELANG', file_penilian_lelang)
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': state.id_opd
      },
      body: formData
    }

    try {
      const request = await fetch(`${HOST}/admin/kendaraan/lelang/update`, param)
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_loading_status_penilaian(false)
        } else {
          error({ msg: response.m })
          set_loading_status_penilaian(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading_status_penilaian(false)
    }
  }

  const handleHapusStatusPenilaian = async (file) => {
    if (!window.confirm('Ingin mengubah status ini.?')) {
      return
    }
    set_loading_status_penilaian(true)
    const parts = file.split('/');
    const namaFile = parts.pop();

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('STATUS_LELANG', data_detail[0].status_lelang)
    formData.append('NAMA_FILE', namaFile)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/lelang/hapus`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_status_barang('tersedia')
          set_file_status_barang(null)
          set_loading_status_penilaian(false)
        } else {
          error({ msg: response.m })
          set_loading_status_penilaian(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (error) {
      error({ msg: 'Masalah ditemukan : ' + error.message })
      set_loading_status_penilaian(false)
    }

  }

  const handleUpdateStatusKeLelang = async () => {
    set_loading_status_penilaian(true)
    const formData = new FormData()
    formData.append('STATUS_LELANG', 'lelang')
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': state.id_opd
      },
      body: formData
    }

    try {
      const request = await fetch(`${HOST}/admin/kendaraan/lelang/update`, param)
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_loading_status_penilaian(false)
        } else {
          error({ msg: response.m })
          set_loading_status_penilaian(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading_status_penilaian(false)
    }
  }

  const handleUbahStatusKondisiKendaraan = async (e) => {
    e.preventDefault()
    set_loading_status_kondisi(true)
    if (status_kondisi === 'rusak_berat') {
      if (!file_status_kondisi) {
        error({ msg: 'Harus mengupload surat keterangan rusak berat dari Bengkel atau SKTJM Kepala OPD' })
        return set_loading_status_kondisi(false)
      }
    }

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('STATUS_KONDISI', status_kondisi)
    formData.append('FILE_STATUS_KONDISI_RUSAK_BERAT', file_status_kondisi)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/status/kondisi/update`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_loading_status_kondisi(false)
        } else {
          error({ msg: response.m })
          set_loading_status_kondisi(false)
        }
      } else {
        throw new Error(response.m)
      }

    } catch (err) {
      error({ msg: err.message })
      set_loading_status_kondisi(false)
    }

  }
  const handleHapusStatusKondisiKendaraan = async (file) => {
    if (!window.confirm('Ingin mengubah status ini.?')) {
      return
    }
    const parts = file.split('/');
    const namaFile = parts.pop();

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('NAMA_FILE', namaFile)
    set_loading_status_kondisi(true)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/status/kondisi/hapus`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_status_kondisi('baik')
          set_file_status_kondisi(null)
          set_loading_status_kondisi(false)
        } else {
          error({ msg: response.m })
          set_loading_status_kondisi(false)
        }
      }
    } catch (err) {
      error({ msg: 'Masalah ditemukan : ' + err.message })
      set_loading_status_kondisi(false)
    }

  }
  const handleUbahStatusBarangKendaraan = async (e) => {
    e.preventDefault()
    set_loading_status_barang(true)
    if (status_barang === 'hilang') {
      if (!file_status_barang) {
        error({ msg: 'Harus mengupload surat keterangan hilang dari Kepolisian atau SKTJM Kepala OPD' })
        return set_loading_status_barang(false)
      }
    }

    if (status_barang === "pinjam_pakai") {
      if (!keterangan_pinjam_pakai) {
        error({ msg: 'Harus mengisi keterangan pinjam pakai' })
        return
      }
      if (!file_status_barang) {
        error({ msg: 'Harus mengupload surat keterangan Pinjam Pakai' })
        return set_loading_status_barang(false)
      }
    }

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('STATUS_BARANG', status_barang)
    formData.append('KETERANGAN_PINJAM_PAKAI', keterangan_pinjam_pakai)
    formData.append('FILE_STATUS_BARANG', file_status_barang)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/status/barang/update`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_loading_status_barang(false)
          set_keterangan_pinjam_pakai("")
          set_file_status_barang(null)
        } else {
          error({ msg: response.m })
          set_loading_status_barang(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading_status_barang(false)
    }

  }

  const handleHapusStatusBarangKendaraan = async (file) => {
    if (!window.confirm('Ingin mengubah status ini.?')) {
      return
    }
    set_loading_status_barang(true)
    const parts = file.split('/');
    const namaFile = parts.pop();

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('STATUS_BARANG', data_detail[0].status_barang)
    formData.append('NAMA_FILE', namaFile)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/status/barang/hapus`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_status_barang('tersedia')
          set_file_status_barang(null)
          set_loading_status_barang(false)
        } else {
          error({ msg: response.m })
          set_loading_status_barang(false)
        }
      }
    } catch (error) {
      error({ msg: 'Masalah ditemukan : ' + error.message })
      set_loading_status_barang(false)
    }

  }

  /**
  * --------------------------------------------------------------------
  * BATAS AKHIR PENAMBAHAN MODUL BARU
  * VERSI 2.1
  * --------------------------------------------------------------------
  */


  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><i className='fa fa-file-alt'></i> DETAIL KENDARAAN</strong></h3>
          </div>
        </div>
        <hr />
        <button className='btn btn-sm btn-warning me-1' onClick={() => direct('/opd/kendaraan')}> <BsArrowLeftCircleFill /> Kembali</button>
        {
          daftar_menu.menu_kendaraan_edit === "1"
            ?
            <button className='btn btn-sm btn-primary  me-1' onClick={() => direct("/opd/kendaraan/edit", { state: data_detail[0] })}><BsPencilFill /> Ubah data</button>
            :
            <button className='btn btn-sm btn-danger disabled  me-1' onClick={() => alert('Akses tidak diizinkan.')}><BsPencilFill /> Ubah data</button>
        }
        <button className='btn btn-sm btn-success' onClick={handleMutasiShow}> <FaSyncAlt /> Mutasi</button>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <table className='tb-daftar-dinas'>
              <tbody>
                {
                  data_detail.length !== 0
                    ?
                    <>
                      <tr>
                        <td colSpan={2} className='bg-info text-dark'><BsFillInfoCircleFill /> INFO KENDARAAN</td>
                      </tr>
                      <tr>
                        <td width='20%'>Kode Barang</td>
                        <td>{data_detail[0].kode_barang}</td>
                      </tr>
                      <tr>
                        <td>Nama Barang</td>
                        <td>{data_detail[0].nama_barang}</td>
                      </tr>
                      <tr>
                        <td>Nomor Register</td>
                        <td>{data_detail[0].nomor_register}</td>
                      </tr>
                      <tr>
                        <td>Merk Type</td>
                        <td>{data_detail[0].merk_type}</td>
                      </tr>
                      <tr>
                        <td>Ukuran/CC</td>
                        <td>{data_detail[0].ukuran_cc}</td>
                      </tr>
                      <tr>
                        <td>Tahun Pembuatan</td>
                        <td>{data_detail[0].tahun_pembuatan}</td>
                      </tr>
                      <tr>
                        <td>Tahun Pembelian</td>
                        <td>{data_detail[0].tahun_pembelian}</td>
                      </tr>
                      <tr>
                        <td>Bahan Bakar</td>
                        <td>{data_detail[0].bahan_bakar}</td>
                      </tr>
                      <tr>
                        <td>Nomor Rangka</td>
                        <td>{data_detail[0].no_rangka}</td>
                      </tr>
                      <tr>
                        <td>Nomor Mesin</td>
                        <td>{data_detail[0].no_mesin}</td>
                      </tr>
                      <tr>
                        <td>Nomor Polisi</td>
                        <td>{data_detail[0].no_polisi}</td>
                      </tr>
                      <tr>
                        <td>Nomor BPKB</td>
                        <td>{data_detail[0].no_bpkb}</td>
                      </tr>
                      <tr>
                        <td>Jenis</td>
                        <td>{data_detail[0].jenis}</td>
                      </tr>
                      <tr>
                        <td>Sub Jenis</td>
                        <td>{data_detail[0].sub_jenis}</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className='bg-info text-dark'><BsFillInfoCircleFill /> INFO PENGGUNA</td>
                      </tr>
                      <tr>
                        <td>Nip</td>
                        <td>{data_detail[0].nip}</td>
                      </tr>
                      <tr>
                        <td>Nama Pengguna</td>
                        <td>{data_detail[0].nama}</td>
                      </tr>
                      <tr>
                        <td>Jabatan</td>
                        <td>{data_detail[0].jabatan}</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className='bg-info text-dark'><BsFillInfoCircleFill /> INFO PKB</td>
                      </tr>
                      <tr>
                        <td>Pkb</td>
                        <td>{formatRupiah(data_detail[0].rp_pkb)}</td>
                      </tr>
                      <tr>
                        <td>Tanggal Jatuh Tempo STNK</td>
                        <td>{data_detail[0].tgl_jth_tmp_stnk}</td>
                      </tr>
                      <tr>
                        <td>Tanggal Jatuh Tempo PKB</td>
                        <td>{data_detail[0].tgl_jth_tmp_pkb}</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className='bg-info text-dark'><BsFillInfoCircleFill /> INFO SURAT BPKB DAN STNK</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-header">BPKB</div>
                                <div className="card-body">
                                  {
                                    !data_detail[0].file_bpkb
                                      ?
                                      <button onClick={handleBpkbShow} className='btn btn-primary btn-sm'><i className='far fa-arrow-alt-circle-up'></i> UPLOAD FILE</button>
                                      :
                                      <div className="btn-group" role="group" aria-label="Basic example">
                                        <button className='btn btn-success btn-sm' onClick={() => window.open(`${data_detail[0].file_bpkb}`, '_blank')} target={"_blank"} rel="noreferrer"><FaFileAlt /> Lihat Dokumen</button>
                                        <button type="button" className="btn btn-danger" onClick={handleDeleteBpkb.bind(this, { id_kendaraan: data_detail[0].id_kendaraan, file_bpkb: data_detail[0].file_bpkb })}><BsTrash /></button>
                                      </div>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-header">STNK</div>
                                <div className="card-body">
                                  {
                                    !data_detail[0].file_stnk
                                      ?
                                      <button onClick={handleStnkShow} className='btn btn-primary btn-sm'><i className='far fa-arrow-alt-circle-up'></i> UPLOAD FILE</button>
                                      :
                                      <div className="btn-group" role="group" aria-label="Basic example">
                                        <button className='btn btn-success btn-sm' onClick={() => window.open(`${data_detail[0].file_stnk}`, '_blank')} target={"_blank"} rel="noreferrer"><FaFileAlt /> Lihat Dokumen</button>
                                        <button type="button" className="btn btn-danger" onClick={handleDeleteStnk.bind(this, { id_kendaraan: data_detail[0].id_kendaraan, file_stnk: data_detail[0].file_stnk })}><BsTrash /></button>
                                      </div>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className='bg-info text-dark'><BsFillInfoCircleFill /> INFO FOTO KENDARAAN</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card">
                                <div className="card-header">FOTO KENDARAAN</div>
                                <div className="card-body">
                                  <button onClick={handleFotKenShow} className='btn btn-primary btn-sm my-2'><i className='far fa-arrow-alt-circle-up'></i> UPLOAD FILE</button>
                                  <div className="row">
                                    {
                                      data_detail.file_fotken.length !== 0
                                        ?
                                        data_detail.file_fotken.map((val, key) => {
                                          return (<div className="col-md-4" key={key}>
                                            <div className="card" >
                                              <img src={val.file_gambar} alt='meload gambar...' className='card-img-top' />
                                              <div className="card-footer">
                                                {val.nama_gambar}
                                                <br />
                                                <a href={val.file_gambar} target="_blank" rel="noreferrer" className='btn btn-sm btn-info'><BsFillEyeFill /> </a>
                                                <button className='btn btn-danger btn-sm' onClick={handleDeleteFotken.bind(this, { id: val.id, file_gambar: val.file_gambar })}><BsTrash /> </button>
                                              </div>
                                            </div>
                                          </div>)
                                        })
                                        :
                                        <span className='text-center'>Tidak ada file gambar.</span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      {
                        daftar_menu.menu_update_status === "1" && (
                          <>
                            <tr>
                              <td colSpan={2} className='bg-warning text-dark'><FaInfoCircle size={18} />&nbsp; INFO STATUS</td>
                            </tr>
                            {
                              data_detail[0].status_barang === 'hilang' || data_detail[0].status_barang === 'pinjam_pakai'
                                ?
                                <tr>
                                  <td colSpan={2}>
                                    <div className="card">
                                      <div className="card-body">
                                        <label htmlFor="status_kondisi">Status Kendaraan</label>
                                        <input type="text" className='form-control form-control-sm mb-2' value={data_detail[0].status_barang} disabled />
                                        <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                          {
                                            data_detail[0].status_barang === "hilang"
                                              ?
                                              <>
                                                <button className='btn btn-success btn-sm' onClick={() => window.open(data_detail[0].file_sk_status_barang_hilang, "_blank")}><FaFileAlt /> Lihat surat keterangan hilang dari Kepolisian / SKTJM Kepala OPD</button>
                                                {
                                                  loading_status_barang
                                                    ?
                                                    <button className="btn btn-danger" type="button" disabled>
                                                      <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                      <span className="visually-hidden" role="status">Loading...</span>
                                                    </button>
                                                    :
                                                    <button className='btn btn-danger' onClick={handleHapusStatusBarangKendaraan.bind(this, data_detail[0].file_sk_status_barang_hilang)}><FaTimesCircle /></button>
                                                }
                                              </>
                                              :
                                              <>
                                                <button className='btn btn-success btn-sm' onClick={() => window.open(data_detail[0].file_sk_status_pinjam_pakai, "_blank")}><FaFileAlt /> Lihat surat keterangan Pinjam Pakai</button>
                                                {
                                                  loading_status_barang
                                                    ?
                                                    <button className="btn btn-danger" type="button" disabled>
                                                      <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                      <span className="visually-hidden" role="status">Loading...</span>
                                                    </button>
                                                    :
                                                    <button className='btn btn-danger' onClick={handleHapusStatusBarangKendaraan.bind(this, data_detail[0].file_sk_status_pinjam_pakai)}><FaTimesCircle /></button>
                                                }
                                              </>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                :
                                <tr>
                                  <td colSpan={2}>
                                    <div className="card">
                                      <div className="card-body">
                                        <form onSubmit={handleUbahStatusBarangKendaraan}>
                                          <div className="form-group">
                                            <label htmlFor="status_kondisi">Status Kendaraan</label>
                                            <select name="status_lelang" id="status_kondisi" className='form-select form-select-sm mb-2' value={status_barang} onChange={e => { set_status_barang(e.target.value); ref_file_status_barang.current.value = null }} required>
                                              <option>--Pilih--</option>
                                              <option value="tersedia">Tersedia</option>
                                              <option value="hilang">Hilang</option>
                                              <option value="pinjam_pakai">Pinjam Pakai</option>
                                            </select>
                                            {status_barang === "hilang" || status_barang === "pinjam_pakai"
                                              ?
                                              <>
                                                {status_barang === "pinjam_pakai" &&
                                                  <>
                                                    <label htmlFor="keterangan_pinjam_pakai">Keterangan Pinjam Pakai</label>
                                                    <input type="text" className='form-control form-control-sm mb-1' value={keterangan_pinjam_pakai} onChange={e => set_keterangan_pinjam_pakai(e.target.value)} placeholder='Masukan keterangan disini' required />
                                                  </>
                                                }
                                                <input type='file' className='form-control form-control-sm' ref={ref_file_status_barang} onChange={e => set_file_status_barang(e.target.files[0])} required />
                                                {status_barang === "hilang" && <small className='text-muted'>Silahkan upload surat keterangan dari Kepolisian / SKTJM Kepala OPD</small>}
                                                {status_barang === "pinjam_pakai" && <small className='text-muted'>Silahkan upload Surat Pinjam Pakai</small>}
                                                <hr />
                                              </>
                                              :
                                              null
                                            }
                                            {loading_status_barang
                                              ?
                                              <button className="btn btn-primary btn-sm my-2" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                <span role="status"> Mohon tunggu...</span>
                                              </button>
                                              :
                                              <button type="submit" className='btn btn-primary btn-sm my-2'> Ubah</button>
                                            }
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                            }

                            {
                              data_detail[0].status_kondisi === 'rusak_berat'
                                ?
                                <tr>
                                  <td colSpan={2}>
                                    <div className="card">
                                      <div className="card-body">
                                        <label htmlFor="status_kondisi">Status Kondisi Kendaraan</label>
                                        <input type="text" className='form-control form-control-sm mb-2' value={data_detail[0].status_kondisi} disabled />
                                        <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                          <button className='btn btn-success btn-sm' onClick={() => window.open(data_detail[0].file_sk_status_kondisi_rusak_berat, "_blank")}><FaFileAlt /> Lihat surat keterangan Bengkel / SKTJM Kepala OPD</button>
                                          {
                                            loading_status_kondisi
                                              ?
                                              <button className="btn btn-danger" type="button" disabled>
                                                <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                <span className="visually-hidden" role="status">Loading...</span>
                                              </button>
                                              :
                                              <button className='btn btn-danger' onClick={handleHapusStatusKondisiKendaraan.bind(this, data_detail[0].file_sk_status_kondisi_rusak_berat)}><FaTimesCircle /></button>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                :
                                <tr>
                                  <td colSpan={2}>
                                    <div className="card">
                                      <div className="card-body">
                                        <form onSubmit={handleUbahStatusKondisiKendaraan}>
                                          <div className="form-group">
                                            <label htmlFor="status_kondisi">Status Kondisi Kendaraan</label>
                                            <select name="status_lelang" id="status_kondisi" className='form-select form-select-sm mb-2' value={status_kondisi} onChange={e => set_status_kondisi(e.target.value)} required>
                                              <option>--Pilih--</option>
                                              <option value="baik">Baik</option>
                                              <option value="rusak_ringan">Rusak Ringan</option>
                                              <option value="rusak_berat">Rusak Berat</option>
                                            </select>
                                            {status_kondisi === "rusak_berat"
                                              ?
                                              <>
                                                <input type='file' className='form-control form-control-sm' onChange={e => set_file_status_kondisi(e.target.files[0])} required />
                                                <small className='text-muted'>Silahkan upload surat keterangan dari Bengkel / SKTJM Kepala OPD</small>
                                                <hr />
                                              </>
                                              :
                                              null
                                            }
                                            {
                                              loading_status_kondisi
                                                ?
                                                <button className="btn btn-primary btn-sm my-2" type="button" disabled>
                                                  <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                  <span role="status"> Mohon tunggu...</span>
                                                </button>
                                                :
                                                <button type="submit" className='btn btn-primary btn-sm my-2'> Ubah</button>
                                            }
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                            }

                            {
                              data_detail[0].status_lelang !== 'lelang'
                                ?
                                data_detail[0].status_lelang === "penilaian"
                                  ?
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="card">
                                        <div className="card-body">
                                          <label htmlFor="status_kondisi">Status Lelang</label>
                                          <input type="text" className='form-control form-control-sm mb-2' value={data_detail[0].status_lelang} disabled />
                                          <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                            <button className='btn btn-success btn-sm' onClick={() => window.open(data_detail[0].file_sk_penilaian_lelang, "_blank")}><FaFileAlt /> Lihat surat keterangan Penilaian</button>
                                            {
                                              loading_status_penilaian
                                                ?
                                                <button className="btn btn-danger" type="button" disabled>
                                                  <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                  <span className="visually-hidden" role="status">Loading...</span>
                                                </button>
                                                :
                                                <>
                                                  <button className='btn btn-danger' onClick={handleHapusStatusPenilaian.bind(this, data_detail[0].file_sk_penilaian_lelang)} ><FaTimesCircle /></button>
                                                  <button className='btn btn-info' onClick={handleUpdateStatusKeLelang.bind(this, data_detail[0].file_sk_penilaian_lelang)} title='Lanjut ke tahap lelang'><FaCheck /></button>
                                                </>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  :
                                  data_detail[0].status_lelang === "selesai"
                                    ?
                                    null
                                    :
                                    <tr>
                                      <td colSpan={2}>
                                        <div className="card">
                                          <div className="card-body">
                                            <form onSubmit={handleLelang}>
                                              <div className="form-group">
                                                <label htmlFor="status_lelang">Status Kendaraan Lelang</label>
                                                <select name="status_lelang" id="status_lelang" className='form-select form-select-sm' value={lelang} onChange={e => set_lelang(e.target.value)}>
                                                  <option>--Pilih--</option>
                                                  <option value="penilaian">Penilaian</option>
                                                  {/* <option value="lelang">Lelang</option> */}
                                                </select>
                                                {lelang === "penilaian"
                                                  ?
                                                  <>
                                                    <input type='file' className='form-control form-control-sm mt-2' onChange={e => set_file_penilaian_lelang(e.target.files[0])} required />
                                                    <small className='text-muted'>Silahkan upload surat keterangan penilaian</small>
                                                    <hr />
                                                  </>
                                                  :
                                                  null
                                                }
                                                {
                                                  loading_status_penilaian
                                                    ?
                                                    <button className="btn btn-primary btn-sm my-2" type="button" disabled>
                                                      <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                      <span role="status"> Mohon tunggu...</span>
                                                    </button>
                                                    :
                                                    <button type="submit" className='btn btn-primary btn-sm my-2'> Ubah</button>
                                                }
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                :
                                <tr>
                                  <td colSpan={2}>
                                    <div className="card">
                                      <div className="card-body">
                                        <h4>{data_detail[0].status_lelang === "lelang" ? 'Kendaraan Ini Dilelang' : ''}</h4>
                                        {
                                          loading_status_penilaian
                                            ?
                                            <button className="btn btn-danger btn-sm my-2" type="button" disabled>
                                              <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                              <span role="status"> Mohon tunggu...</span>
                                            </button>
                                            :
                                            <button type="button" onClick={handleHapusStatusPenilaian.bind(this, data_detail[0].file_sk_penilaian_lelang)} className='btn btn-danger btn-sm my-2'><FaTimes /> BATAL LELANG</button>
                                        }
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                            }
                          </>
                        )
                      }
                    </>
                    :
                    <>
                      <tr>
                        <td className='text-center d-flex justify-content-center align-items-center'>
                          <BeatLoader />
                        </td>
                      </tr>
                    </>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Modal show={show_modal_bpkb} onHide={handleBpkbClose}>
              <Modal.Header closeButton>
                <Modal.Title>UPLOAD FILE BPKB</Modal.Title>
              </Modal.Header>
              <form onSubmit={handleUploadBpkb}>
                <Modal.Body>
                  <div className="form-group">
                    <input type="file" className="form-control" onChange={e => set_file_bpkb(e.target.files[0])} required />
                  </div>
                  <small className='text-muted'>Pastikan file yang diupload format extensi-nya adalah : (*.jpg, *.png, *.pdf)</small>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" size='sm' onClick={handleBpkbClose}>
                    Keluar
                  </Button>
                  {
                    <Button type="submit" variant="primary" disabled={is_upload}>
                      {is_upload ? 'Mohon tunggu...' : 'Upload'}
                    </Button>
                  }
                </Modal.Footer>
              </form>
            </Modal>
          </div>
          <div className="col-md-12">
            <Modal show={show_modal_stnk} onHide={handleStnkClose}>
              <Modal.Header closeButton>
                <Modal.Title>UPLOAD FILE STNK</Modal.Title>
              </Modal.Header>
              <form onSubmit={handleUploadStnk} >
                <Modal.Body>
                  <div className="form-group">
                    <input type="file" className="form-control" onChange={e => set_file_stnk(e.target.files[0])} required />
                  </div>
                  <small className='text-muted'>Pastikan file yang diupload format extensi-nya adalah : (*.jpg, *.png, *.pdf)</small>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" size='sm' onClick={handleStnkClose}>
                    Keluar
                  </Button>
                  {
                    <Button type="submit" variant="primary" size="sm" disabled={is_upload}>
                      {is_upload ? 'Mohon tunggu...' : 'Upload'}
                    </Button>
                  }
                </Modal.Footer>
              </form>
            </Modal>
          </div>
          <div className="col-md-12">
            <Modal show={show_modal_fotken} onHide={handleFotKenClose}>
              <Modal.Header closeButton>
                <Modal.Title>UPLOAD FOTO KENDARAAN</Modal.Title>
              </Modal.Header>
              <form onSubmit={handleUploadFotKen} >
                <Modal.Body>
                  <div className="form-group mb-3">
                    <input type="text" className='form-control' placeholder='Nama file' onChange={e => set_nama_file_fotken(e.target.value)} />
                    <small className='text-muted'>Contoh: foto depan</small>
                  </div>
                  <div className="form-group">
                    <input type="file" className="form-control" onChange={e => set_file_fotken(e.target.files[0])} required />
                    <small className='text-muted'>Pastikan file yang diupload format extensi-nya adalah : (*.jpg, *.png)</small>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" size='sm' onClick={handleFotKenClose}>
                    Keluar
                  </Button>
                  {
                    <Button type="submit" variant="primary" size="sm" disabled={is_upload}>
                      {is_upload ? 'Mohon tunggu...' : 'Upload'}
                    </Button>
                  }
                </Modal.Footer>
              </form>
            </Modal>
          </div>
          <div className="col-md-12">
            <Modal show={show_modal_mutasi} onHide={handleMutasiClose} backdrop="static">
              <Modal.Header closeButton>
                <Modal.Title>MUTASI KENDARAAN</Modal.Title>
              </Modal.Header>
              <form onSubmit={handleSubmitMutasi} ref={form_mutasi}>
                <Modal.Body>
                  <h6>PIHAK PERTAMA</h6>
                  <hr />
                  <div className="form-group mb-3">
                    <label htmlFor="nama_pihak_pertama">Nama</label>
                    <input type="text" className='form-control' name='nama_pihak_pertama' id='nama_pihak_pertama' value={nama_pihak_pertama} onChange={e => set_nama_pihak_pertama(e.target.value)} autoComplete={"off"} required />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="nip_pihak_pertama">Nip</label>
                    <input type="text" className='form-control' name='nip_pihak_pertama' id='nip_pihak_pertama' value={nip_pihak_pertama} onChange={e => set_nip_pihak_pertama(e.target.value)} autoComplete={"off"} required />
                  </div>

                  <h6>PIHAK KEDUA</h6>
                  <hr />
                  <div className="form-group mb-3">
                    <label htmlFor="nama_pihak_kedua">Nama</label>
                    <input type="text" className='form-control' name='nama_pihak_kedua' id='nama_pihak_kedua' value={nama_pihak_kedua} onChange={e => set_nama_pihak_kedua(e.target.value)} autoComplete={"off"} required />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="nip_pihak_kedua">Nip</label>
                    <input type="text" className='form-control' name='nip_pihak_kedua' id='nip_pihak_kedua' value={nip_pihak_kedua} onChange={e => set_nip_pihak_kedua(e.target.value)} autoComplete={"off"} required />
                  </div>

                  <h6>MUTASI</h6>
                  <hr />

                  <div className="form-group mb-3">
                    <label htmlFor="opd">OPD Tujuan</label>
                    <select name="opd" id="opd" className='form-control' defaultValue={opd_tujuan} onChange={e => set_opd_tujuan(e.target.value)}>
                      <option>--Pilih--</option>
                      {opd.filter(e => { return e.id !== STATE_AUTH.id_opd }).map((v, i) => {
                        return (<option key={i} value={v.id + ',' + v.opd}>{v.opd}</option>)
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="file_bast_mutasi">Berita Acara Serah Terima (BAST)</label>
                    <input type="file" className="form-control" name='file_bast_mutasi' onChange={e => set_file_bast(e.target.files[0])} required accept='application/pdf' />
                    <small className='text-muted'>Pastikan file yang diupload format extensi-nya adalah : (*.pdf)</small>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" size='sm' onClick={handleMutasiClose}>
                    Batal
                  </Button>
                  {
                    <Button type="submit" variant="primary" size="sm" disabled={is_mutasi}>
                      {is_mutasi ? 'Mohon tunggu...' : 'Mutasi'}
                    </Button>
                  }
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Opd_kendaraan_detail