import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { Modal } from 'react-bootstrap'
import { success, error } from '../notify'
import { FaArrowLeft, FaFileInvoice } from "react-icons/fa";
import { BeatLoader } from 'react-spinners'

const Opd_kendaraan_pemeliharaan = () => {
  const { state } = useLocation()
  const { HOST } = useStateHostContext()
  const { STATE_AUTH } = useStateAuthContext()
  console.log(state)
  const [show_modal, set_show_modal] = React.useState(false)
  const [data_pemeliharaan, set_data_pemeliharaan] = React.useState([])
  const [tanggal_pemeliharaan, set_tanggal_pemeliharaan] = React.useState(null)
  const [uraian, set_uraian] = React.useState('')
  const [volume, set_volume] = React.useState('')
  const [satuan, set_satuan] = React.useState('');
  const [harga_satuan, set_harga_satuan] = React.useState('')
  const [file_pemeliharaan, set_file_pemeliharaan] = React.useState(null)
  const [nama_pptk, set_nama_pptk] = React.useState('')
  const [loading, set_loading] = React.useState(false)
  const handleShow = () => set_show_modal(true)
  const handleHide = () => set_show_modal(false)
  const direct = useNavigate();

  const handleFormatRupiah = (angka) => {
    var reverse = angka.toString().split('').reverse().join('');
    var ribuan = reverse.match(/\d{1,3}/g);
    var formatted = ribuan.join('.').split('').reverse().join('');
    return 'Rp ' + formatted;
  }

  const handleFormatTanggal = (tanggal) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var tanggalObj = new Date(tanggal);
    var hasilFormat = tanggalObj.toLocaleDateString('id-ID', options).replace(/ /g, '-');
    return hasilFormat;
  }


  const handleAmbilDataPemeliharaan = React.useCallback(async (e) => {
    set_loading(true)
    try {
      const request = await fetch(`${HOST}/user/kendaraan/pemeliharaan/detail/${state.id}`, {
        method: 'GET',
        headers: {
          "REST-ID-OPD": STATE_AUTH.id_opd,
          "REST-TOKEN": STATE_AUTH.token
        }
      })
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          set_data_pemeliharaan(response.d)
          set_loading(false)
        } else {
          set_loading(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }

  }, [HOST,state, STATE_AUTH])

  const handleSubmitPemeliharaan = async (e) => {
    e.preventDefault()
    if (!file_pemeliharaan) {
      error({ msg: 'Harus mengupload file /Bukti pemeliharaan' })
      return
    }

    set_loading(true)

    const formData = new FormData();
    formData.append('ID_KENDARAAN', state.id);
    formData.append('ID_OPD', STATE_AUTH.id_opd);
    formData.append('TANGGAL_PEMELIHARAAN', tanggal_pemeliharaan);
    formData.append('URAIAN', uraian);
    formData.append('VOLUME', volume);
    formData.append('SATUAN', satuan);
    formData.append('HARGA_SATUAN', harga_satuan);
    formData.append('FILE_PEMELIHARAAN', file_pemeliharaan);
    formData.append('NAMA_PPTK', nama_pptk)

    try {
      const request = await fetch(`${HOST}/user/kendaraan/pemeliharaan/simpan`, {
        method: 'POST',
        headers: {
          "REST-ID-OPD": STATE_AUTH.id_opd,
          "REST-TOKEN": STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          handleAmbilDataPemeliharaan()
          handleHide()
          set_loading(false)
        } else {
          error({ msg: response.m })
          set_loading(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }
  }

  React.useEffect(() => {
    handleAmbilDataPemeliharaan()
  }, [handleAmbilDataPemeliharaan])

  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><FaFileInvoice /> PEMELIHARAAN KENDARAAN</strong></h3>
          </div>
        </div>
        <hr />
        <button className='btn btn-sm btn-warning me-2' onClick={() => { direct(-1) }}> <FaArrowLeft /> Kembali</button>
        <button className='btn btn-sm btn-primary me-2' onClick={() => handleShow()}> Tambah</button>
        <button className='btn btn-sm btn-success' onClick={() => window.open(`${HOST}/user/kendaraan/pemeliharaan/export/${state.id}`)}> Export</button>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <table className='tb-daftar-dinas mt-1'>
              <tbody>
                <tr>
                  <td>
                    <small className='text-muted'>Kode Barang</small><br />
                    <span>{state.kode_barang}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small className='text-muted'>Nomor Register</small><br />
                    <span>{state.nomor_register}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small className='text-muted'>Nama Barang</small><br />
                    <span>{state.nama_barang}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small className='text-muted'>Type Kendaraan</small><br />
                    <span>{state.merk_type}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small className='text-muted'>Nomor Polisi</small><br />
                    <span>{state.no_polisi}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <table className='tb-daftar-dinas mt-1'>
              <thead>
                <tr>
                  <th rowSpan={2} className='text-center text-dark'>Tanggal</th>
                  <th colSpan={6} className='text-center text-dark'>Jenis Pemeliharaan</th>
                  <th rowSpan={2} className='text-center text-dark'>Nama PPTK</th>
                </tr>
                <tr className='bg-info'>
                  <th className='text-dark'>Uraian</th>
                  <th className='text-dark'>Volume</th>
                  <th className='text-dark'>Satuan</th>
                  <th className='text-dark'>Harga Satuan</th>
                  <th className='text-dark'>Jumlah</th>
                  <th className='text-dark'>File/Bukti Pemeliharaan</th>
                </tr>
              </thead>
              <tbody>
                {
                  !loading
                    ?
                    data_pemeliharaan.length === 0
                      ?
                      <tr>
                        <td colSpan={9} className='text-center '>Belum ada data pemeliharaan</td>
                      </tr>
                      :
                      data_pemeliharaan.map((val, ind) => {
                        return (
                          <tr key={ind}>
                            <td className='text-center'>{handleFormatTanggal(val.tanggal_pemeliharaan)}</td>
                            <td className='text-center'>{val.uraian}</td>
                            <td className='text-center'>{val.volume}</td>
                            <td className='text-center'>{val.satuan}</td>
                            <td className='text-center'>{handleFormatRupiah(val.harga_satuan)}</td>
                            <td className='text-center'>{handleFormatRupiah(val.satuan * val.harga_satuan)}</td>
                            <td className='text-center'>{val.file_dokumentasi_pemeliharaan ? <button className='btn btn-success btn-sm' onClick={() => window.open(`${val.file_dokumentasi_pemeliharaan}`, '_blank')}>Lihat Dokumen</button> : <button className='btn btn-danger btn-sm'>Dokumen tidak tersedia</button>}</td>
                            <td className='text-center'>{val.nama_pptk}</td>
                          </tr>
                        )
                      })
                    :
                    <tr>
                      <td colSpan={9} className='text-center'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <BeatLoader />
                        </div>
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <Modal show={show_modal} centered backdrop={'static'} onHide={handleHide}>
          <Modal.Header closeButton>
            <Modal.Title>Tambah Uraian Pemeliharaan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmitPemeliharaan}>
              <div className='form-group mb-1'>
                <input type='date' className='form-control form-control-sm' required value={tanggal_pemeliharaan} onChange={e => set_tanggal_pemeliharaan(e.target.value)} />
              </div>
              <div className='form-group mb-1'>
                <textarea rows={3} placeholder='Uraian Pemeliharaan' className='form-control form-control-sm' value={uraian} onChange={e => set_uraian(e.target.value)}></textarea>
              </div>
              <div className='form-group mb-1'>
                <input type='text' placeholder='Volume' className='form-control form-control-sm' required value={volume} onChange={e => set_volume(e.target.value)} />
              </div>
              <div className='form-group mb-1'>
                <input type='text' placeholder='Satuan' className='form-control form-control-sm' required value={satuan} onChange={e => set_satuan(e.target.value)} />
              </div>
              <div className='form-group mb-1'>
                <input type='number' placeholder='Harga Satuan' className='form-control form-control-sm' required value={harga_satuan} onChange={e => set_harga_satuan(e.target.value)} />
              </div>
              <div className='form-group mb-1'>
                <input type='text' placeholder='Jumlah' readOnly value={handleFormatRupiah(satuan * harga_satuan)} className='form-control form-control-sm' required />
              </div>
              <div className='form-group mb-1'>
                <input type='text' placeholder='Nama PPTK' className='form-control form-control-sm' required value={nama_pptk} onChange={e => set_nama_pptk(e.target.value)} />
              </div>
              <div className='form-group mb-1'>
                <input type='file' className='form-control form-control-sm' required onChange={e => set_file_pemeliharaan(e.target.files[0])} />
                <small className='text-muted'>Upload file/Bukti pemeliharaan</small>
              </div>
              {
                !loading
                  ?
                  <button className='btn btn-primary btn-sm my-2'>Simpan</button>
                  :
                  <button className="btn btn-primary btn-sm my-2" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                    <span role="status"> Mohon tunggu...</span>
                  </button>

              }
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </main>
  )
}

export default Opd_kendaraan_pemeliharaan