import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { error } from '../notify'

const Admin_kendaraan_pengguna = () => {
      window.document.title = "Pengguna Kendaraan"
      const { state } = useLocation()
      const direct = useNavigate()
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [pengguna, set_pengguna] = React.useState([])
      const [is_get_data, set_is_data] = React.useState(false)

      React.useEffect(() => {
            set_is_data(true)
            const ambilDataPengguna = async () => {
                  const param = {
                        method: 'GET',
                        headers: {
                              'REST-TOKEN': STATE_AUTH.token,
                              'REST-ID-OPD': state.id
                        }
                  }
                  const resp = await fetch(HOST + '/admin/kendaraan/pengguna', param)
                  try {
                        const resu = await resp.json()
                        set_pengguna(resu.d)
                        set_is_data(false)

                  } catch (e) {
                        error({ msg: 'Maslah ditemukan : ' + e })
                        set_is_data(false)
                  }
            }
            ambilDataPengguna()
      }, [STATE_AUTH, HOST, state])

      const loadSpiners = (<div className="spinner-border text-center" role="status">
            <span className="visually-hidden">Loading...</span>
      </div>)

      if (!STATE_AUTH.is_login) {
            return <Navigate to='/' />
      }
      return (
            <main>
                  <div className="container-fluid">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='far fa-building'></i> {state.opd.toUpperCase()}</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <button className='btn btn-sm btn-warning' onClick={() => { direct('/admin/kendaraan/detail', { state: state }) }}> <i className='far fa-arrow-alt-circle-left'></i> Kembali</button>
                        <button className='btn btn-sm btn-primary mx-2' onClick={() => { direct('/admin/kendaraan/pengguna/tambah', { state: state }) }}> <i className=''></i> Tambah Pengguna</button>
                        <hr />
                        <div className="row mt-2">
                              <div className="col-md-12">
                                    <i className='fa fa-list'></i> DAFTAR PENGGUNA KENDARAAN
                                    <table className='tb-daftar-dinas' style={{ fontSize: '14px', width: '100%' }} cellPadding={5} cellSpacing={0}>
                                          <thead>
                                                <tr>
                                                      <th>#</th>
                                                      <th>Nip</th>
                                                      <th>Nama Pengguna</th>
                                                      <th>Jabatan</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {

                                                      is_get_data
                                                            ?
                                                            <tr><td colSpan={4} className='text-center fw-bold'>{loadSpiners}</td></tr>
                                                            :
                                                            pengguna.length !== 0
                                                                  ?
                                                                  pengguna.map((v, i) => {
                                                                        return (<tr key={i}>
                                                                              <td>{i + 1}</td>
                                                                              <td>{v.nip}</td>
                                                                              <td>{v.nama}</td>
                                                                              <td>{v.jabatan}</td>
                                                                        </tr>)
                                                                  })
                                                                  :
                                                                  <tr><td className='text-center' colSpan={4}>(Pengguna Tidak Tersedia.)</td></tr>
                                                }
                                          </tbody>
                                    </table>
                              </div>
                        </div>
                  </div>
            </main>
      )
}

export default Admin_kendaraan_pengguna