import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { error } from '../notify'

const Opd_pengguna_kendaraan = () => {
      window.document.title = "Pengguna Kendaraan"

      const direct = useNavigate();
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [pengguna, set_pengguna] = React.useState([]);
      const [is_get_data, set_is_data] = React.useState(false);

      React.useEffect(() => {
            set_is_data(true)
            const ambilDataPengguna = async () => {
                  const param = {
                        method: 'GET',
                        headers: {
                              'REST-TOKEN': STATE_AUTH.token,
                              'REST-ID-OPD': STATE_AUTH.id_opd
                        }
                  }
                  const resp = await fetch(HOST + '/user/kendaraan/pengguna', param)
                  try {
                        const resu = await resp.json()
                        set_pengguna(resu.d)
                        set_is_data(false)
                  } catch (e) {
                        error({ msg: 'Maslah ditemukan : ' + e })
                        set_is_data(false)
                  }
            }
            ambilDataPengguna()
      }, [STATE_AUTH, HOST])

      const loadSpiners = (<div className="spinner-border text-center" role="status">
            <span className="visually-hidden">Loading...</span>
      </div>)

      if (!STATE_AUTH.is_login) {
            return <Navigate to='/' />
      }
      return (
            <main>
                  <div className="container-fluid">

                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='fa fa-user-alt'></i> DAFTAR PENGGUNA KENDARAAN</strong></h3>
                              </div>
                        </div>
                        {
                              STATE_AUTH.is_verifikator === 'tidak'
                                    ?
                                    <>
                                          <hr />
                                          <button className='btn btn-primary btn-sm' onClick={() => { direct('/opd/pengguna/kendaraan/tambah') }}>+ Tambah</button>
                                          <hr />
                                    </>
                                    :
                                    <hr />
                        }
                        <div className="row mt-2">
                              <div className="col-md-12">
                                    <table className='tb-daftar-dinas' style={{ fontSize: '14px', width: '100%' }} cellPadding={5} cellSpacing={0}>
                                          <thead>
                                                <tr>
                                                      <th width="2%">#</th>
                                                      <th>NIP</th>
                                                      <th>NAMA PENGGUNA</th>
                                                      <th>JABATAN</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {
                                                      is_get_data
                                                            ?
                                                            <tr><td colSpan={4} className='text-center fw-bold'>{loadSpiners}</td></tr>
                                                            :
                                                            pengguna.length !== 0
                                                                  ?
                                                                  pengguna.map((v, i) => {
                                                                        return (<tr key={i}>
                                                                              <td>{i + 1}</td>
                                                                              <td>{v.nip}</td>
                                                                              <td>{v.nama}</td>
                                                                              <td>{v.jabatan}</td>
                                                                        </tr>)
                                                                  })
                                                                  :
                                                                  <tr><td className='text-center' colSpan={4}>(Pengguna kendaraan tidak tersedia.)</td></tr>
                                                }
                                          </tbody>
                                    </table>
                              </div>
                        </div>
                  </div>
            </main>
      )
}

export default Opd_pengguna_kendaraan