import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import 'bootstrap'
import './assets/css/styles.css'
import './assets/css/table-daftar-kendaraan.css'
import { BrowserRouter as Router } from 'react-router-dom';
import { content as ContentNotif } from './notify';
import { AuthProvider, HostProvider } from './context';

const root = ReactDOM.createRoot(document.getElementById("root"));
const InitApp = () => {
  return (
    <HostProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </HostProvider>
  )
}
root.render(
  <Router basename='/'>
    <InitApp />
    <ContentNotif />
  </Router>
);