import React from 'react';
import { getBreakpoint } from '../utils';
import { useNavigate } from 'react-router-dom';
const NotFound = () => {
  const direct = useNavigate();
  window.document.title = "404 Laman tidak ditemukan";
  return (
    <main style={style.wrapp} className='d-flex justify-content-center align-items-center'>
      <div className="container">
        <div className='row'>
          <div className='col-md-5'>
          </div>
          <div className='col-md-7'>
            <h1>Halaman tidak ditemukan</h1>
            <button className='btn btn-primary' onClick={()=>direct('/')}>Kembali ke halaman sebelumnya</button>
          </div>
        </div>
      </div>
    </main>
  );
}

const style = {
  wrapp: {
    height: getBreakpoint().height,
    backgroundImage: `url(${require('../assets/img/bg.jpg')})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'

  }
}

export default NotFound;