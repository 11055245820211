import React from 'react'
import { Navigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { useStateAuthContext, useStateHostContext } from '../context'
import { error } from '../notify'
import { formatRupiah } from '../utils'

const Viewer = () => {
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [daftar_kendaraan, set_daftar_kendaraan] = React.useState([]);
  const [loading, set_loading] = React.useState(false);
  const ambil_daftar_kendaraan = React.useCallback(async () => {
    set_loading(true)
    const requ = await fetch(`${HOST}/user/kendaraan`, {
      method: 'GET', headers: {
        "REST-TOKEN": STATE_AUTH.token,
        "REST-ID-OPD": null
      }
    })
    const resu = await requ.json()
    if (resu.s) {
      set_daftar_kendaraan(resu.d)
      set_loading(false)
    } else {
      error({ msg: resu.m })
    }
  }, [HOST, STATE_AUTH])

  React.useEffect(() => { window.document.title = "Daftar Kendaraan Dinas"; ambil_daftar_kendaraan() }, [ambil_daftar_kendaraan])
  if (!STATE_AUTH.is_login) {
    return <Navigate to="/login" />
  }
  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3 className='p-0 m-0'><strong><i className='fas fa-car'></i> DAFTAR KENDARAAN DINAS</strong></h3>
            <small className='text-muted'>Daftar aset kendaraan dinas organisasi perangkat daerah se-kota kotamobagu</small>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            {
              loading
                ?
                <div className='d-flex justify-content-center'>
                  <BeatLoader color='#333' />
                </div>
                :
                <div class="table-responsive">
                  <table className='tb-daftar-dinas'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>OPD</th>
                        <th>Kode barang</th>
                        <th>Nama barang</th>
                        <th>Merk/Type</th>
                        <th>Nomor polisi</th>
                        <th>Pajak tiap tahun</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        daftar_kendaraan.map((v, i) => {
                          return <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{v.opd}</td>
                            <td>{v.kode_barang}</td>
                            <td>{v.nama_barang}</td>
                            <td>{v.merk_type}</td>
                            <td>{v.no_polisi}</td>
                            <td>{formatRupiah(v.rp_pkb)}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
            }
          </div>
        </div>
      </div>
    </main>
  )
}

export default Viewer