import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { error, success } from '../notify'

const Admin_pengguna_tambah = () => {
  window.document.title = "Tambah Pengguna"
  const { state } = useLocation()
  const direct = useNavigate()
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const form_ref = React.useRef()
  const [is_submit, set_submit] = React.useState(false);
  const [nip, set_nip] = React.useState("")
  const [nama_lengkap, set_nama_lengkap] = React.useState("")
  const [username, set_username] = React.useState("")
  const [password, set_password] = React.useState("")
  const [konfirmasi_password, set_konfirmasi_password] = React.useState("")
  const [akses, set_akses] = React.useState("")
  const [verifikasi, set_verifikasi] = React.useState("")
  const [id_opd, set_id_opd] = React.useState([])
  const [opd, set_opd] = React.useState([])

  React.useEffect(() => {
    const getOpd = async () => {
      const param = {
        method: 'get',
        headers: {
          'REST-TOKEN': STATE_AUTH.token,
          'REST-ID-OPD': STATE_AUTH.id_opd
        }
      }

      const requ = await fetch(HOST + '/opd', param);
      const resp = await requ.json()
      const resl = resp;
      if (resl.s) {
        set_opd(resl.d)
      } else {
        error({ msg: resl.m })
      }
    }
    getOpd()
  }, [HOST, STATE_AUTH])



  const handleResetForm = () => {
    set_nip("")
    set_nama_lengkap("")
    set_username("")
    set_password("")
    set_konfirmasi_password("")
    set_akses("")
    set_verifikasi("")
    set_id_opd("")
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    set_submit(true)
    const formData = new FormData()
    formData.append('NIP', nip)
    formData.append('NAMA_LENGKAP', nama_lengkap)
    formData.append('USERNAME', username)
    formData.append('PASSWORD', password)
    formData.append('AKSES', akses)
    formData.append('IS_VERIFIKATOR', verifikasi)
    formData.append('ID_OPD', id_opd)

    if (password !== konfirmasi_password) {
      set_submit(false)
      return alert('Konfirmasi password tidak sama')
    }

    const param = {
      method: 'POST',
      headers: {
        "REST-ID-OPD": STATE_AUTH.id_opd,
        "REST-TOKEN": STATE_AUTH.token
      },
      body: formData
    }
    const sendData = async () => {
      const requ = await fetch(HOST + '/admin/pengguna/simpan', param)
      try {
        const resp = await requ.json()
        const resu = resp;
        if (resu.s) {
          success({ msg: resu.m })
          handleResetForm()
          form_ref.current.reset();
          set_submit(false)
          setTimeout(() => {
            if (window.confirm('Tambah pengguna lagi.?')) {
              return
            } else {
              direct('/admin/pengguna')
            }
          }, 3000)
        } else {
          error({ msg: resu.m })
          set_submit(false)
        }
      } catch (error) {
        error({ msg: 'Masalah ditemukan : ' + error })
        set_submit(false)
      }

    }
    sendData()

  }

  if (!STATE_AUTH.is_login) {
    return <Navigate to="/" />
  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><i className='far fa-user'></i> TAMBAH PENGGUNA BARU</strong></h3>
          </div>
        </div>
        <hr />
        <button className='btn btn-sm btn-warning' onClick={() => { direct('/admin/pengguna', { state: state }) }}> <i className='far fa-arrow-alt-circle-left'></i> Kembali</button>
        <hr />
        <div className="row">
          <form onSubmit={handleSubmit} ref={form_ref}>
            <div className="col-md-12">
              <h6 className='p-2 bg-info'> <i className='fa fa-info-circle'></i> INFORMASI PENGGUNA SYSTEM</h6>
              <div className="input-group input-group-sm mb-1">
                <span className="input-group-text" id="nip" style={{ width: '25%' }}>Nip</span>
                <input name="nip" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" required onChange={e => set_nip(e.target.value)} value={nip} autoComplete="off" />
              </div>
              <div className="input-group input-group-sm mb-1">
                <span className="input-group-text" id="nama" style={{ width: '25%' }}>Nama Lengkap</span>
                <input name="nama" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" required onChange={e => set_nama_lengkap(e.target.value)} value={nama_lengkap} autoComplete="off" />
              </div>
              <div className="input-group input-group-sm mb-1">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Username</span>
                <input name="nip" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_username(e.target.value)} value={username} autoComplete="off" />
              </div>
              <div className="input-group input-group-sm mb-1">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Password</span>
                <input name="password" type="password" className="form-control" minLength="5" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_password(e.target.value)} value={password} autoComplete="off" />
              </div>
              <div className="input-group input-group-sm mb-1">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Konfirmasi password</span>
                <input name="nama" type="password" className="form-control" minLength="5" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_konfirmasi_password(e.target.value)} value={konfirmasi_password} autoComplete="off" />
              </div>
              <div className="input-group input-group-sm mb-1">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Akses</span>
                <select className="form-control" onChange={e => set_akses(e.target.value)} value={akses} required>
                  <option>--Pilih--</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </div>
              <div className="input-group input-group-sm mb-1">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Sebagai Verifikator</span>
                <select className="form-control" onChange={e => set_verifikasi(e.target.value)} value={verifikasi} required>
                  <option>--Pilih--</option>
                  <option value="tidak">Tidak</option>
                  <option value="ya">Ya</option>
                </select>
              </div>
              <div className="input-group input-group-sm mb1">
                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Organisasi Perangkat Daerah</span>
                <select name="id_pengguna" className='form-control' onChange={e => set_id_opd(e.target.value)} required>
                  <option>--Pilih--</option>
                  {opd.map((v, i) => {
                    return (<option key={i} value={v.id}>{v.opd}</option>)
                  })}
                </select>
              </div>
              {
                <button type='submit' className='btn btn-primary mt-2' disabled={is_submit}>
                  <i className='fa fa-save'> </i> {is_submit ? 'Mohon tunggu...' : 'Simpan'}
                </button>
              }

            </div>
          </form>
        </div>
      </div>

    </main>
  )
}

export default Admin_pengguna_tambah;