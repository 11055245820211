import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { error, success } from '../notify'

const Opd_kendaraan_tambah = () => {
      window.document.title = "Tambah Pengguna"
      const direct = useNavigate();
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const form_ref = React.useRef()
      const [is_submit, set_submit] = React.useState(false);

      const [nip, set_nip] = React.useState("")
      const [nama, set_nama] = React.useState("")
      const [jabatan, set_jabatan] = React.useState("")

      const handleResetForm = () => {
            set_nip("")
            set_nama("")
            set_jabatan("")
      }

      const handleSubmit = (e) => {
            e.preventDefault()
            set_submit(true)
            const formData = new FormData()
            formData.append('NIP', nip)
            formData.append('NAMA', nama)
            formData.append('JABATAN', jabatan)

            const param = {
                  method: 'post',
                  headers: {
                        "REST-ID-OPD": STATE_AUTH.id_opd,
                        "REST-TOKEN": STATE_AUTH.token
                  },
                  body: formData
            }
            const sendData = async () => {
                  const requ = await fetch(HOST + '/user/kendaraan/pengguna/simpan', param)
                  try {
                        const resp = await requ.json()
                        const resu = resp;
                        if (resu.s) {
                              success({ msg: resu.m })
                              handleResetForm()
                              form_ref.current.reset();
                              set_submit(false)
                              setTimeout(() => {
                                    if (window.confirm('Tambah pengguna lagi.?')) {
                                          return
                                    } else {
                                          direct('/opd/pengguna/kendaraan')
                                    }
                              }, 3000)
                        } else {
                              error({ msg: resu.m })
                              set_submit(false)
                        }
                  } catch (error) {
                        error({ msg: 'Masalah ditemukan : ' + error })
                  }

            }
            sendData()

      }

      if (!STATE_AUTH.is_login) {
            return <Navigate to="/" />
      }

      return (
            <main>
                  <div className="container-fluid">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='fa fa-user-alt'></i> TAMBAH PENGGUNA KENDARAAN BARU</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <button className='btn btn-sm btn-warning' onClick={() => { direct('/opd/pengguna/kendaraan') }}> <i className='far fa-arrow-alt-circle-left'></i> Kembali</button>
                        <hr />
                        <div className="row">
                              <form onSubmit={handleSubmit} ref={form_ref}>
                                    <div className="col-md-12">
                                          <h6 className='p-2 bg-info'> <i className='fa fa-info-circle'></i> INFORMASI PENGGUNA</h6>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Nip</span>
                                                <input name="nip" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_nip(e.target.value)} value={nip} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Nama</span>
                                                <input name="nama" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_nama(e.target.value)} value={nama} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Jabatan</span>
                                                <input name="jabatan" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_jabatan(e.target.value)} value={jabatan} />
                                          </div>
                                          {
                                                <button type='submit' className='btn btn-primary mt-2' disabled={is_submit}>
                                                      <i className='fa fa-save'> </i> {is_submit ? 'Mohon tunggu...' : 'Simpan'}
                                                </button>
                                          }
                                    </div>
                              </form>
                        </div>
                  </div>
            </main>
      )
}

export default Opd_kendaraan_tambah;