import React from 'react'
import { BsTrash } from 'react-icons/bs';
import { BiUserX, BiUserCheck } from 'react-icons/bi'
import { Navigate, useNavigate } from 'react-router-dom';
import { useStateAuthContext, useStateHostContext } from '../context';
import { error, success } from '../notify';
import { spin as Spin } from '../utils';
import { DotLoader } from 'react-spinners';
import { FaCogs } from 'react-icons/fa';

const Admin_pengguna = () => {
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [penggua, set_pengguna] = React.useState([])
      const [loading, set_loading] = React.useState(false);
      const [loading_status, set_loading_status] = React.useState(false)
      const [id_status, set_id_status] = React.useState("")
      const direct = useNavigate()
      const get_pengguna = React.useCallback(async () => {
            set_loading(true)
            const param = {
                  method: 'get',
                  headers: {
                        'REST-TOKEN': STATE_AUTH.token,
                        'REST-ID-OPD': STATE_AUTH.id_opd
                  }
            }
            const requ = await fetch(HOST + '/admin/pengguna', param);
            const resp = await requ.json()
            const resl = resp;
            if (resl.s) {
                  set_pengguna(resl.d)
                  set_loading(false)
            } else {
                  error({ msg: resl.m })
                  set_loading(false)
            }
      }, [STATE_AUTH, HOST])

      React.useEffect(() => {
            window.document.title = "Pengguna Sistem"
            get_pengguna()
            return () => { set_pengguna([]) }
      }, [get_pengguna])

      const handleStatusAktif = async (ID) => {
            set_loading_status(true)
            set_id_status(ID)
            const fd = new FormData()
            fd.append('ID_PENGGUNA', ID)
            const requ = await fetch(`${HOST}/admin/pengguna/status_pengguna/aktif`, { method: 'POST', body: fd })
            try {
                  const resu = await requ.json()
                  if (resu.s) {
                        success({ msg: resu.m })
                        get_pengguna()
                        set_loading_status(false)
                  } else {
                        error({ msg: resu.m })
                  }
                  set_loading_status(false)
            } catch (err) {
                  error({ msg: err.message })
            }
      }

      const handleStatusNonaktif = async (ID) => {
            set_loading_status(true)
            set_id_status(ID)
            const fd = new FormData()
            fd.append('ID_PENGGUNA', ID)
            const requ = await fetch(`${HOST}/admin/pengguna/status_pengguna/nonaktif`, { method: 'POST', body: fd })
            try {
                  const resu = await requ.json()
                  if (resu.s) {
                        success({ msg: resu.m })
                        get_pengguna()
                        set_loading_status(false)
                  } else {
                        error({ msg: resu.m })
                  }
                  set_loading_status(false)
            } catch (err) {
                  error({ msg: err.message })
            }
      }

      const handleHapusUser = async (ID) => {
            if (window.confirm('Yakin akan menghapus pengguna ini.?')) {
                  const fd = new FormData()
                  fd.append('ID_PENGGUNA', ID)
                  const requ = await fetch(`${HOST}/admin/pengguna/hapus`, { method: 'POST', body: fd })
                  try {
                        const resu = await requ.json()
                        if (resu.s) {
                              success({ msg: resu.m })
                              get_pengguna()
                        } else {
                              error({ msg: resu.m })
                        }
                  } catch (err) {
                        error({ msg: err.message })
                  }
            }

      }

      if (!STATE_AUTH.is_login) {
            return <Navigate to="/login" />
      }

      return (
            <main>
                  <div className="container-fluid mt-3">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='fas fa-users'></i> DAFTAR PENGGUNA SISTEM</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <button className='btn btn-primary btn-sm me-1' onClick={() => { direct('/admin/pengguna/tambah') }}>+ Tambah</button>
                        {/* <button className='btn btn-warning btn-sm' onClick={() => { direct('/admin/pengguna/menu') }}><FaCogs /> Akses Menu</button> */}
                        <hr />
                        <div className="row">
                              <div className="col-md-12">
                                    <div className="table-responsive">
                                          <table className='tb-daftar-dinas' style={{ fontSize: 14 }}>
                                                <thead>
                                                      <tr>
                                                            <th>#</th>
                                                            <th>NIP</th>
                                                            <th>NAMA LENGKAP</th>
                                                            <th>USERNAME</th>
                                                            <th>AKSES</th>
                                                            <th>SEBAGAI VERIFIKATOR</th>
                                                            <th>OPD</th>
                                                            <th></th>
                                                      </tr>
                                                </thead>
                                                <tbody>
                                                      {
                                                            !loading
                                                                  ?
                                                                  penggua.map((v, i) => {
                                                                        return (
                                                                              <tr key={i}>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>{v.nip}</td>
                                                                                    <td>{v.nama_lengkap}</td>
                                                                                    <td>{v.username}</td>
                                                                                    <td>{v.role}</td>
                                                                                    <td>{
                                                                                          v.is_verifikator === 'ya'
                                                                                                ?
                                                                                                <span className='badge bg-info text-dark'>YA</span>
                                                                                                :
                                                                                                <span className='badge bg-danger'>TIDAK</span>
                                                                                    }</td>
                                                                                    <td>{v.opd ? v.opd : '-'}</td>
                                                                                    <td>
                                                                                          <div className="btn-group btn-group-sm" role="group" aria-label="Basic mixed styles example">
                                                                                                {
                                                                                                      v.status === "aktif"
                                                                                                            ?
                                                                                                            <button type="button" className="btn btn-success btn-sm" onClick={handleStatusNonaktif.bind(this, v.id_pengguna)} title={"Nonaktifkan"}>
                                                                                                                  {
                                                                                                                        loading_status
                                                                                                                              ?
                                                                                                                              id_status === v.id_pengguna ? <DotLoader color="#36d7b7" size={16} /> : <BiUserCheck size={24} />
                                                                                                                              :
                                                                                                                              <BiUserCheck size={24} />
                                                                                                                  }
                                                                                                            </button>
                                                                                                            :
                                                                                                            <>
                                                                                                                  <button type="button" className="btn btn-warning btn-sm" onClick={handleStatusAktif.bind(this, v.id_pengguna)} title={"Aktifkan"}>
                                                                                                                        {
                                                                                                                              loading_status
                                                                                                                                    ?
                                                                                                                                    id_status === v.id_pengguna ? <DotLoader color="#36d7b7" size={16} /> : <BiUserX size={24} />
                                                                                                                                    :
                                                                                                                                    <BiUserX size={24} />
                                                                                                                        }
                                                                                                                  </button>
                                                                                                            </>
                                                                                                }
                                                                                                <button type="button" className="btn btn-danger btn-sm" title='Hapus pengguna ini?' onClick={handleHapusUser.bind(this, v.id_pengguna)}><BsTrash /></button>
                                                                                                {
                                                                                                      (v.is_verifikator === 'ya' || v.is_guest === 'ya')
                                                                                                            ?
                                                                                                            ''
                                                                                                            :
                                                                                                            <button type="button" className="btn btn-warning btn-sm" title='Akses menu' onClick={() => direct('/admin/pengguna/menu', { state: { id_pengguna: v.id_pengguna, nama_pengguna: v.nama_lengkap } })}><FaCogs /></button>
                                                                                                }
                                                                                          </div>
                                                                                    </td>
                                                                              </tr>
                                                                        )
                                                                  })
                                                                  :
                                                                  <tr><td colSpan={7} className="text-center"><Spin /></td></tr>
                                                      }
                                                </tbody>
                                          </table>
                                    </div>
                              </div>
                        </div>
                  </div>
            </main>
      )
}

export default Admin_pengguna
