import React from 'react'
import { useStateAuthContext, useStateHostContext } from '../context';
import {  FaFileAlt, FaInfoCircle, FaList } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { info, success, error } from '../notify';
import { Accordion, Button, Form, Modal } from 'react-bootstrap';

const Admin_laporan_kendaraan_lelang = () => {
  window.document.title = "Kendaraan"
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [data_penghapusan_kendaraan, set_data_penghapusan_kendaraan] = React.useState([]);
  const [loading, set_loading] = React.useState([]);
  const [show, set_show] = React.useState(false);
  const [nomor_risalah, set_nomor_risalah] = React.useState('');
  const [file_sk_penghapusan, set_file_sk_penghapusan] = React.useState(null);

  const ref_sile_sk_penghapusan = React.useRef();

  const handleShow = () => set_show(true);
  const handleHide = () => set_show(false);
  const handleGetDataKendaraan = React.useCallback(async () => {
    set_loading(true)
    try {
      const request = await fetch(HOST + '/admin/kendaraan/lelang/daftar_kendaraan_akan_dihapus', {
        method: 'GET',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        }
      })
      const response = await request.json()

      if (request.ok) {
        if (response.s) {
          set_data_penghapusan_kendaraan(response.d)
          set_loading(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      info({ msg: err.message })
      set_loading(false)
    }
  }, [HOST, STATE_AUTH])

  const hanldeSubmitBerkas = async (e) => {
    e.preventDefault()
    set_loading(true)
    const formData = new FormData()
    formData.append('NOMOR_RISALAH', nomor_risalah)
    formData.append('FILE_SK_PENGHAPUSAN', file_sk_penghapusan)
    try {
      const request = await fetch(HOST + '/admin/kendaraan/lelang/penghapusan', {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          handleGetDataKendaraan()
          handleHide()
          set_loading(false)
          ref_sile_sk_penghapusan.current.value = null
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }
  }

  React.useEffect(() => {
    handleGetDataKendaraan()
  }, [handleGetDataKendaraan])

  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row my-2">
          <div className="col-md-12">
          <div className='alert alert-warning'><FaInfoCircle /> Daftar kendaraan yang akan dihapus</div>
            {
              !loading
                ?
                data_penghapusan_kendaraan.length !== 0
                  ?
                  <Accordion defaultActiveKey="0">
                    {
                      data_penghapusan_kendaraan.map((val, ind) => {
                        return (
                          <Accordion.Item eventKey={ind}>
                            <Accordion.Header>Nomor Risalah Lelang : {val.nomor_risalah}</Accordion.Header>
                            <Accordion.Body>
                              <div className="table-requestonsive">
                                <span><FaList /> Daftar kendaraan yang akan dihapus</span>
                                <table className='tb-daftar-dinas mt-1' >
                                  <thead style={{ fontSize: '13px' }}>
                                    <tr>
                                      <th>NO</th>
                                      <th>KODE BARANG</th>
                                      <th>NAMA KENDARAAN</th>
                                      <th>NOMOR REGISTER</th>
                                      <th>MERK/TYPE</th>
                                      <th>UKURAN/CC</th>
                                      <th>TAHUN</th>
                                      <th>WARNA</th>
                                      <th>NOMOR POLISI</th>
                                      <th>FILE</th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ fontSize: '12px' }}>
                                    {
                                      val.data.map((v, i) => {
                                        return (
                                          <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{v.kode_barang}</td>
                                            <td>{v.jenis}</td>
                                            <td>{v.nomor_register}</td>
                                            <td>{v.merk_type}</td>
                                            <td>{v.ukuran_cc}</td>
                                            <td>{v.tahun_pembuatan}</td>
                                            <td>{v.warna}</td>
                                            <td>{v.no_polisi}</td>
                                            <td></td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                                </table>

                              </div>
                              <Button size='sm' onClick={() => { handleShow(); set_nomor_risalah(val.nomor_risalah) }} className='btn btn-info my-2'><FaFileAlt /> Upload Dokumen Penghapusan</Button>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })
                    }
                  </Accordion>
                  :
                  <div className='text-center d-flex justify-content-center align-items-center'>
                    Tidak ada daftar kendaraan
                  </div>
                :
                <div className='text-center d-flex justify-content-center align-items-center'>
                  <BeatLoader />
                </div>
            }

          </div>
        </div>
        <div className="row mt-2">
          <div className='col-md-12'>
            <Modal show={show} onHide={handleHide} backdrop='static'>
              <Form onSubmit={hanldeSubmitBerkas}>
                <Modal.Header closeButton>
                  <Modal.Title>Upload Berkas Penghapusan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className='mb-2'>
                    <Form.Label>Nomor Risalah</Form.Label>
                    <Form.Control type='text' size='sm' required readOnly value={nomor_risalah} />
                  </Form.Group>
                  <Form.Group className='mb-2'>
                    <Form.Label>Berkas Penghapusan</Form.Label>
                    <Form.Control type='file' size='sm' ref={ref_sile_sk_penghapusan} required onChange={(e) => set_file_sk_penghapusan(e.target.files[0])} />
                    <small className='text-mutted'>Pastikan format yang diupload adalah extensi *.pdf</small>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  {
                    loading
                      ?
                      <Button size='sm'  className="my-2" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                        <span role="status"> Mohon tunggu...</span>
                      </Button>
                      :
                      <Button variant='primary' size='sm' type='submit'>Upload</Button>
                  }

                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_laporan_kendaraan_lelang