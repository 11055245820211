import React from "react";
import { NavLink } from "react-router-dom";
import { useStateAuthContext } from "../context";
import { FaCarAlt, FaCarSide, FaChartLine, FaCheckCircle, FaCog, FaFileAlt, FaSearch, FaSignOutAlt, FaSyncAlt, FaTachometerAlt, FaUserAlt } from "react-icons/fa";

export const Sidebar = () => {


      const { STATE_AUTH, DISPATCH_AUTH } = useStateAuthContext();
      const handleLogout = () => {
            DISPATCH_AUTH({ type: 'LOGOUT' })
      }
      let bgc;
      if (STATE_AUTH.is_super_admin) {
            bgc = '#3b414d'
      } else if (STATE_AUTH.is_admin) {
            bgc = '#3b414d'
      } else if (STATE_AUTH.is_opd) {
            bgc = STATE_AUTH.is_guest ? '#1a3c70' : '#5c6578'
      } else {
            bgc = '#333'
      }

      const menu_admin = (
            <div className="nav">
                  <h6 className="sb-sidenav-menu-heading border-bottom border-1 bg-dark">Menu</h6>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin" end><div className="sb-nav-link-icon"><FaTachometerAlt size={20} /></div>Dashboard</NavLink>
                  <hr />
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/kendaraan"><div className="sb-nav-link-icon"><FaCarAlt size={20} /></div> Kendaraan Per OPD</NavLink>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/kendaraan_lelang"><div className="sb-nav-link-icon"><FaCarAlt size={20} /></div> Kendaraan Lelang</NavLink>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/kendaraan_mutasi"><div className="sb-nav-link-icon"><FaCarAlt size={20} /></div> Kendaraan Mutasi</NavLink>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/kendaraan_cari"><div className="sb-nav-link-icon"><FaSearch size={20} /></div> Cari Kendaraan</NavLink>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/kendaraan_statistik" end><div className="sb-nav-link-icon"><FaChartLine size={20} /></div> Kendaraan Statistik</NavLink>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/laporan/kendaraan"><div className="sb-nav-link-icon"><FaFileAlt size={20} /></div> Laporan Kendaraan</NavLink>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/laporan/pkb" end><div className="sb-nav-link-icon"><FaFileAlt size={20} /></div> Laporan PKB</NavLink>
                  {
                        STATE_AUTH.is_verifikator === 'ya'
                              ?
                              <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/opd/kendaraan/pkb/verifikasi"><div className="sb-nav-link-icon"><FaCheckCircle size={20} /></div> Verifikasi PKB</NavLink>
                              :
                              ''
                  }
                  <hr />
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/pengguna"><div className="sb-nav-link-icon"><FaUserAlt size={20} /></div> Manajemen User</NavLink>
                  <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/pengaturan" end><div className="sb-nav-link-icon"><FaCog size={20} /></div> Pengaturan</NavLink>
                  <NavLink onClick={handleLogout} className="nav-link" to="/"><div className="sb-nav-link-icon"><FaSignOutAlt size={20} /></div> Keluar</NavLink>
            </div>
      )

      const menu_user = (
            <div className="nav">
                  <h6 className="sb-sidenav-menu-heading border-bottom border-1 bg-dark">Menu</h6>
                  {
                        STATE_AUTH.is_guest
                              ?
                              <>
                                    <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/viewer"><div className="sb-nav-link-icon"><FaCarAlt size={20} /></div> Daftar Kendaraan</NavLink>
                                    <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-danger active' : 'nav-link'} to="/admin/kendaraan_cari"><div className="sb-nav-link-icon"><FaSearch size={20} /></div> Cari Kendaraan</NavLink>
                                    <NavLink className='nav-link' onClick={handleLogout} to="/" ><div className="sb-nav-link-icon"><FaSignOutAlt size={20} /></div> Keluar</NavLink>
                              </>
                              :
                              STATE_AUTH.is_verifikator === 'ya'
                                    ?
                                    <>

                                          <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-info active' : 'nav-link'} to="/opd" end><div className="sb-nav-link-icon"><FaTachometerAlt size={20} /></div> Dashboard</NavLink>
                                          <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-info active' : 'nav-link'} to="/opd/kendaraan/pkb/verifikasi"><div className="sb-nav-link-icon"><FaCheckCircle size={20} /></div> Verifikasi PKB</NavLink>
                                          <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-info active' : 'nav-link'} to="/opd/kendaraan/pkb/laporan" end><div className="sb-nav-link-icon"><FaFileAlt size={20} /></div> Laporan PKB</NavLink>
                                          <NavLink className='nav-link' onClick={handleLogout} to="/" ><div className="sb-nav-link-icon"><FaSignOutAlt size={20} /></div> Keluar</NavLink>

                                    </>
                                    :
                                    <>
                                          <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-success active' : 'nav-link'} to="/opd" end><div className="sb-nav-link-icon"><FaTachometerAlt size={20} /></div> Dashboard</NavLink>
                                          <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-success active' : 'nav-link'} to="/opd/kendaraan"><div className="sb-nav-link-icon"><FaCarAlt size={20} /></div> Kendaraan</NavLink>
                                          <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-success active' : 'nav-link'} to="/opd/pengguna/kendaraan" end><div className="sb-nav-link-icon"><FaUserAlt size={20} /></div> Pengguna Kendaraan</NavLink>
                                          <NavLink className={({ isActive }) => isActive ? 'nav-link bg-dark border-1 border-bottom border-success active' : 'nav-link'} to="/opd/profil" end><div className="sb-nav-link-icon"><FaUserAlt size={20} /></div> Profil</NavLink>
                                          <NavLink onClick={handleLogout} className="nav-link" to="/"><div className="sb-nav-link-icon"><FaSignOutAlt size={20} /></div> Keluar</NavLink>
                                    </>
                  }
            </div>
      )

      return (
            <>
                  <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion" style={{ backgroundColor: bgc }}>
                        <div className="sb-sidenav-menu">
                              {
                                    STATE_AUTH.is_super_admin || STATE_AUTH.is_admin
                                          ?
                                          menu_admin
                                          :
                                          menu_user}
                        </div>
                        <div className="sb-sidenav-footer">
                              <div className="small"> Sebagai:</div>
                              ({STATE_AUTH.nama_lengkap})
                        </div>
                  </nav>
            </>

      )
}

export default Sidebar;