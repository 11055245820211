import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { error, success } from '../notify'
import { formatRupiahOnchange } from '../utils'

const Admin_kendaraan_tambah = () => {
      const { state } = useLocation()
      window.document.title = "Tambah Kendaraan"
      const direct = useNavigate()
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [pengguna, set_pengguna] = React.useState([])
      const form_ref = React.useRef()
      const [is_submit, set_submit] = React.useState(false);

      const [kode_barang, set_kode_barang] = React.useState("")
      const [jenis, set_jenis] = React.useState("")
      const [nama_barang, set_nama_barang] = React.useState("")
      const [nomor_register, set_nomor_register] = React.useState("")
      const [merk_type, set_merk_type] = React.useState("")
      const [ukuran_cc, set_ukuran_cc] = React.useState("")
      const [no_rangka, set_no_rangka] = React.useState("")
      const [no_mesin, set_no_mesin] = React.useState("")
      const [no_polisi, set_no_polisi] = React.useState("")
      const [no_bpkb, set_no_bpkb] = React.useState("")
      const [tgl_jth_tmp_stnk, set_tgl_jth_tmp_stnk] = React.useState("")
      const [tgl_jth_tmp_pkb, set_tgl_jth_tmp_pkb] = React.useState("")
      const [rp_pkb, set_rp_pkb] = React.useState("")
      const [id_pengguna, set_id_pengguna] = React.useState("")
      const [sub_jenis_value, set_sub_jenis_value] = React.useState("")
      const [sub_jenis, set_sub_jenis] = React.useState([])
      const [sub_jenis_motor] = React.useState(['motor'])
      const [sub_jenis_mobil] = React.useState(['minibus', 'sedan', 'truck', 'jeep', 'bus', 'double_cabin', 'pickup'])
      const [sub_jenis_alat_berat] = React.useState(['alat_berat'])

      React.useEffect(() => {
            const param = {
                  method: 'get',
                  headers: {
                        "REST-ID-OPD": state.id,
                        "REST-TOKEN": STATE_AUTH.token
                  }
            }
            const getPengguna = async () => {
                  const requ = await fetch(HOST + '/admin/kendaraan/pengguna/tidak', param)
                  try {
                        const resp = await requ.json()
                        const resu = resp;
                        set_pengguna(resu.d)
                  } catch (e) {
                        error({ msg: 'Masalah ditemukan : ' + e })
                  }

            }
            getPengguna()
      }, [STATE_AUTH, HOST, state])

      const handleJenis = (e) => {
            const val = e.target.value;
            set_jenis(val)
            switch (val) {
                  case 'motor':
                        set_sub_jenis(sub_jenis_motor)
                        break;
                  case 'mobil':
                        set_sub_jenis(sub_jenis_mobil)
                        break;
                  default:
                        set_sub_jenis(sub_jenis_alat_berat)
                        break;
            }
      }

      const handleResetForm = () => {
            set_kode_barang("")
            set_jenis("")
            set_nama_barang("")
            set_nomor_register("")
            set_merk_type("")
            set_ukuran_cc("")
            set_no_rangka("")
            set_no_mesin("")
            set_no_polisi("")
            set_no_bpkb("")
            set_tgl_jth_tmp_stnk("")
            set_tgl_jth_tmp_pkb("")
            set_rp_pkb("")
            set_id_pengguna("")
            set_sub_jenis_value("")
      }

      const handleSubmit = (e) => {
            e.preventDefault()
            set_submit(true)
            const formData = new FormData()
            formData.append('KODE_BARANG', kode_barang)
            formData.append('JENIS', jenis)
            formData.append('SUB_JENIS', sub_jenis_value)
            formData.append('NAMA_BARANG', nama_barang)
            formData.append('NOMOR_REGISTER', nomor_register)
            formData.append('MERK_TYPE', merk_type)
            formData.append('UKURAN_CC', ukuran_cc)
            formData.append('NO_RANGKA', no_rangka)
            formData.append('NO_MESIN', no_mesin)
            formData.append('NO_POLISI', no_polisi)
            formData.append('NO_BPKB', no_bpkb)
            formData.append('TGL_JTH_TMP_STNK', tgl_jth_tmp_stnk)
            formData.append('TGL_JTH_TMP_PKB', tgl_jth_tmp_pkb)
            formData.append('RP_PKB', rp_pkb)
            formData.append('ID_PENGGUNA', id_pengguna)
            const param = {
                  method: 'post',
                  headers: {
                        "REST-ID-OPD": state.id,
                        "REST-TOKEN": STATE_AUTH.token
                  },
                  body: formData
            }
            const sendData = async () => {
                  const requ = await fetch(HOST + '/admin/kendaraan/simpan', param)
                  try {
                        const resp = await requ.json()
                        const resu = resp;
                        if (resu.s) {
                              success({ msg: resu.m })
                              handleResetForm()
                              form_ref.current.reset();
                              set_submit(false)
                        } else {
                              error({ msg: resu.m })
                              set_submit(false)
                        }
                  } catch (error) {
                        error({ msg: 'Masalah ditemukan : ' + error })
                  }

            }
            sendData()

      }



      if (!STATE_AUTH.is_login) {
            return <Navigate to="/" />
      }

      return (
            <main>
                  <div className="container-fluid">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='far fa-building'></i> {state.opd.toUpperCase()}</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <button className='btn btn-sm btn-warning' onClick={() => { direct('/admin/kendaraan/detail', { state: state }) }}> <i className='far fa-arrow-alt-circle-left'></i> Kembali</button>
                        <hr />
                        <div className="row">
                              <form onSubmit={handleSubmit} ref={form_ref}>
                                    <div className="col-md-12">
                                          <h6 className='p-2 bg-info'> <i className='fa fa-info-circle'></i> INFORMASI KENDARAAN</h6>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Kode Barang</span>
                                                <input name="kode_barang" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" required onChange={e => set_kode_barang(e.target.value)} value={kode_barang} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Jenis Barang</span>
                                                <select name="jenis" className='form-control' required onChange={handleJenis} value={jenis} >
                                                      <option>--Pilih--</option>
                                                      <option value="motor">MOTOR</option>
                                                      <option value="mobil">MOBIL</option>
                                                      <option value="alat_berat">ALAT BERAT</option>
                                                </select>
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Sub Jenis Barang</span>
                                                <select name="jenis" className='form-control' required onChange={e => set_sub_jenis_value(e.target.value)} value={sub_jenis_value} >
                                                      <option>--Pilih--</option>
                                                      {
                                                            sub_jenis.map((v, i) => {
                                                                  return <option value={v} key={i}>{v.toUpperCase()}</option>
                                                            })
                                                      }
                                                </select>
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Nama Barang</span>
                                                <input name="nama_barang" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_nama_barang(e.target.value)} value={nama_barang} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Nomor Register</span>
                                                <input name="nomor_register" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_nomor_register(e.target.value)} value={nomor_register} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Merk/Type</span>
                                                <input name="merk_type" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_merk_type(e.target.value)} value={merk_type} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Ukuran/CC</span>
                                                <input name="ukuran_cc" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_ukuran_cc(e.target.value)} value={ukuran_cc} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Nomor Rangka</span>
                                                <input name="no_rangka" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_no_rangka(e.target.value)} value={no_rangka} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Nomor Mesin</span>
                                                <input name="no_mesin" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_no_mesin(e.target.value)} value={no_mesin} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Nomor Polisi</span>
                                                <input name="no_polisi" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_no_polisi(e.target.value)} value={no_polisi} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Nomor BPKB</span>
                                                <input name="no_bpkb" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_no_bpkb(e.target.value)} value={no_bpkb} />
                                          </div>
                                          <h6 className='p-2 bg-info'> <i className='fa fa-info-circle'></i> INFORMASI PAJAK DAN PENGGUNA KENDARAAN</h6>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Tanggal Jatuh Tempo STNK</span>
                                                <input name="tgl_jth_tmp_stnk" type="date" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_tgl_jth_tmp_stnk(e.target.value)} value={tgl_jth_tmp_stnk} />
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Tanggal Jatuh Tempo PKB</span>
                                                <input name="tgl_jth_tmp_pkb" type="date" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_tgl_jth_tmp_pkb(e.target.value)} value={tgl_jth_tmp_pkb} required/>
                                          </div>
                                          <div className="input-group input-group-sm mb-1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>PKB Harus dibayarkan</span>
                                                <input name="rp_pkb" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" placeholder='Rp. ' onChange={e => set_rp_pkb(formatRupiahOnchange(e.target.value, 'Rp. '))} value={rp_pkb} required/>
                                          </div>
                                          <div className="input-group input-group-sm mb1">
                                                <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Pengguna Kendaraan</span>
                                                {
                                                      pengguna.length !== 0
                                                            ?
                                                            <select name="id_pengguna" className='form-control' required onChange={e => set_id_pengguna(e.target.value)} value={id_pengguna} >
                                                                  <option>--Pilih--</option>
                                                                  {pengguna.map((v, i) => {
                                                                        return (<option key={i} value={v.id}>{v.nama}</option>)
                                                                  })}
                                                            </select>
                                                            :
                                                            <button className='btn btn-sm btn-primary' onClick={() => direct("/admin/kendaraan/pengguna/tambah", { state: state })}>+ Pengguna Kendaraan (Opsional)</button>
                                                }

                                          </div>
                                          {
                                                is_submit
                                                      ?
                                                      <button type='button' className='btn btn-warning btn-sm mt-2'>
                                                            <i className='fa fa-save'> </i> Mohon tunggu...
                                                      </button>
                                                      :
                                                      <button type='submit' className='btn btn-primary btn-sm mt-2'>
                                                            <i className='fa fa-save'> </i> Simpan
                                                      </button>
                                          }

                                    </div>
                              </form>
                        </div>
                  </div>
            </main>
      )
}

export default Admin_kendaraan_tambah;