const spin = () => {
  return (<div className="spinner-border text-center" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>)
}

const formatRupiah = (rp) => {
  return new Intl.NumberFormat('id-ID',
    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(rp);
}

const formatRupiahOnchange = (angka, prefix) => {
  let separator;
  let number_string = angka.replace(/[^,\d]/g, '').toString(),
    split = number_string.split(','),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  if (ribuan) {
    separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}

const breakpoint = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)'
};

const getBreakpoint = () => {
  if (window.matchMedia(breakpoint.desktop).matches) {
    return { mode: 'desktop', height: window.innerHeight };
  } else if (window.matchMedia(breakpoint.tablet).matches) {
    return { mode: 'tablet', height: window.innerHeight };
  } else if (window.matchMedia(breakpoint.mobile).matches) {
    return { mode: 'mobile', height: window.innerHeight };
  } else {
    return { mode: 'auto', height: 'auto' };
  }
};

export { spin, formatRupiah, formatRupiahOnchange, getBreakpoint }