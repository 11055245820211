import React from 'react'
import { useStateAuthContext, useStateHostContext } from '../context';
import { FaFile, FaInfoCircle } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { success } from '../notify';
import { error } from 'jquery';

const Admin_kendaraan_lelang_penilaian = () => {
  window.document.title = "Kendaraan"
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [data_kendaraan, set_kendaraan] = React.useState([])
  const [data_total, set_total] = React.useState({})
  const [pilih_data, set_pilih_data] = React.useState([])
  const [pilih_semua_data, set_pilih_semua_data] = React.useState(false)
  const [loading_lelang, set_loading_lelang] = React.useState(false)

  const handleGetDataKendaraan = React.useCallback(async () => {
    const resp = await fetch(HOST + '/admin/kendaraan/laporan/penilaian', {
      method: 'GET',
      headers: {
        'REST-TOKEN': STATE_AUTH.token
      }
    })
    const resu = await resp.json()
    let total = { motor: 0, mobil: 0, alat_berat: 0 }
    resu.d.forEach((v) => {
      if (v.jenis === "motor") { total.motor++ }
      if (v.jenis === "mobil") { total.mobil++ }
      if (v.jenis === "alat_berat") { total.alat_berat++ }
    })
    set_total(total)
    set_kendaraan(resu.d)
  }, [HOST, STATE_AUTH])


  const handlePilihId = (id) => {
    if (pilih_data.includes(id)) {
      set_pilih_data(pilih_data.filter((rowId) => rowId !== id));
    } else {
      set_pilih_data([...pilih_data, id]);
    }
  };

  const handlePilihSemuaId = () => {
    if (pilih_semua_data) {
      set_pilih_data([]);
    } else {
      const semua_id = data_kendaraan.map((item) => item.id_kendaraan);
      set_pilih_data(semua_id);
    }
    set_pilih_semua_data(!pilih_semua_data);
  };

  const handleSubmitLelang = async () => {
    if (!window.confirm('Yakin ingin mengupdate data yang dipilih.?')) {
      return
    }
    set_loading_lelang(true);
    const formData = new FormData()
    formData.append('ID_DATA_KENDARAAN', pilih_data)
    try {
      const reqest = await fetch(HOST + '/admin/kendaraan/lelang/update_semua', {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await reqest.json()
      if (reqest.ok) {
        if (response.s) {
          success({ msg: response.m })
          set_pilih_semua_data([])
          set_pilih_data([])
          handleGetDataKendaraan()
          set_loading_lelang(false)
        } else {
          error({ msg: response.m })
          set_loading_lelang(false)
        }

      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading_lelang(false)
    }
  }

  const cek_total = Object.keys(data_total).length === 0

  React.useEffect(() => {
    handleGetDataKendaraan()
  }, [handleGetDataKendaraan])



  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-2">
          <div className="col-md-12">
          <div className='alert alert-info'><FaInfoCircle /> Daftar kendaraan yang masuk penilaian lelang</div>
            <div className="table-responsive">
              {
                !cek_total
                  ?
                  <table className='tb-daftar-dinas mt-1' >
                    <thead style={{ fontSize: '13px' }}>
                      <tr>
                        <th>
                          <input
                            onClick={() => handlePilihSemuaId()}
                            type="checkbox"
                            checked={pilih_data.length !== 0}
                            readOnly
                          />
                        </th>
                        <th>KODE BARANG</th>
                        <th>NAMA KENDARAAN</th>
                        <th>NOMOR REGISTER</th>
                        <th>MERK/TYPE</th>
                        <th>UKURAN/CC</th>
                        <th>TAHUN</th>
                        <th>WARNA</th>
                        <th>NOMOR POLISI</th>
                        <th colSpan={2}>File SK</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: '12px' }}>
                      {

                        data_kendaraan.length !== 0
                          ?
                          data_kendaraan.map((v, k) => {
                            return <tr
                              key={k}
                              title='Kendaraan ini dalam tahap penilaian'
                              style={{
                                backgroundColor: pilih_data.includes(v.id_kendaraan) ? 'lightblue' : null,
                              }}>
                              <td>
                                <input
                                  onClick={() => handlePilihId(v.id_kendaraan)}
                                  type="checkbox"
                                  checked={pilih_data.includes(v.id_kendaraan)}
                                  readOnly
                                />
                              </td>
                              <td>
                                {v.kode_barang} <br />
                                <small className='text-muted'>{v.opd}</small>
                              </td>
                              <td><small>{v.nama_barang}</small></td>
                              <td><small>{v.nomor_register}</small></td>
                              <td>
                                <small>{v.merk_type}</small>
                              </td>
                              <td>{v.ukuran_cc}</td>
                              <td>
                                <small>Pembuatan : {v.tahun_pembuatan}</small> <br />
                                <small>Pembelian : {v.tahun_pembelian}</small>
                              </td>
                              <td>{v.warna}</td>
                              <td>{v.no_polisi}</td>
                              <td>
                                <div className="btn-group" role="group">
                                  <button onClick={() => window.open(`${v.file_sk_penilaian_lelang}`, '_blank')} className='btn btn-sm btn-outline-primary' title='File SK Penilaian'><FaFile size={20} className='text-warning' /></button>
                                </div>
                              </td>
                            </tr>
                          })
                          :
                          <tr><td colSpan={10} className='text-center'>(Kendaraan tidak tersedia)</td></tr>
                      }
                    </tbody>
                  </table>
                  :
                  <div className='text-center d-flex justify-content-center align-items-center'>
                    <BeatLoader />
                  </div>
              }
              {
                pilih_data.length !== 0
                  ?
                  <>
                    {
                      loading_lelang
                        ?
                        <button className="btn btn-outline-info btn-sm my-2" type="button" disabled>
                          <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                          <span role="status"> Mohon tunggu...</span>
                        </button>
                        :
                        <button className='btn btn-outline-info btn-sm my-2' onClick={handleSubmitLelang}>Lanjut Lelang <strong>{pilih_data.length}</strong> Kendaraan</button>
                    }

                  </>
                  :
                  ''
              }
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_kendaraan_lelang_penilaian