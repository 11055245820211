import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { success, error } from '../notify'
import { spin as Spin } from '../utils'
import faAlatBerat from '../assets/img/fa-alat-berat.png'
import { FaCarAlt, FaListAlt, FaMotorcycle, FaMoneyCheckAlt, FaUserAlt, FaFileAlt, FaUserTimes, FaIdCardAlt, FaFileImport, FaPlus } from 'react-icons/fa';
import { Modal } from 'react-bootstrap'

const Opd_kendaraan = () => {
      window.document.title = "Kendaraan"
      const direct = useNavigate()
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [data_kendaraan, set_kendaraan] = React.useState([])
      const [data_total, set_total] = React.useState({})
      const [data_pengguna, set_data_pengguna] = React.useState([])
      const [load_pengguna, set_load_pengguna] = React.useState(false)
      const [show, set_show] = React.useState(false)
      const [id_pengguna, set_id_pengguna] = React.useState(null)
      const [id_kendaraan, set_id_kendaraan] = React.useState(null)
      const [file_bast, set_file_bast] = React.useState(null)
      const [loading, set_loading] = React.useState(false)

      const handleAmbilDataKendaraan = React.useCallback(async () => {
            const resp = await fetch(HOST + '/user/kendaraan', {
                  method: 'GET',
                  headers: {
                        'REST-TOKEN': STATE_AUTH.token,
                        'REST-ID-OPD': STATE_AUTH.id_opd
                  }
            })
            const resu = await resp.json()
            let total = { motor: 0, mobil: 0, alat_berat: 0 }
            resu.d.forEach((v) => {
                  if (v.jenis === "motor") { total.motor++ }
                  if (v.jenis === "mobil") { total.mobil++ }
                  if (v.jenis === "alat_berat") { total.alat_berat++ }
            })
            set_total(total)
            set_kendaraan(resu.d)
      }, [HOST, STATE_AUTH])

      const handleAmbilDataPengguna = React.useCallback(async () => {
            const resp = await fetch(HOST + '/user/kendaraan/pengguna', {
                  method: 'GET',
                  headers: {
                        'REST-TOKEN': STATE_AUTH.token,
                        'REST-ID-OPD': STATE_AUTH.id_opd
                  }
            })
            const resu = await resp.json()
            set_data_pengguna(resu.d)
            set_load_pengguna(true)
      }, [HOST, STATE_AUTH])

      const handleShow = (obj) => {
            set_show(true)
            set_id_kendaraan(obj.id_kendaraan)
      }

      const handleClose = () => {
            set_show(false)
      }

      const handleRiwayatPKB = (obj) => {
            direct("/opd/kendaraan/pkb", { state: { id_kendaraan: obj.id } })
      }

      const handleSubmitPenggunaKkendaraan = async (e) => {
            e.preventDefault();
            set_loading(true)
            const data = new FormData()
            data.append('ID_KENDARAAN', id_kendaraan)
            data.append('ID_PENGGUNA_KENDARAAN', id_pengguna)
            data.append('FILE_BAST', file_bast)

            if (id_pengguna === null) {
                  error({ msg: "Pilih salah satu pengguna." })
                  set_loading(false)
                  return
            }

            try {
                  const request = await fetch(HOST + '/user/kendaraan/pengguna/pilih', {
                        method: 'POST',
                        headers: {
                              'REST-TOKEN': STATE_AUTH.token,
                              'REST-ID-OPD': STATE_AUTH.id_opd
                        },
                        body: data
                  })
                  const response = await request.json();

                  if (request.ok) {
                        if (response.s) {
                              success({ msg: response.m })
                              handleAmbilDataKendaraan()
                              handleClose()
                              set_loading(false)
                        } else {
                              error({ msg: response.m })
                              set_loading(false)
                        }
                  } else {
                        throw new Error(response.m)
                  }

            } catch (err) {
                  error({ msg: err.message })
                  set_loading(false)
            }
      }

      const handleLihatKartuPemeliharaan = async (obj) => {
            direct("/opd/kendaraan/pemeliharaan", { state: obj })
      }

      const cek_total = Object.keys(data_total).length === 0

      React.useEffect(() => {
            handleAmbilDataKendaraan()
            handleAmbilDataPengguna()
      }, [handleAmbilDataKendaraan, handleAmbilDataPengguna])


      return (
            <main>
                  <div className="container-fluid mt-3">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='fa fa-car'></i> DAFTAR KENDARAAN DINAS</strong></h3>
                              </div>
                        </div>
                        {
                              STATE_AUTH.is_verifikator === 'tidak'
                                    ?
                                    <>
                                          <hr />
                                          <button className='btn btn-primary btn-sm' onClick={() => { direct('/opd/kendaraan/tambah') }}><FaPlus /> Tambah</button>
                                          {
                                                data_kendaraan.length > 0
                                                      ?
                                                      <button className='btn btn-danger btn-sm' onClick={() => alert('Data kendaraan tersedia, tidak bisa menggunakan fitur ini')}> <FaFileImport /> Import via Excel</button>
                                                      :
                                                      <button className='btn btn-success btn-sm mx-2' onClick={() => { direct('/opd/kendaraan/import') }}><FaFileImport /> Import via Excel</button>
                                          }
                                          <button className='btn btn-sm btn-success' onClick={() => window.open(HOST + '/admin/kendaraan/export/' + STATE_AUTH.id_opd, '_blank')} target="_blank" rel="noreferrer"> <FaFileAlt /> Export</button>
                                          <hr />
                                    </>
                                    :
                                    <hr />
                        }

                        <div className="row">
                              <div className="col-md-4 mt-3">
                                    <div className="card">
                                          <div className='border border-info'>
                                                <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                      <div className='flex-fill'>
                                                            <p>Jumlah aset mobil</p>
                                                            <h2 className='text-info fw-bold'>{!cek_total ? data_total.mobil : <Spin />}</h2>
                                                      </div>
                                                      <div className='flex-fill text-end'>
                                                            <FaCarAlt size={60} className={"text-info"} />
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-md-4 mt-3">
                                    <div className="card">
                                          <div className='border border-success'>
                                                <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                      <div className=' flex-fill'>
                                                            <p>Jumlah aset motor</p>
                                                            <h2 className={"text-success fw-bold"}>{!cek_total ? data_total.motor : <Spin />}</h2>
                                                      </div>
                                                      <div className=' flex-fill text-end'>
                                                            <FaMotorcycle size={60} className={"text-success"} />
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-md-4 mt-3">
                                    <div className="card">
                                          <div className='border border-dark'>
                                                <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                      <div className=' flex-fill'>
                                                            <p>Jumlah aset alat berat</p>
                                                            <h2 className={"text-dark fw-bold"}>{!cek_total ? data_total.alat_berat : <Spin />}</h2>
                                                      </div>
                                                      <div className=' flex-fill text-end'>
                                                            <img src={faAlatBerat} width="70px" style={{ margin: 0, padding: 0 }} alt="icon-alat-berat" />
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className="row mt-2">
                              <div className="col-md-12">
                                    <div className="table-responsive">
                                          <table className='tb-daftar-dinas mt-1' >
                                                <thead style={{ fontSize: '13px' }}>
                                                      <tr>
                                                            <th>KODE BARANG</th>
                                                            <th>NAMA KENDARAAN</th>
                                                            <th>NOMOR REGISTER</th>
                                                            <th>MERK/TYPE</th>
                                                            <th>UKURAN/CC</th>
                                                            <th>NOMOR POLISI</th>
                                                            <th>PENGGUNA KENDARAAN</th>
                                                            <th colSpan={2}>...</th>
                                                      </tr>
                                                </thead>
                                                <tbody style={{ fontSize: '12px' }}>
                                                      {
                                                            !cek_total
                                                                  ?
                                                                  data_kendaraan.length !== 0
                                                                        ?
                                                                        data_kendaraan.map((v, k) => {
                                                                              switch (v.status_lelang) {
                                                                                    case 'penilaian':
                                                                                          return <tr key={k} className='bg-warning' title='Kendaraan ini dalam tahap penilaian'>
                                                                                                <td>{v.kode_barang}</td>
                                                                                                <td>{v.nama_barang}</td>
                                                                                                <td>{v.nomor_register}</td>
                                                                                                <td>{v.merk_type}</td>
                                                                                                <td>{v.ukuran_cc}</td>
                                                                                                <td>{v.no_polisi}</td>
                                                                                                <td>-</td>
                                                                                                <td className='d-flex justify-content-start align-items-center'>
                                                                                                      <div className="btn-group" role="group">
                                                                                                            <button onClick={() => direct('/opd/kendaraan/detail', { state: { id_kendaraan: v.id } })} className='btn btn-sm btn-outline-dark' title='Detail Kendaraan'><FaListAlt size={20} color='yellow' /> </button>
                                                                                                            <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Pajak Kendaraan'><FaMoneyCheckAlt size={20} color='blue' /></button>
                                                                                                            <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt color='#FF00FF' size={20} /></button>
                                                                                                      </div>
                                                                                                </td>
                                                                                          </tr>
                                                                                    case 'lelang':
                                                                                          return <tr key={k} className='bg-secondary' title='Kendaraan ini masuk daftar lelang'>
                                                                                                <td className='text-white'>{v.kode_barang}</td>
                                                                                                <td className='text-white'>{v.nama_barang}</td>
                                                                                                <td className='text-white'>{v.nomor_register}</td>
                                                                                                <td className='text-white'>{v.merk_type}</td>
                                                                                                <td className='text-white'>{v.ukuran_cc}</td>
                                                                                                <td className='text-white'>{v.no_polisi}</td>
                                                                                                <td className='text-white'>-</td>
                                                                                                <td className='d-flex justify-content-start align-items-center'>
                                                                                                      <div className="btn-group" role="group">
                                                                                                            <button onClick={() => direct('/opd/kendaraan/detail', { state: { id_kendaraan: v.id } })} className='btn btn-sm btn-outline-dark' title='Detail Kendaraan'><FaListAlt size={20} color='yellow' /> </button>
                                                                                                            <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Pajak Kendaraan'><FaMoneyCheckAlt size={20} color='blue' /></button>
                                                                                                            <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt color='#FF00FF' size={20} /></button>
                                                                                                      </div>
                                                                                                </td>
                                                                                          </tr>
                                                                                    case 'selesai':
                                                                                          return (
                                                                                                v.deleted_at === null
                                                                                                      ?
                                                                                                      <tr key={k} className='bg-danger text-white fw-bold' title='Kendaraan ini selesai dilelang'>
                                                                                                            <td>{v.kode_barang}</td>
                                                                                                            <td>{v.nama_barang}</td>
                                                                                                            <td>{v.nomor_register}</td>
                                                                                                            <td>{v.merk_type}</td>
                                                                                                            <td>{v.ukuran_cc}</td>
                                                                                                            <td>{v.no_polisi}</td>
                                                                                                            <td>{v.id_pengguna === null || v.id_pengguna === '0' ? <span className='badge badge-pill bg-warning text-dark'>Tidak Ada Pengguna</span> : v.nama}</td>
                                                                                                            <td className='d-flex justify-content-start align-items-center'>
                                                                                                                  <div className="btn-group" role="group">
                                                                                                                        <button onClick={() => direct('/opd/kendaraan/detail', { state: { id_kendaraan: v.id } })} className='btn btn-sm btn-outline-dark' title='Detail Kendaraan'><FaListAlt size={20} className='text-warning' /></button>
                                                                                                                        <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Pajak kendaraan'> <FaMoneyCheckAlt size={20} /></button>
                                                                                                                        <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt size={20} color='#FF00FF' /></button>
                                                                                                                  </div>
                                                                                                            </td>
                                                                                                      </tr>
                                                                                                      :
                                                                                                      null
                                                                                          )
                                                                                    default:
                                                                                          return <tr key={k}>
                                                                                                <td>{v.kode_barang}</td>
                                                                                                <td>{v.nama_barang}</td>
                                                                                                <td>{v.nomor_register}</td>
                                                                                                <td>{v.merk_type}</td>
                                                                                                <td>{v.ukuran_cc}</td>
                                                                                                <td>{v.no_polisi}</td>
                                                                                                <td>
                                                                                                      {
                                                                                                            v.id_pengguna === "0"
                                                                                                                  ?
                                                                                                                  load_pengguna
                                                                                                                        ?
                                                                                                                        data_pengguna.length !== 0
                                                                                                                              ?
                                                                                                                              <button className='btn btn-sm btn-outline-dark' onClick={handleShow.bind(this, { id_kendaraan: v.id })}>Pilih Pengguna</button>
                                                                                                                              :
                                                                                                                              <span className='badge bg-warning text-dark'>Tidak ada pengguna {v.nama}</span>
                                                                                                                        :
                                                                                                                        <span className='badge bg-warning text-dark'>Mohon Tunggu...</span>
                                                                                                                  :
                                                                                                                  <span><FaUserAlt /> {v.nama}</span>
                                                                                                      }
                                                                                                </td>
                                                                                                <td className='d-flex justify-content-start align-items-center'>
                                                                                                      {
                                                                                                            v.id_pengguna === "0"
                                                                                                                  ?
                                                                                                                  <div className="btn-group" role="group">
                                                                                                                        <button onClick={() => direct('/opd/kendaraan/detail', { state: { id_kendaraan: v.id } })} className='btn btn-sm btn-outline-dark' title='Detail Kendaraan'><FaListAlt size={20} color='aqua' /> </button>
                                                                                                                        <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Pajak Kendaraan'><FaMoneyCheckAlt size={20} color='blue' /></button>
                                                                                                                        <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt size={20} color='#FF00FF' /></button>
                                                                                                                  </div>
                                                                                                                  :
                                                                                                                  <div className="btn-group" role="group">
                                                                                                                        <button onClick={() => direct('/opd/kendaraan/detail', { state: { id_kendaraan: v.id } })} className='btn btn-sm btn-outline-dark ' title='Detail Kendaraan'><FaListAlt size={20} color='aqua' /> </button>
                                                                                                                        <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark ' title='Pajak Kendaraan'><FaMoneyCheckAlt size={20} /></button>
                                                                                                                        <button onClick={() => window.open(v.file_bast_pengguna_kendaraan, '_blank')} className='btn btn-sm btn-outline-dark ' title='Lihat file bast'><FaFileAlt size={20} color='#09CA' /></button>
                                                                                                                        <button onClick={handleShow.bind(this, { id_kendaraan: v.id })} className='btn btn-sm btn-outline-dark ' title='Ganti Pengguna Kendaraan'><FaUserTimes size={20} color='red' /></button>
                                                                                                                        <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt size={20} color='#FF00FF' /></button>
                                                                                                                  </div>
                                                                                                      }
                                                                                                </td>
                                                                                          </tr>
                                                                              }
                                                                        })
                                                                        :
                                                                        <tr><td colSpan={8} className='text-center'>(Kendaraan Tidak Tersedia)</td></tr>
                                                                  :
                                                                  <tr><td colSpan={8} className='text-center fw-bold'>{<Spin />}</td></tr>}
                                                </tbody>
                                          </table>
                                    </div>
                              </div>
                        </div>
                        <div className='row'>
                              <Modal show={show} onHide={handleClose} backdrop="static">
                                    <Modal.Header closeButton>
                                          <Modal.Title>Pilih Pengguna Kendaraan</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                          <form onSubmit={handleSubmitPenggunaKkendaraan}>
                                                <div className='form-group'>
                                                      <label htmlFor="pengguna">Pengguna Kendaraan</label>
                                                      <select name='pengguna' className='form-select form-select-sm' onChange={e => set_id_pengguna(e.target.value)} required>
                                                            <option value={null} >--Pilih--</option>
                                                            {
                                                                  data_pengguna.map((val, key) => {
                                                                        return (
                                                                              <option value={val.id} key={key}>{val.nama} | {val.jabatan}</option>
                                                                        )
                                                                  })
                                                            }
                                                      </select>
                                                </div>
                                                <div className='form-group mt-3'>
                                                      <label htmlFor="file">File BAST</label>
                                                      <input type='file' className='form-control form-control-sm' onChange={e => set_file_bast(e.target.files[0])} required />
                                                      <small className='text-muted'>Silahkan upload file berita acara serah terima barang</small>
                                                </div>
                                                <hr />
                                                {
                                                      loading
                                                            ?
                                                            <button className="btn btn-primary btn-sm" type="button" disabled>
                                                                  <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                                  <span role="status"> Mohon tunggu...</span>
                                                            </button>
                                                            :
                                                            <button className='btn btn-sm btn-primary' type='submit'>Simpan</button>
                                                }

                                          </form>
                                    </Modal.Body>
                              </Modal>
                        </div>
                  </div>
            </main >
      )
}

export default Opd_kendaraan