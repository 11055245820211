import React from 'react'
import ADMIN_KENDARAAN_LELANG_PENILAIAN from './admin_kendaraan_lelang_penilaian';
import ADMIN_KENDARAAN_LELANG_RISALAH from './admin_kendaraan_lelang_risalah';
import ADMIN_KENDARAAN_LELANG_PENGHAPUSAN from './admin_kendaraan_lelang_penghapusan';
import ADMIN_KENDARAAN_LELANG_RIWAYAT from './admin_kendaraan_lelang_riwayat';
import { FaCarAlt } from 'react-icons/fa';

const Admin_laporan_kendaraan = () => {
  const [menu_laporan, set_menu_laporan] = React.useState(1)
  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><FaCarAlt /> KENDARAAN LELANG</strong></h3>
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          <div className="col-md-12">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button onClick={() => set_menu_laporan(1)} className="nav-link active" id="kendaraan_penilaian-tab" data-bs-toggle="tab" data-bs-target="#kendaraan_penilaian" type="button" role="tab" aria-controls="kendaraan_penilaian" aria-selected="false">Penilaian</button>
                <button onClick={() => set_menu_laporan(2)} className="nav-link" id="kendaraan_lelang-tab" data-bs-toggle="tab" data-bs-target="#kendaraan_lelang" type="button" role="tab" aria-controls="kendaraan_lelang" aria-selected="false">Lelang</button>
                <button onClick={() => set_menu_laporan(3)} className="nav-link" id="penghapusan_lelang-tab" data-bs-toggle="tab" data-bs-target="#penghapusan_lelang" type="button" role="tab" aria-controls="penghapusan_lelang" aria-selected="false">Penghapusan Kendaraan Lelang</button>
                <button onClick={() => set_menu_laporan(4)} className="nav-link" id="riwayat_lelang-tab" data-bs-toggle="tab" data-bs-target="#riwayat_lelang" type="button" role="tab" aria-controls="riwayat_lelang" aria-selected="false">Riwayat Penghapusan Kendaraan</button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active border-start border-bottom border-end pb-3 pt-1 " id="kendaraan_penilaian" role="tabpanel" aria-labelledby="kendaraan_penilaian-tab" tabIndex="0">
                {menu_laporan === 1 && <ADMIN_KENDARAAN_LELANG_PENILAIAN />}
              </div>
              <div className="tab-pane fade border-start border-bottom border-end pb-3 pt-1" id="kendaraan_lelang" role="tabpanel" aria-labelledby="kendaraan_lelang-tab" tabIndex="0">
                {menu_laporan === 2 && <ADMIN_KENDARAAN_LELANG_RISALAH />}
              </div>
              <div className="tab-pane fade border-start border-bottom border-end pb-3 pt-1" id="penghapusan_lelang" role="tabpanel" aria-labelledby="penghapusan_lelang-tab" tabIndex="0">
                {menu_laporan === 3 && <ADMIN_KENDARAAN_LELANG_PENGHAPUSAN />}
              </div>
              <div className="tab-pane fade border-start border-bottom border-end pb-3 pt-1" id="riwayat_lelang" role="tabpanel" aria-labelledby="riwayat_lelang-tab" tabIndex="0">
                {menu_laporan === 4 && <ADMIN_KENDARAAN_LELANG_RIWAYAT />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_laporan_kendaraan