import React from "react";

import { Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import NotFound from "../pages/not_found";
import Page from "./page";

import ADMIN_DASHBOARD from "../pages/admin_dashboard";
import ADMIN_KENDARAAN from "../pages/admin_kendaraan";
import ADMIN_KENDARAAN_LELANG from "../pages/admin_kendaraan_lelang";
import ADMIN_KENDARAAN_DETAIL_EDIT from "../pages/admin_kendaraan_edit";
import ADMIN_KENDARAAN_DETAIL from "../pages/admin_kendaraan_detail";
import ADMIN_KENDARAAN_DETAIL_PEROPD from "../pages/admin_kendaraan_detail_peropd";
import ADMIN_KENDARAAN_DETAIL_PKB from "../pages/admin_kendaraan_detail_pkb";
import ADMIN_KENDARAAN_DETAIL_PEMELIHARAAN from "../pages/admin_kendaraan_pemeliharaan";
import ADMIN_KENDARAAN_TAMBAH from "../pages/admin_kendaraan_tambah";
import ADMIN_KENDARAAN_PENGGUNA from "../pages/admin_kendaraan_pengguna";
import ADMIN_KENDARAAN_PENGGUNA_TAMBAH from "../pages/admin_kendaraan_pengguna_tambah";
import ADMIN_LAPORAN_KENDARAAN from "../pages/admin_laporan_kendaraan";
import ADMIN_KENDARAAN_LAPORAN_PKB from "../pages/admin_kendaraan_laporan_pkb";
import ADMIN_PENGGUNA from "../pages/admin_pengguna";
import ADMIN_PENGGUNA_TAMBAH from "../pages/admin_pengguna_tambah";
import ADMIN_PENGGUNA_MENU from "../pages/admin_pengguna_menu";
import ADMMIN_KENDARAAN_MUTASI from "../pages/admin_kendaraan_mutasi";
import ADMIN_KENDARAAN_STATISTIK from "../pages/admin_kendaraan_statistik";

import OPD_DASHBOARD from "../pages/opd_dashboard";
import OPD_KENDARAAN from "../pages/opd_kendaraan";
import OPD_KENDARAAN_DETAIL from "../pages/opd_kendaraan_detail";
import OPD_KENDARAAN_TAMBAH from "../pages/opd_kendaraan_tambah";
import OPD_KENDARAAN_IMPORT from "../pages/opd_kendaraan_import";
import OPD_PENGGUNA_KENDARAAN from "../pages/opd_pengguna_kendaraan";
import OPD_KENDARAAN_VERIFIKASI_PKB from "../pages/opd_kendaraan_verifikasi_pkb";
import OPD_PENGGUNA_KENDARAAN_TAMBAH from "../pages/opd_pengguna_kendaraan_tambah";
import OPD_KENDARAAN_PKB from "../pages/opd_kendaraan_pkb";
import OPD_KENDARAAN_PEMELIHARAN from "../pages/opd_kendaraan_pemeliharaan";
import OPD_KENDARAAAN_PKB_LAPORAN from "../pages/opd_kendaraan_laporan_pkb";
import KENDARAAN_CARI from "../pages/kendaraan_cari"
import KENDARAAN_CARI_DETAIL from "../pages/kendaraan_cari_detail";
import PENGATURAN from "../pages/pengaturan";
import OPD_KENDARAAN_EDIT from "../pages/opd_kendaraan_edit";
import OPD_PROFIL from "../pages/opd_profil";
import VIEWER from "../pages/viewer";
import { useStateAuthContext } from "../context";

const Index = () => {

  const { STATE_AUTH } = useStateAuthContext();

  const menu_admin = (
    <>
      <Route exact path="/admin" element={<Page mod={<ADMIN_DASHBOARD />} />}></Route>
      <Route path="/admin/kendaraan" element={<Page mod={<ADMIN_KENDARAAN />} />}></Route>
      <Route path="/admin/kendaraan_lelang" element={<Page mod={<ADMIN_KENDARAAN_LELANG />} />}></Route>
      <Route path="/admin/kendaraan_cari" element={<Page mod={<KENDARAAN_CARI />} />}></Route>
      <Route path="/admin/kendaraan_statistik" element={<Page mod={<ADMIN_KENDARAAN_STATISTIK />} />} ></Route>
      <Route path="/admin/kendaraan_cari/detail" element={<Page mod={<KENDARAAN_CARI_DETAIL />} />}></Route>
      <Route path="/admin/kendaraan/detail" element={<Page mod={<ADMIN_KENDARAAN_DETAIL />} />}></Route>
      <Route path="/admin/kendaraan/detail/edit" element={<Page mod={<ADMIN_KENDARAAN_DETAIL_EDIT />} />}></Route>
      <Route path="/admin/kendaraan/opd/detail" element={<Page mod={<ADMIN_KENDARAAN_DETAIL_PEROPD />} />}></Route>
      <Route path="/admin/kendaraan/detail/pkb" element={<Page mod={<ADMIN_KENDARAAN_DETAIL_PKB />} />}></Route>
      <Route path="/admin/kendaraan/detail/pemeliharaan" element={<Page mod={<ADMIN_KENDARAAN_DETAIL_PEMELIHARAAN />} />}></Route>
      <Route path="/admin/kendaraan/tambah" element={<Page mod={<ADMIN_KENDARAAN_TAMBAH />} />}></Route>
      <Route path="/admin/kendaraan/pengguna" element={<Page mod={<ADMIN_KENDARAAN_PENGGUNA />} />}></Route>
      <Route path="/admin/kendaraan_mutasi" element={<Page mod={<ADMMIN_KENDARAAN_MUTASI />} />}></Route>
      <Route path="/admin/kendaraan/pengguna/tambah" element={<Page mod={<ADMIN_KENDARAAN_PENGGUNA_TAMBAH />} />}></Route>
      <Route path="/admin/pengguna" element={<Page mod={<ADMIN_PENGGUNA />} />}></Route>
      <Route path="/admin/pengguna/tambah" element={<Page mod={<ADMIN_PENGGUNA_TAMBAH />} />}></Route>
      <Route path="/admin/pengguna/menu" element={<Page mod={<ADMIN_PENGGUNA_MENU />} />}></Route>
      <Route path="/admin/laporan/kendaraan" element={<Page mod={<ADMIN_LAPORAN_KENDARAAN />} />}></Route>
      <Route path="/admin/laporan/pkb" element={<Page mod={<ADMIN_KENDARAAN_LAPORAN_PKB />} />}></Route>
      <Route path="/opd/kendaraan/pkb/verifikasi" element={<Page mod={<OPD_KENDARAAN_VERIFIKASI_PKB />} />}></Route>
      <Route path="/admin/pengaturan" element={<Page mod={<PENGATURAN />} />} ></Route>
    </>
  )

  const menu_opd = (
    <>
      {
        STATE_AUTH.is_guest
          ?
          <>
            <Route exact path="/opd" element={<Page mod={<VIEWER />} />}></Route>
            <Route exact path="/viewer" element={<Page mod={<VIEWER />} />}></Route>
            <Route path="/admin/kendaraan_cari" element={<Page mod={<KENDARAAN_CARI />} />}></Route>
            <Route path="/admin/kendaraan_cari/detail" element={<Page mod={<KENDARAAN_CARI_DETAIL />} />}></Route>
          </>
          :
          <>
            <Route exact path="/opd" element={<Page mod={<OPD_DASHBOARD />} />}></Route>
            <Route path="/opd/kendaraan" element={<Page mod={<OPD_KENDARAAN />} />}></Route>
            <Route path="/opd/kendaraan/detail" element={<Page mod={<OPD_KENDARAAN_DETAIL />} />}></Route>
            <Route path="/opd/kendaraan/edit" element={<Page mod={<OPD_KENDARAAN_EDIT />} />}></Route>
            <Route path="/opd/kendaraan/tambah" element={<Page mod={<OPD_KENDARAAN_TAMBAH />} />}></Route>
            <Route path="/opd/kendaraan/import" element={<Page mod={<OPD_KENDARAAN_IMPORT />} />}></Route>
            <Route path="/opd/pengguna/kendaraan" element={<Page mod={<OPD_PENGGUNA_KENDARAAN />} />}></Route>
            <Route path="/opd/pengguna/kendaraan/tambah" element={<Page mod={<OPD_PENGGUNA_KENDARAAN_TAMBAH />} />}></Route>
            <Route path="/opd/kendaraan/pkb" element={<Page mod={<OPD_KENDARAAN_PKB />} />}></Route>
            <Route path="/opd/kendaraan/pemeliharaan" element={<Page mod={<OPD_KENDARAAN_PEMELIHARAN />} />}></Route>
            <Route path="/opd/kendaraan/pkb/verifikasi" element={<Page mod={<OPD_KENDARAAN_VERIFIKASI_PKB />} />}></Route>
            <Route path="/opd/kendaraan/pkb/laporan" element={<Page mod={<OPD_KENDARAAAN_PKB_LAPORAN />} />}></Route>
            <Route path="/opd/profil" element={<Page mod={<OPD_PROFIL />} />}></Route>
          </>
      }

    </>
  )

  return (
    <>
      <Routes>
        {/* <Route exact path="/" element={<Login />}></Route>
        {STATE_AUTH.is_login ? (STATE_AUTH.is_admin || STATE_AUTH.is_super_admin ? menu_admin : menu_opd) : ''}
        <Route exact path="/*" element={<Login />}></Route> */}
        {
          STATE_AUTH.is_login
            ?
            STATE_AUTH.is_super_admin || STATE_AUTH.is_admin
              ?
              <Route exact path="/" element={<Page mod={<ADMIN_DASHBOARD />} />}></Route>
              :
              <Route exact path="/" element={<Page mod={<OPD_DASHBOARD />} />}></Route>
            :
            <Route exact path="/" element={<Login />}></Route>
        }

        {
          STATE_AUTH.is_login
            ?
            STATE_AUTH.is_super_admin || STATE_AUTH.is_admin
              ?
              menu_admin
              :
              menu_opd
            :
            <Route exact path="/" element={<Login />}></Route>
        }
        <Route exact path="/*" element={<NotFound />}></Route>
      </Routes>
    </>
  );
};

export default Index;
