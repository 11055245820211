import React, { createContext } from 'react';
import { useContext } from 'react';

/**
 * 
 * Context Auth Provider
 * 
 */

const ls = localStorage;
const ul = "_SIKENDIS_USER_LOGIN_";
const ud = "_SIKENDIS_USER_DATA_";

const AuthContext = createContext();
const InitData = {
  id_pengguna: null,
  id_opd: null,
  is_login: false,
  is_super_admin: false,
  is_admin: false,
  is_aktif: false,
  is_verifikator: 'tidak',
  is_guest: "tidak",
  nama_opd: '',
  nama_opd_singkat : '',
  username: '',
  nama_lengkap: '',
  token: null
}
const AuthReducers = (state, receive) => {
  const rec = receive;
  console.log('Receive From Reducers:', rec)
  switch (rec.type) {
    case 'LOGIN':
      const set_data = {
        ...state,
        id_pengguna: rec.payload.id_pengguna,
        id_opd: rec.payload.id_opd,
        is_login: true,
        is_super_admin: rec.payload.is_super_admin,
        is_admin: rec.payload.is_admin,
        is_opd: rec.payload.is_opd,
        is_aktif: rec.payload.is_aktif,
        is_verifikator: rec.payload.is_verifikator,
        is_guest: rec.payload.is_guest,
        nama_opd: rec.payload.nama_opd,
        nama_opd_singkat:rec.payload.nama_opd_singkat,
        username: rec.payload.username,
        nama_lengkap: rec.payload.nama_lengkap,
        token: rec.payload.token
      }
      ls.setItem(ul, set_data.is_login)
      ls.setItem(ud, JSON.stringify(set_data))

      return set_data

    case 'GANTI_TOKEN':
      const update_data = { ...state, token: rec.token }
      ls.setItem(ud, JSON.stringify(update_data))
      return update_data

    case 'LOGOUT':
      ls.removeItem(ul)
      ls.removeItem(ud)
      return InitData
    default:
      return state
  }
}

export const AuthProvider = ({ children }) => {
  const [STATE_AUTH, DISPATCH_AUTH] = React.useReducer(AuthReducers, InitData)
  return (
    <AuthContext.Provider value={{ STATE_AUTH, DISPATCH_AUTH }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useStateAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(
      "useStateAuthContext must be used within a StateAuthProvider"
    );
  }
  return context;
}

/**
 * 
 * Context Host Provider
 * 
 */

const HostContext = createContext();
// const HOST = "http://localhost:8080";
const HOST = "/webservices";

export const HostProvider = ({ children }) => {
  return (
    <HostContext.Provider value={{ HOST }}>
      {children}
    </HostContext.Provider>
  )
}

export const useStateHostContext = () => {
  const context = useContext(HostContext)
  if (!context) {
    throw new Error(
      "useStateHostContext must be used within a StateHostProvider"
    );
  }
  return context;
}