import React from 'react';
import logo from '../assets/img/logo.png';
import { useStateAuthContext, useStateHostContext } from '../context';
import { useNavigate } from 'react-router-dom';
import { error } from '../notify';
import { getBreakpoint } from '../utils';

const Login = () => {
  window.document.title = "Login System"
  const direct = useNavigate()

  const { DISPATCH_AUTH } = useStateAuthContext();
  const { HOST } = useStateHostContext();

  const [user, setUser] = React.useState('')
  const [pass, setPass] = React.useState('')
  const [isError, setError] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)

  React.useEffect(() => { return () => { } }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    const param = new FormData()
    param.append("username", user)
    param.append("password", pass)

    const requ = await fetch(`${HOST}/auth/login`, { method: 'POST', body: param })
    try {
      const resu = await requ.json()
      if (resu.s) {
        if (resu.d.is_aktif) {
          const SET_DISPATCH = {
            type: 'LOGIN',
            payload: resu.d
          }
          DISPATCH_AUTH(SET_DISPATCH)
          if (resu.d.is_super_admin) {
            direct("/admin")
            console.log('superadmin')
          } else if (resu.d.is_admin) {
            direct("/admin")
            console.log('admin')
          } else {
            direct("/opd")
          }
        } else {
          error({ msg: 'Status akun tidak aktif' })
        }
      } else {
        error({ msg: resu.m })
      }
      setLoading(false)
    } catch (err) {
      error({ msg: err.message })
      setError(true)
      setLoading(false)
    }
  }

  return (
    <main style={style.wrapper}>
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-lg-4">
            <div className="card shadow-lg border-0 rounded-lg mt-5">
              <div className="card-header">
                <div className="text-center mt-2"><img className="img-fluid" src={logo} width="20%" alt="logo"></img></div>
                <div className='text-center mt-4'>
                  <p className="text-center font-weight-light lh-1">
                    <span className='h3'>SiKENDIS</span> <br />
                    <span style={{ fontSize: '14px' }}>( Sistem Informasi Kendaraan Dinas )</span> <br />
                    <span style={{ fontSize: '12px' }}>versi 2.1</span>
                  </p>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-floating mb-3">
                    <input className="form-control" name="username" type="text" autoComplete="off" id="inputUsername" value={user} onChange={e => setUser(e.target.value)} required />
                    <label htmlFor="inputUsername">Nama Pengguna</label>
                  </div>
                  <div className="form-floating">
                    <input className="form-control mb-3" name="password" type="password" autoComplete="off" id="inputPassword" value={pass} onChange={e => setPass(e.target.value)} required />
                    <label htmlFor="inputPassword">Kata Sandi</label>
                  </div>
                  <div className="d-grid gap-2">
                    {
                      isLoading
                        ?
                        (<button className="btn btn-danger" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Mohon tunggu...
                        </button>)
                        :
                        <button className="btn btn-danger" type="submit">Masuk</button>
                    }
                    {
                      isError && (<div className="alert alert-danger text-center" type="button">Tidak bisa login! Terjadi kesalahan sistem.</div>)
                    }
                  </div>
                </form>
              </div>
              <div className="card-footer text-center py-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                      <p style={{ fontSize: '10px' }}><strong>SiKENDIS</strong> copyright&copy; 2023 - Badan Pengelola Keuangan Daerah (BPKD) Kota Kotamobagu</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const style = {
  wrapper: {
    height: getBreakpoint().height,
    backgroundImage: `url(${require('../assets/img/bg.jpg')})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
}

export default Login;