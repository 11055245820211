/* eslint-enabled */
import React from 'react'
import {  useStateAuthContext, useStateHostContext } from '../context'
import { error } from '../notify';
import { spin as Spin } from '../utils'

const Admin_kendaraan_laporan_pkb = () => {
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [report_agregat, set_report_agregat] = React.useState([])
      const [report_data, set_report_data] = React.useState([])
      const [loading, set_loading] = React.useState(false)
      const [loading_rincian, set_loading_rincian] = React.useState(false)
      const [tahun, set_tahun] = React.useState(null);
      const [id_opd, set_id_opd] = React.useState(null);
      const [opd, set_opd] = React.useState([])
      const [bg, set_bg] = React.useState('');
      const [title, set_title] = React.useState('');
      const [is_open_rincian, set_open_rincian] = React.useState(false)

      const getAllOPD = React.useCallback(async () => {
            const param = {
                  method: 'GET',
                  headers: {
                        'REST-TOKEN': STATE_AUTH.token,
                  }
            }
            const requ = await fetch(HOST + '/opd', param)
            const resl = await requ.json()
            set_opd(resl.d);
      }, [HOST, STATE_AUTH]);

      const getAllReportPKB = React.useCallback(async () => {
            set_loading(true)
            const param = {
                  method: 'GET',
                  headers: {
                        'REST-TOKEN': STATE_AUTH.token,
                  }
            }
            const requ = await fetch(HOST + '/admin/kendaraan/pkb/laporan', param);
            const resp = await requ.json()
            const resl = resp;
            if (resl.s) {

                  const data_agregat = [
                        { status: "1", rp_pkb: 0 },
                        { status: "2", rp_pkb: 0 },
                        { status: "3", rp_pkb: 0 },
                        { status: "4", rp_pkb: 0 }
                  ]

                  resl.d.agregat.forEach((v) => {
                        if (v.status === "1") {
                              data_agregat[0] = { status: "1", rp_pkb: v.rp_pkb, id_opd: v.id_opd }
                        }
                        if (v.status === "2") {
                              data_agregat[1] = { status: "2", rp_pkb: v.rp_pkb, id_opd: v.id_opd }
                        }
                        if (v.status === "3") {
                              data_agregat[2] = { status: "3", rp_pkb: v.rp_pkb, id_opd: v.id_opd }
                        }
                        if (v.status === "4") {
                              data_agregat[3] = { status: "4", rp_pkb: v.rp_pkb, id_opd: v.id_opd }
                        }
                  })
                  set_report_agregat(data_agregat);
                  set_loading(false)
            } else {
                  error({ msg: resl.m })
                  set_loading(false)
            }
      }, [HOST, STATE_AUTH])

      React.useEffect(() => {
            getAllOPD()
            getAllReportPKB()
      }, [getAllOPD, getAllReportPKB])

      const getReportPkbByOPD = async () => {
            set_open_rincian(false);
            set_loading(true)
            const param = {
                  method: 'GET',
                  headers: {
                        'REST-TOKEN': STATE_AUTH.token,
                  }
            }
            const requ = await fetch(HOST + `/admin/kendaraan/pkb/laporan_per_opd?id_opd=${id_opd}&tahun=${tahun}`, param)
            const resp = await requ.json()
            const resl = resp;
            if (resl.s) {

                  const data_agregat = [
                        { status: "1", rp_pkb: 0 },
                        { status: "2", rp_pkb: 0 },
                        { status: "3", rp_pkb: 0 },
                        { status: "4", rp_pkb: 0 }
                  ]

                  resl.d.agregat.forEach((v) => {
                        if (v.status === "1") {
                              data_agregat[0] = { status: "1", rp_pkb: v.rp_pkb, id_opd: v.id_opd }
                        }
                        if (v.status === "2") {
                              data_agregat[1] = { status: "2", rp_pkb: v.rp_pkb, id_opd: v.id_opd }
                        }
                        if (v.status === "3") {
                              data_agregat[2] = { status: "3", rp_pkb: v.rp_pkb, id_opd: v.id_opd }
                        }
                        if (v.status === "4") {
                              data_agregat[3] = { status: "4", rp_pkb: v.rp_pkb, id_opd: v.id_opd }
                        }
                  })
                  set_report_agregat(data_agregat);
                  set_loading(false)
            }
      }

      const formatRupiah = (rp) => {
            return new Intl.NumberFormat('id-ID',
                  { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(rp);
      }

      const getYear = () => {
            const year = [];
            const date = new Date();
            for (let i = 2020; i <= date.getFullYear(); i++) {
                  year.push(i)
            }
            return year;
      }
      const handleViewRincian = (status, id_opd, tahun) => {
            set_open_rincian(true);
            let bg;
            let title;
            if (status === "1") {
                  title = "BELUM DIBAYAR"
                  bg = "p-2 bg-warning text-dark"
            }
            if (status === "2") {
                  title = "SUDAH DIBAYAR"
                  bg = "p-2 bg-success text-white"
            }
            if (status === "3") {
                  title = "JATUH TEMPO"
                  bg = "p-2 bg-info"
            }
            if (status === "4") {
                  title = "MENUNGGAK"
                  bg = "p-2 bg-danger text-white"
            }

            set_bg(bg)
            set_title(title)



            set_loading_rincian(true)
            const tanggal = new Date();
            const tahun_sekarang = tanggal.getFullYear()
            const formData = new FormData()
            formData.append("TAHUN", tahun !== null ? tahun : tahun_sekarang)
            formData.append("STATUS", status)
            formData.append("ID_OPD", id_opd)
            const param = {
                  method: "POST",
                  headers: {
                        'REST-ID-OPD': STATE_AUTH.id_opd,
                        'REST-TOKEN': STATE_AUTH.token
                  },
                  body: formData
            }
            const getRincianPkb = async () => {
                  const requ = await fetch(HOST + "/admin/kendaraan/pkb/laporan_rincian", param)
                  const resl = await requ.json()
                  set_report_data(resl.d);
                  set_loading_rincian(false)
            }
            getRincianPkb()



      }

      return (
            <main>
                  <div className="container-fluid mt-3">

                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='fa fa-file-alt'></i> LAPORAN PKB KENDARAAN</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <div className="col-md-5">
                              <div className="input-group input-group-sm flex-nowrap ">
                                    <span className="input-group-text w-25" id="tahun">OPD</span>
                                    <select className='form-control' onChange={e => set_id_opd(e.target.value)} id="opd" defaultValue="">
                                          <option value="">--Pilih--</option>
                                          <option value="null">Semua</option>
                                          {
                                                opd.map((val, ind) => {
                                                      return <option value={val.id} key={val.id}>{val.opd}</option>
                                                })
                                          }
                                    </select>
                              </div>
                        </div>
                        <div className="col-md-5 my-2">
                              <div className="input-group input-group-sm flex-nowrap">
                                    <span className="input-group-text w-25" id="tahun">TAHUN</span>
                                    <select className='form-control' onChange={e => set_tahun(e.target.value)} id="tahun" defaultValue="">
                                          <option value="" disabled>--Pilih--</option>
                                          {
                                                getYear().map((val, ind) => {
                                                      return <option value={val} key={ind}>{val}</option>
                                                })
                                          }
                                    </select>
                              </div>
                        </div>
                        <div className="col-md-5 my-2">
                              <button className='btn btn-primary btn-sm' onClick={getReportPkbByOPD}><i className='fa fa-list-alt'></i> CEK LAPORAN PKB</button>
                        </div>
                        <hr />
                        <div className="row">
                              {
                                    !loading
                                          ?
                                          report_agregat.map((v, i) => {
                                                return (
                                                      <div className="col-md-3" key={i}>
                                                            {
                                                                  v.status === "1"
                                                                        ?
                                                                        <div className="card border border-warning mb-3">
                                                                              <div className="card-header bg-warning text-dark">
                                                                                    TOTAL PKB BELUM DIBAYAR
                                                                              </div>
                                                                              <div className="card-body">
                                                                                    <h2>{formatRupiah(v.rp_pkb)}</h2>
                                                                                    <hr />
                                                                                    {
                                                                                          v.rp_pkb !== 0 && (<button className='btn btn-primary btn-sm' onClick={handleViewRincian.bind(this, v.status, id_opd, tahun)}><i className='fa fa-eye'></i> Lihat Rincian</button>)
                                                                                    }
                                                                                    {
                                                                                          v.rp_pkb === 0 && (<button className='btn btn-primary btn-sm' disabled onClick={() => alert('Tidak ada data tersedia.')}><i className='fa fa-eye'></i> Lihat Rincian</button>)
                                                                                    }
                                                                              </div>
                                                                        </div>
                                                                        :
                                                                        v.status === "2"
                                                                              ?
                                                                              <div className="card border border-success mb-3">
                                                                                    <div className="card-header bg-success text-white">
                                                                                          TOTAL PKB DIBAYAR
                                                                                    </div>
                                                                                    <div className="card-body">
                                                                                          <h2>{formatRupiah(v.rp_pkb)}</h2>
                                                                                          <hr />
                                                                                          {
                                                                                                v.rp_pkb !== 0 && (<button className='btn btn-primary btn-sm' onClick={handleViewRincian.bind(this, v.status, id_opd, tahun)}><i className='fa fa-eye'></i> Lihat Rincian</button>)
                                                                                          }
                                                                                          {
                                                                                                v.rp_pkb === 0 && (<button className='btn btn-primary btn-sm' disabled onClick={() => alert('Tidak ada data tersedia.')}><i className='fa fa-eye'></i> Lihat Rincian</button>)
                                                                                          }

                                                                                    </div>
                                                                              </div>
                                                                              :
                                                                              v.status === "3"
                                                                                    ?
                                                                                    <div className="card border border-info mb-3">
                                                                                          <div className="card-header bg-info text-white">
                                                                                                TOTAL PKB JATUH TEMPO
                                                                                          </div>
                                                                                          <div className="card-body">
                                                                                                <h2>{formatRupiah(v.rp_pkb)}</h2>
                                                                                                <hr />
                                                                                                {
                                                                                                      v.rp_pkb !== 0 && (<button className='btn btn-primary btn-sm' onClick={handleViewRincian.bind(this, v.status, id_opd, tahun)}><i className='fa fa-eye'></i> Lihat Rincian</button>)
                                                                                                }
                                                                                                {
                                                                                                      v.rp_pkb === 0 && (<button className='btn btn-primary btn-sm' disabled onClick={() => alert('Tidak ada data tersedia.')}><i className='fa fa-eye'></i> Lihat Rincian</button>)
                                                                                                }
                                                                                          </div>
                                                                                    </div>
                                                                                    :

                                                                                    <div className="card border border-danger mb-3">
                                                                                          <div className="card-header bg-danger text-white">
                                                                                                TOTAL PKB MENUNGGAK
                                                                                          </div>
                                                                                          <div className="card-body">
                                                                                                <h2>{formatRupiah(v.rp_pkb)}</h2>
                                                                                                <hr />
                                                                                                {
                                                                                                      v.rp_pkb !== 0 && (<button className='btn btn-primary btn-sm' onClick={handleViewRincian.bind(this, v.status, id_opd, tahun)}><i className='fa fa-eye'></i> Lihat Rincian</button>)
                                                                                                }
                                                                                                {
                                                                                                      v.rp_pkb === 0 && (<button className='btn btn-primary btn-sm' disabled onClick={() => alert('Tidak ada data tersedia.')}><i className='fa fa-eye'></i> Lihat Rincian</button>)
                                                                                                }
                                                                                          </div>
                                                                                    </div>

                                                            }
                                                      </div>
                                                )
                                          })
                                          :
                                          <div className="col-md-12 text-center">
                                                <Spin />
                                          </div>
                              }

                        </div>
                        <div className="row">
                              {
                                    is_open_rincian
                                          ?
                                          <div className="col-md-12">
                                                <h6 className={bg}><i className='fa fa-file-alt'></i> LAPORAN RINCIAN PKB {title}</h6>
                                                <div className="table-responsive">
                                                      <table className="tb-daftar-dinas">
                                                            <thead style={{ fontSize: 12 }}>
                                                                  <tr>
                                                                        <th>#</th>
                                                                        <th>Kode Barang</th>
                                                                        <th>Nama Barang</th>
                                                                        <th>Type</th>
                                                                        <th>Nomor Polisi</th>
                                                                        <th>Pajak Kendaraan Bermotor (PKB)</th>
                                                                        <th>Organisasi Perangkat Daerah(OPD)</th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody style={{ fontSize: 12 }}>
                                                                  {
                                                                        !loading_rincian
                                                                              ?
                                                                              report_data.length !== 0
                                                                                    ?
                                                                                    report_data.map((val, key) => {
                                                                                          return <tr key={key} >
                                                                                                <td className='p-1 m-0'>{key + 1}</td>
                                                                                                <td className='p-1 m-0'>{val.kode_barang}</td>
                                                                                                <td className='p-1 m-0'>{val.nama_barang}</td>
                                                                                                <td className='p-1 m-0'>{val.merk_type}</td>
                                                                                                <td className='p-1 m-0'>{val.no_polisi}</td>
                                                                                                <td className='p-1 m-0'>{formatRupiah(val.rp_pkb)}</td>
                                                                                                <td className='p-1 m-0'>{val.opd}</td>
                                                                                          </tr>
                                                                                    })
                                                                                    :
                                                                                    <tr><td colSpan={7} className="text-center">(Tidak Ada Data.)</td></tr>
                                                                              :
                                                                              <tr><td colSpan={7} className="text-center"><Spin /></td></tr>
                                                                  }
                                                            </tbody>
                                                      </table>
                                                </div>
                                          </div>
                                          :
                                          ''
                              }

                        </div>
                  </div>
            </main>
      )
}

export default Admin_kendaraan_laporan_pkb