import React from 'react'
import { useStateAuthContext, useStateHostContext } from '../context';
import { FaFileExcel, FaListAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

const Admin_laporan_kendaraan_rusak_ringan = () => {
  window.document.title = "Kendaraan"
  const direct = useNavigate()
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [data_kendaraan, set_kendaraan] = React.useState([])
  const [data_total, set_total] = React.useState({})
  React.useEffect(() => {
    const param = {
      method: 'GET',
      headers: {
        'REST-TOKEN': STATE_AUTH.token
      }
    }
    const ambilDataKendaraan = async () => {
      const resp = await fetch(HOST + '/admin/kendaraan/laporan/rusak_ringan', param)
      const resu = await resp.json()
      let total = { motor: 0, mobil: 0, alat_berat: 0 }
      resu.d.forEach((v) => {
        if (v.jenis === "motor") { total.motor++ }
        if (v.jenis === "mobil") { total.mobil++ }
        if (v.jenis === "alat_berat") { total.alat_berat++ }
      })
      set_total(total)
      set_kendaraan(resu.d)
    }
    ambilDataKendaraan()
  }, [STATE_AUTH, HOST])

  const cek_total = Object.keys(data_total).length === 0

  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="table-responsive">
              {
                !cek_total
                  ?
                  <table className='tb-daftar-dinas mt-1' >
                    <thead style={{ fontSize: '13px' }}>
                      <tr>
                        <th>KODE BARANG</th>
                        <th>NAMA KENDARAAN</th>
                        <th>NOMOR REGISTER</th>
                        <th>MERK/TYPE</th>
                        <th>UKURAN/CC</th>
                        <th>TAHUN</th>
                        <th>WARNA</th>
                        <th>NOMOR POLISI</th>
                        <th colSpan={2}></th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: '12px' }}>
                      {

                        data_kendaraan.length !== 0
                          ?
                          data_kendaraan.map((v, k) => {
                            return <tr key={k} title='Kendaraan ini dalam tahap penilaian'>
                              <td>
                                {v.kode_barang} <br />
                                <small className='text-muted'>{v.opd}</small>
                              </td>
                              <td>{v.nama_barang}</td>
                              <td>{v.nomor_register}</td>
                              <td>{v.merk_type}</td>
                              <td>{v.ukuran_cc}</td>
                              <td>
                                <small>Pembuatan : {v.tahun_pembuatan}</small> <br />
                                <small>Pembelian : {v.tahun_pembelian}</small>
                              </td>
                              <td>{v.warna}</td>
                              <td>{v.no_polisi}</td>
                              <td>
                                <div className="btn-group" role="group">
                                  <button onClick={() => direct('/admin/kendaraan/opd/detail', { state: { id_kendaraan: v.id, id_opd: v.id_opd, opd: v.opd } })} className='btn btn-sm btn-outline-primary' title='Detail Kendaraan'><FaListAlt size={20} className='text-warning' /></button>
                                </div>
                              </td>
                            </tr>
                          })
                          :
                          <tr><td colSpan={10} className='text-center'>(Kendaraan tidak tersedia)</td></tr>
                      }
                    </tbody>
                  </table>
                  :
                  <div className='text-center d-flex justify-content-center align-items-center'>
                    <BeatLoader />
                  </div>
              }
              {data_kendaraan.length !== 0 && <button className='btn btn-success btn-sm my-2' onClick={() => window.open(HOST + '/admin/kendaraan/laporan/export/rusak_ringan/null', '_blank')}><FaFileExcel /> Export ke Excel</button>}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_laporan_kendaraan_rusak_ringan