import React from 'react'
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { FaCarAlt, FaCheck, FaFileAlt, FaTimesCircle, FaUserAlt } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';
import { useStateAuthContext, useStateHostContext } from '../context';
import { info } from '../notify';

const Opd_dashboard = () => {
    window.document.title = "Opd Dashboard"
    const { HOST } = useStateHostContext();
    const { STATE_AUTH } = useStateAuthContext();
    const direct = useNavigate()
    const [data, set_data] = React.useState([])


    React.useEffect(() => {
        const getData = async () => {
            const param = {
                method: 'GET',
                headers: {
                    'REST-TOKEN': STATE_AUTH.token,
                    'REST-ID-OPD': STATE_AUTH.id_opd
                }
            }
            const requ = await fetch(HOST + '/user', param)
            const resu = await requ.json();
            set_data(resu);
        }
        getData()
    }, [STATE_AUTH, HOST])

    if (!STATE_AUTH.is_login) {
        return <Navigate to="/" />
    }

    return (
        <main>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-12 mb-1">
                        <div className='alert alert-success p-1 d-flex m-0' style={{ borderRadius: 0 }}>
                            <BsFillPersonCheckFill size={42} />
                            <div className="mx-3">
                                <p className='p-0 m-0' style={{ fontSize: '14px' }}> Pengguna sistem : <strong>{STATE_AUTH.nama_lengkap}</strong> </p>
                                {STATE_AUTH.is_opd && <><p style={{ fontSize: '14px' }} className='p-0 m-0'>Status akses : <span className='p-0 m-0 fw-bold' style={{ fontSize: '14px' }}>OPD</span></p></>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="alert alert-info" style={{ borderRadius: 0 }}>
                            <div style={{ lineHeight: 0.8 }}>
                                <h3>Selamat datang di <strong>SiKENDIS (Sistem Informasi Kendaraan Dinas) versi 2.1</strong></h3>
                                <p>Sistem ini digunakan untuk Manajemen Aset Kendaraan Dinas, Pemantauan dan Pelaporan Pajak Kendaraan Bermotor</p>
                                <p>Untuk pengecekan pajak kendaraan silahkan klik tautan dibawah</p>
                            </div>
                            <a className='btn btn-sm btn-primary' target="_blank" rel="noreferrer" href="https://play.google.com/store/search?q=info+pajak+kendaraan+sulut&c=apps"><i className='fab fa-google-play'></i> Download Playstore</a>
                            <a className='btn btn-sm btn-success mx-2' target="_blank" rel="noreferrer" href="https://bapenda.sulutprov.go.id/index.php?page=cekpajak"><i className='fa fa-globe'></i> Online BAPENDA</a>
                            <a className='btn btn-sm btn-warning' target="_blank" rel="noreferrer" href="https://cekpajak.com/sulawasi-utara"><i className='fa fa-globe'></i> Online e-SAMSAT</a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    {
                        STATE_AUTH.is_verifikator === 'ya'
                            ?
                            <>
                                <div className="col-md-4 mt-3">
                                    <div className="card">
                                        <button className='btn btn-outline-secondary' onClick={() => direct('/opd/kendaraan/pkb/verifikasi')}>
                                            <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                <div className=' flex-fill'>
                                                    <p>VERIFIKASI DATA</p>
                                                    <h2>{data.length !== 0 && data.d.agregat.pkb_verifikasi}</h2>
                                                </div>
                                                <div className=' flex-fill text-end'>
                                                    <FaCheck size={60} />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-3">
                                    <div className="card">
                                        <button className='btn btn-outline-secondary' onClick={() => direct('/opd/kendaraan/pkb/laporan')}>
                                            <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                <div className=' flex-fill'>
                                                    <p>LAPORAN PEMBAYARAN PKB</p>
                                                    <h2>{data.length !== 0 && data.d.agregat.pkb_laporan}</h2>
                                                </div>
                                                <div className=' flex-fill text-end'>
                                                    <FaFileAlt size={60} />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-3">
                                    <div className="card">
                                        <button className='btn btn-outline-danger' onClick={() => info({ msg: 'Modul dalam tahap pengembangan' })}>
                                            <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                <div className=' flex-fill'>
                                                    <p>COOMING SOON</p>
                                                    <h2>0</h2>
                                                </div>
                                                <div className=' flex-fill text-end'>
                                                    <FaTimesCircle size={60} />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-md-4 mt-3">
                                    <div className="card">
                                        <button className='btn btn-outline-secondary' onClick={() => direct('/opd/kendaraan')}>
                                            <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                <div className=' flex-fill'>
                                                    <p>MODUL ASET KENDARAAN</p>
                                                    <h2>{data.length !== 0 && data.d.agregat.kendaraan}</h2>
                                                </div>
                                                <div className=' flex-fill text-end'>
                                                    <FaCarAlt size={60} />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-3">
                                    <div className="card">
                                        <button className='btn btn-outline-secondary' onClick={() => direct('/opd/pengguna/kendaraan')}>
                                            <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                <div className=' flex-fill'>
                                                    <p>MODUL PENGGUNA KENDARAAN</p>
                                                    <h2>{data.length !== 0 && data.d.agregat.pengguna}</h2>
                                                </div>
                                                <div className=' flex-fill text-end'>
                                                    <FaUserAlt size={60} />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-4 mt-3">
                                    <div className="card">
                                        <button className='btn btn-outline-danger' onClick={() => info({ msg: 'Modul dalam tahap pengembangan' })}>
                                            <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                <div className=' flex-fill'>
                                                    <p>COOMING SOON</p>
                                                    <h2>0</h2>
                                                </div>
                                                <div className=' flex-fill text-end'>
                                                    <FaTimesCircle size={60} />
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </div>
        </main>
    )
}

export default Opd_dashboard
