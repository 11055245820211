import React from 'react'
import { useStateAuthContext, useStateHostContext } from '../context';
import { FaFile, FaInfoCircle } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { error, success } from '../notify';

const Admin_laporan_kendaraan_lelang = () => {
  window.document.title = "Kendaraan"
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [data_kendaraan, set_kendaraan] = React.useState([])
  const [data_total, set_total] = React.useState({})
  const [pilih_data, set_pilih_data] = React.useState([])
  const [pilih_semua_data, set_pilih_semua_data] = React.useState(false)
  const [loading_lelang, set_loading_lelang] = React.useState(false)

  const [nomor_risalah, set_nomor_risalah] = React.useState('')
  const [tanggal_risalah, set_tanggal_risalah] = React.useState('')
  const form_risalah = React.useRef()

  const [data_nilai_lelang, set_data_nilai_lelang] = React.useState([])

  const handleGetDataKendaraan = React.useCallback(async () => {
    const resp = await fetch(HOST + '/admin/kendaraan/laporan/lelang', {
      method: 'GET',
      headers: {
        'REST-TOKEN': STATE_AUTH.token
      }
    })
    const resu = await resp.json()
    let total = { motor: 0, mobil: 0, alat_berat: 0 }
    resu.d.forEach((v) => {
      if (v.jenis === "motor") { total.motor++ }
      if (v.jenis === "mobil") { total.mobil++ }
      if (v.jenis === "alat_berat") { total.alat_berat++ }
    })
    set_total(total)
    set_kendaraan(resu.d)
  }, [HOST, STATE_AUTH])


  const handlePilihId = (id) => {
    if (pilih_data.includes(id)) {
      set_pilih_data(pilih_data.filter((rowId) => rowId !== id));
      set_data_nilai_lelang(prevData => prevData.filter((data) => data.id !== id))
    } else {
      set_pilih_data([...pilih_data, id]);
    }
  };

  const handlePilihSemuaId = () => {
    if (pilih_semua_data) {
      set_pilih_data([]);
      set_data_nilai_lelang([]);
    } else {
      const semua_id = data_kendaraan.map((item) => item.id_kendaraan);
      set_pilih_data(semua_id);
    }
    set_pilih_semua_data(!pilih_semua_data);
  };

  const handleAmbilNilai = (obj) => {
    const existingData = data_nilai_lelang.find((data) => data.id_kendaraan === obj.id_kendaraan);
    if (existingData) {
      set_data_nilai_lelang((prevData) => prevData.map((data) => (data.id_kendaraan === obj.id_kendaraan ? obj : data)));
    } else {
      set_data_nilai_lelang((prevData) => [...prevData, obj]);
    }
  }

  const handleSubmitRisalah = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('DATA_RISALAH', JSON.stringify(data_nilai_lelang))
    set_loading_lelang(true)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/risalah/simpan`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          set_pilih_semua_data([])
          set_pilih_data([])
          handleGetDataKendaraan()
          form_risalah.current.reset()
          set_loading_lelang(false)
        } else {
          error({ msg: response.m })
          set_loading_lelang(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading_lelang(false)
    }
  }

  const cek_total = Object.keys(data_total).length === 0

  React.useEffect(() => {
    handleGetDataKendaraan()
  }, [handleGetDataKendaraan])

  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-2">
          <div className="col-md-12">
            <div className='alert alert-success'><FaInfoCircle /> Daftar kendaraan yang dilelang</div>
            <form onSubmit={handleSubmitRisalah} ref={form_risalah}>
              {
                data_kendaraan.length === 0
                  ?
                  null
                  :
                  <>
                    <h2 className='text-center'>FORM RISALAH LELANG</h2>
                    <div className='form-group mb-1'>
                      <input type='text' className='form-control form-control-sm' placeholder='Masukan Nomor Risalah' onChange={e => set_nomor_risalah(e.target.value)} required />
                    </div>
                    <div className='form-group mb-1'>
                      <input type='date' className='form-control form-control-sm' placeholder='Masukan Nomor Risalah' onChange={e => set_tanggal_risalah(e.target.value)} required />
                    </div>
                    {/* <div className='form-group mb-1'>
                      <input type='file' className='form-control form-control-sm' onChange={e => set_file_sk_penghapusan(e.target.files[0])} required />
                      <small className='text-muted'>Upload SK Penghapusan</small>
                    </div> */}
                    <hr />
                  </>
              }

              <div className="table-responsive">

                {
                  !cek_total
                    ?
                    <>
                      <table className='tb-daftar-dinas mt-1' >
                        <thead style={{ fontSize: '13px' }}>
                          <tr>
                            <th>
                              <input
                                onClick={() => handlePilihSemuaId()}
                                type="checkbox"
                                checked={pilih_data.length !== 0}
                                readOnly
                              />
                            </th>
                            <th>KODE BARANG</th>
                            <th>NAMA KENDARAAN</th>
                            <th>NOMOR REGISTER</th>
                            <th>MERK/TYPE</th>
                            <th>UKURAN/CC</th>
                            <th>TAHUN</th>
                            <th>WARNA</th>
                            <th>NOMOR POLISI</th>
                            <th colSpan={2}>File SK</th>
                          </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px' }}>
                          {

                            data_kendaraan.length !== 0
                              ?
                              data_kendaraan.map((v, k) => {
                                return (
                                  <>
                                    <tr
                                      key={k}
                                      title='Kendaraan ini dalam tahap pelelangan'
                                      style={{
                                        backgroundColor: pilih_data.includes(v.id_kendaraan) ? 'lightblue' : null,
                                      }}>
                                      <td rowSpan={2}>
                                        <input
                                          onClick={() => handlePilihId(v.id_kendaraan)}
                                          type="checkbox"
                                          checked={pilih_data.includes(v.id_kendaraan)}
                                          readOnly
                                        />
                                      </td>
                                      <td rowSpan={2}>
                                        {v.kode_barang} <br />
                                        <small className='text-muted'>{v.opd}</small>
                                      </td>
                                      <td><small>{v.nama_barang}</small></td>
                                      <td><small>{v.nomor_register}</small></td>
                                      <td>
                                        <small>{v.merk_type}</small>
                                      </td>
                                      <td>{v.ukuran_cc}</td>
                                      <td>
                                        <small>Pembuatan : {v.tahun_pembuatan}</small> <br />
                                        <small>Pembelian : {v.tahun_pembelian}</small>
                                      </td>
                                      <td>{v.warna}</td>
                                      <td>{v.no_polisi}</td>
                                      <td>
                                        <div className="btn-group" role="group">
                                          <button onClick={() => window.open(`${v.file_sk_penilaian_lelang}`, '_blank')} className='btn btn-sm btn-outline-primary' title='File SK Penilaian'><FaFile size={20} className='text-warning' /></button>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr
                                      key={k + 1}
                                      style={{
                                        backgroundColor: pilih_data.includes(v.id_kendaraan) ? 'lightblue' : null,
                                      }}
                                    >
                                      {
                                        pilih_data.includes(v.id_kendaraan)
                                          ?
                                          <>
                                            <td colSpan={2}>
                                              <label htmlFor="no_risalah" style={{ fontSize: 11 }}>Nomor Risalah</label> <br />
                                              <input type='text' className='form-control form-control-sm' placeholder='Nomor Risalah' value={nomor_risalah} readOnly />
                                            </td>
                                            <td colSpan={2}>
                                              <label htmlFor="no_risalah" style={{ fontSize: 11 }}>Tanggal Risalah</label> <br />
                                              <input type='date' className='form-control form-control-sm' value={tanggal_risalah} readOnly />
                                            </td>
                                            <td colSpan={4}>
                                              <label htmlFor="no_risalah" style={{ fontSize: 11 }}>Nilai Lelang</label> <br />
                                              <input type='text' className='form-control form-control-sm' placeholder='Rp.' required onChange={e => handleAmbilNilai(
                                                {
                                                  id_kendaraan: v.id_kendaraan,
                                                  tanggal_risalah,
                                                  nomor_risalah,
                                                  nilai_lelang: e.target.value
                                                })} />
                                            </td>
                                          </>
                                          :
                                          null
                                      }
                                    </tr>
                                  </>
                                )
                              })
                              :
                              <tr><td colSpan={10} className='text-center'>(Kendaraan tidak tersedia)</td></tr>
                          }
                        </tbody>
                      </table>
                    </>
                    :
                    <div className='text-center d-flex justify-content-center align-items-center'>
                      <BeatLoader />
                    </div>
                }
                {
                  pilih_data.length !== 0
                    ?
                    <>
                      {
                        loading_lelang
                          ?
                          <button className="btn btn-outline-info btn-sm my-2" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                            <span role="status"> Mohon tunggu...</span>
                          </button>
                          :
                          <button className='btn btn-outline-info btn-sm my-2' type='submit'>{pilih_data.length} Dipilih, Simpan Data</button>
                      }
                    </>
                    :
                    ''
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_laporan_kendaraan_lelang