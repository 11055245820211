import React from 'react'
import { Navigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { error, success } from '../notify'

const Pengaturan = () => {
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const sandi_lama = React.useRef()
  const sandi_baru = React.useRef()
  const sandi_baru_konfirmasi = React.useRef()
  const handleUbahSandi = async (e) => {
    e.preventDefault()
    const fd = new FormData()
    fd.append("ID_PENGGUNA", STATE_AUTH.id_pengguna)
    fd.append("NAMA_PENGGUNA", STATE_AUTH.username)
    fd.append("SANDI_LAMA", sandi_lama.current.value)
    fd.append("SANDI_BARU", sandi_baru.current.value)
    fd.append("SANDI_BARU_KONFIRMASI", sandi_baru_konfirmasi.current.value)

    if (sandi_baru.current.value !== sandi_baru_konfirmasi.current.value) {
      return error({ msg: 'Sandi baru tidak sama' })
    }

    const requ = await fetch(`${HOST}/admin/pengaturan/ubah_sandi`, {
      method: 'POST', headers: {
        "REST-TOKEN": STATE_AUTH.token
      },
      body: fd
    })


    try {
      const resu = await requ.json()
      if (resu.s) {
        success({ msg: resu.m })
      } else {
        error({ msg: resu.m })
      }
    } catch (err) {
      error({ msg: err.message })
    }

    if (!STATE_AUTH.is_login) {
      return <Navigate to="/login" />
  }


  }
  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><i className='fas fa-car'></i> PENGATURAN</strong></h3>
          </div>
        </div>
        <hr />
        <span className='h6'>UBAH SANDI</span>
        <hr />
        <form onSubmit={handleUbahSandi}>
          <div className="form-group mb-1">
            <label htmlFor="sandi_lama">Sandi Lama</label>
            <input type="password" name="sandi_lama"  id="sandi_lama" autoComplete='off' ref={sandi_lama} className="form-control" required />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="sandi_baru">Sandi Baru</label>
            <input type="password" name="sandi_baru" minLength={5} id="sandi_baru" autoComplete='off' ref={sandi_baru} className="form-control" required />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="sandi_baru_konfirmasi">Konfirmasi Sandi Baru</label>
            <input type="password" name="sandi_baru_konfirmasi" minLength={5} id="sandi_baru_konfirmasi" autoComplete='off' ref={sandi_baru_konfirmasi} className="form-control" required />
          </div>
          <div className="form-group mt-2">
            <button type='submit' className='btn btn-primary'>Ubah</button>
          </div>
        </form>
      </div>
    </main>
  )
}

export default Pengaturan