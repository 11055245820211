import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { error } from '../notify'
import { spin as Spin } from '../utils'
import faAlatBerat from '../assets/img/fa-alat-berat.png'
import { FaCarAlt, FaIdCardAlt, FaListAlt, FaMoneyCheckAlt, FaMotorcycle } from 'react-icons/fa'

const Admin_kendaraan_detail = () => {
      const { state } = useLocation()
      const direct = useNavigate()
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [data_kendaraan, set_kendaraan] = React.useState([])
      const [data_total, set_total] = React.useState({})
      const ambilDataKendaraan = React.useCallback(async () => {
            const param = {
                  method: 'GET',
                  headers: {
                        'REST-TOKEN': STATE_AUTH.token,
                        'REST-ID-OPD': state.id_opd
                  }
            }
            const resp = await fetch(HOST + '/admin/kendaraan/detail', param)
            if (!resp.ok) return error({ msg: 'Gagal mengambil data.' })
            const resu = await resp.json()
            let total = { motor: 0, mobil: 0, alat_berat: 0 }
            resu.d.forEach((v) => {
                  if (v.jenis === "motor") { total.motor++ }
                  if (v.jenis === "mobil") { total.mobil++ }
                  if (v.jenis === "alat_berat") { total.alat_berat++ }
            })

            set_total(total)
            set_kendaraan(resu.d)
      }, [HOST, STATE_AUTH, state])

      React.useEffect(() => {
            window.document.title = "Kendaraan"
            ambilDataKendaraan()
      }, [ambilDataKendaraan])

      const handleRiwayatPKB = (obj) => {
            direct("/admin/kendaraan/detail/pkb", { state: { ...state, id_kendaraan: obj.id } })
      }

      const handleLihatKartuPemeliharaan = (obj) => {
            direct("/admin/kendaraan/detail/pemeliharaan", { state: obj })
      }

      const cek_total = Object.keys(data_total).length === 0

      return (
            <main>
                  <div className="container-fluid">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='far fa-building'></i> {state.opd.toUpperCase()}</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <button className='btn btn-sm btn-warning' onClick={() => { direct('/admin/kendaraan') }}> Kembali </button>
                        <button className='btn btn-sm btn-primary mx-2' onClick={() => { direct('/admin/kendaraan/tambah', { state: state }) }}>+ Tambah Asset</button>
                        <button className='btn btn-sm btn-info' onClick={() => { direct('/admin/kendaraan/pengguna', { state: state }) }}> <i className='fa fa-eye'></i> Lihat Pengguna</button>
                        <button className='btn btn-sm btn-success mx-2' onClick={() => window.open(HOST + '/admin/kendaraan/export/' + state.id, '_blank')} target="_blank" rel="noreferrer"> <i className='fa fa-file-alt'></i> Export</button>
                        <hr />
                        <div className="row">
                              <div className="col-md-4 mt-3">
                                    <div className="card">
                                          <div className='border border-info'>
                                                <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                      <div className=' flex-fill'>
                                                            <p>Jumlah aset mobil</p>
                                                            <h2 className='text-info fw-bold'>{!cek_total ? data_total.mobil : <Spin />}</h2>
                                                      </div>
                                                      <div className=' flex-fill text-end'>
                                                            <FaCarAlt size={60} className={"text-info"} />
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-md-4 mt-3">
                                    <div className="card">
                                          <div className='border border-success'>
                                                <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                      <div className=' flex-fill'>
                                                            <p>Jumlah aset motor</p>
                                                            <h2 className='text-success fw-bold'>{!cek_total ? data_total.motor : <Spin />}</h2>
                                                      </div>
                                                      <div className=' flex-fill text-end'>
                                                            <FaMotorcycle size={60} className={"text-success"} />
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-md-4 mt-3">
                                    <div className="card">
                                          <div className='border border-dark'>
                                                <div className="d-flex justify-content-start align-items-center p-2 text-start">
                                                      <div className=' flex-fill'>
                                                            <p>Jumlah aset alat berat</p>
                                                            <h2 className='text-dark fw-bold'>{!cek_total ? data_total.alat_berat : <Spin />}</h2>
                                                      </div>
                                                      <div className=' flex-fill text-end'>
                                                            <img src={faAlatBerat} width={70} alt="nologo" />
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className="row">
                              <div className="col-md-12">
                                    <div className="table-responsive">
                                          <table className='tb-daftar-dinas mt-1' >
                                                <thead style={{ fontSize: '14px' }}>
                                                      <tr>
                                                            <th>KODE BARANG</th>
                                                            <th>NAMA BARANG</th>
                                                            <th>NOMOR REGISTER</th>
                                                            <th>MERK/TYPE</th>
                                                            <th>UKURAN/CC</th>
                                                            <th>TAHUN</th>
                                                            <th>WARNA</th>
                                                            <th>NOMOR POLISI</th>
                                                            <th>PENGGUNA</th>
                                                            <th className='text-center'>MENU</th>
                                                      </tr>
                                                </thead>
                                                <tbody style={{ fontSize: '12px' }}>
                                                      {
                                                            !cek_total ?
                                                                  data_kendaraan.length !== 0
                                                                        ?
                                                                        data_kendaraan.map((v, k) => {
                                                                              switch (v.status_lelang) {
                                                                                    case 'penilaian':
                                                                                          return <tr key={k} className='bg-warning fw-bold' title='Kendaraan ini dalam tahap penilaian'>
                                                                                                <td>{v.kode_barang}</td>
                                                                                                <td>{v.nama_barang}</td>
                                                                                                <td>{v.nomor_register}</td>
                                                                                                <td>{v.merk_type}</td>
                                                                                                <td>{v.ukuran_cc}</td>
                                                                                                <td>{v.tahun_pembuatan}</td>
                                                                                                <td>{v.warna}</td>
                                                                                                <td>{v.no_polisi}</td>
                                                                                                <td>{v.id_pengguna === null || v.id_pengguna === '0' ? <span className='badge badge-pill bg-warning text-dark'>Tidak Ada Pengguna</span> : v.nama}</td>
                                                                                                <td className='d-flex justify-content-start align-items-center'>
                                                                                                      <div className="btn-group" role="group">
                                                                                                            <button onClick={() => direct('/admin/kendaraan/opd/detail', { state: { id_kendaraan: v.id, id_opd: state.id_opd, opd: state.opd } })} className='btn btn-sm btn-outline-dark' title='Detail Kendaraan'><FaListAlt size={20} className='text-info' /></button>
                                                                                                            <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Pajak kendaraan'> <FaMoneyCheckAlt size={20} /></button>
                                                                                                            <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt size={20} color='#FF00FF' /></button>
                                                                                                      </div>
                                                                                                </td>
                                                                                          </tr>
                                                                                    case 'lelang':
                                                                                          return <tr key={k} className='bg-secondary fw-bold' title='Kendaraan ini masuk daftar lelang'>
                                                                                                <td className='text-white'>{v.kode_barang}</td>
                                                                                                <td className='text-white'>{v.nama_barang}</td>
                                                                                                <td className='text-white'>{v.nomor_register}</td>
                                                                                                <td className='text-white'>{v.merk_type}</td>
                                                                                                <td className='text-white'>{v.ukuran_cc}</td>
                                                                                                <td className='text-white'>{v.tahun_pembuatan}</td>
                                                                                                <td className='text-white'>{v.warna}</td>
                                                                                                <td className='text-white'>{v.no_polisi}</td>
                                                                                                <td className='text-white'>{v.id_pengguna === null || v.id_pengguna === '0' ? <span className='badge badge-pill bg-warning text-dark'>Tidak Ada Pengguna</span> : v.nama}</td>
                                                                                                <td className='d-flex justify-content-start align-items-center'>
                                                                                                      <div className="btn-group" role="group">
                                                                                                            <button onClick={() => direct('/admin/kendaraan/opd/detail', { state: { id_kendaraan: v.id, id_opd: state.id_opd, opd: state.opd } })} className='btn btn-sm btn-outline-dark' title='Detail Kendaraan'><FaListAlt size={20} className='text-warning' /></button>
                                                                                                            <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Pajak kendaraan'> <FaMoneyCheckAlt size={20} /></button>
                                                                                                            <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt size={20} color='#FF00FF' /></button>
                                                                                                      </div>
                                                                                                </td>
                                                                                          </tr>
                                                                                    case 'selesai':
                                                                                          return (
                                                                                                v.deleted_at === null
                                                                                                      ?
                                                                                                      <tr key={k} className='bg-danger text-white fw-bold' title='Kendaraan ini selesai dilelang'>
                                                                                                            <td>{v.kode_barang}</td>
                                                                                                            <td>{v.nama_barang}</td>
                                                                                                            <td>{v.nomor_register}</td>
                                                                                                            <td>{v.merk_type}</td>
                                                                                                            <td>{v.ukuran_cc}</td>
                                                                                                            <td>{v.tahun_pembuatan}</td>
                                                                                                            <td>{v.warna}</td>
                                                                                                            <td>{v.no_polisi}</td>
                                                                                                            <td>{v.id_pengguna === null || v.id_pengguna === '0' ? <span className='badge badge-pill bg-warning text-dark'>Tidak Ada Pengguna</span> : v.nama}</td>
                                                                                                            <td className='d-flex justify-content-start align-items-center'>
                                                                                                                  <div className="btn-group" role="group">
                                                                                                                        <button onClick={() => direct('/admin/kendaraan/opd/detail', { state: { id_kendaraan: v.id, id_opd: state.id_opd, opd: state.opd } })} className='btn btn-sm btn-outline-dark' title='Detail Kendaraan'><FaListAlt size={20} className='text-warning' /></button>
                                                                                                                        <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Pajak kendaraan'> <FaMoneyCheckAlt size={20} /></button>
                                                                                                                        <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt size={20} color='#FF00FF' /></button>
                                                                                                                  </div>
                                                                                                            </td>
                                                                                                      </tr>
                                                                                                      :
                                                                                                      null
                                                                                          )
                                                                                    default:
                                                                                          return <tr key={k}>
                                                                                                <td>{v.kode_barang}</td>
                                                                                                <td>{v.nama_barang}</td>
                                                                                                <td>{v.nomor_register}</td>
                                                                                                <td>{v.merk_type}</td>
                                                                                                <td>{v.ukuran_cc}</td>
                                                                                                <td>{v.tahun_pembuatan}</td>
                                                                                                <td>{v.warna}</td>
                                                                                                <td>{v.no_polisi}</td>
                                                                                                <td>{v.id_pengguna === null || v.id_pengguna === '0' ? <span className='badge badge-pill bg-warning text-dark'>Tidak Ada Pengguna</span> : v.nama}</td>
                                                                                                <td className='d-flex justify-content-start align-items-center'>
                                                                                                      <div className="btn-group" role="group">
                                                                                                            <button onClick={() => direct('/admin/kendaraan/opd/detail', { state: { id_kendaraan: v.id, id_opd: state.id_opd, opd: state.opd } })} className='btn btn-sm btn-outline-dark' title='Detail Kendaraan'><FaListAlt size={20} className='text-warning' /></button>
                                                                                                            <button onClick={handleRiwayatPKB.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Pajak kendaraan'> <FaMoneyCheckAlt size={20} /></button>
                                                                                                            <button onClick={handleLihatKartuPemeliharaan.bind(this, v)} className='btn btn-sm btn-outline-dark' title='Kartu Pemeliharaan'><FaIdCardAlt size={20} color='#FF00FF' /></button>
                                                                                                      </div>
                                                                                                </td>
                                                                                          </tr>
                                                                              }
                                                                        })
                                                                        :
                                                                        <tr><td colSpan={10} className='text-center '>(Kendaraan Tidak Tersedia.)</td></tr>
                                                                  : <tr><td colSpan={10} className='text-center fw-bold'>{<Spin />}</td></tr>
                                                      }
                                                </tbody>
                                          </table>
                                    </div>
                              </div>
                        </div>
                  </div>
            </main>
      )
}

export default Admin_kendaraan_detail;