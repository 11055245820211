import React from 'react'
import {  useLocation, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { spin as Spin } from '../utils'
import { error } from '../notify'

const Admin_kendaraan_detail_pkb = () => {
      const { state } = useLocation()
      console.log('Data State', state)
      const direct = useNavigate()
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [data_pkb, set_data_pkb] = React.useState([])
      const [is_loading, set_loading] = React.useState(false);

      React.useEffect(() => {
            set_loading(true)
            const getPkb = async () => {
                  const param = {
                        method: 'GET',
                        headers: {
                              "REST-ID-OPD": state.id_opd,
                              "REST-TOKEN": STATE_AUTH.token
                        }
                  }
                  const requ = await fetch(HOST + '/admin/kendaraan/pkb/report/' + state.id_kendaraan, param)
                  try {
                        const resp = await requ.json()
                        const resl = resp;
                        if (resl.s) {
                              set_loading(false);
                              set_data_pkb(resl.d);
                        } else {
                              error({ msg: resl.m })
                        }
                  } catch (e) {
                        error({ msg: e })
                        set_loading(false)
                  }

            }
            getPkb()
      }, [STATE_AUTH, HOST, state])

      const formatRupiah = (rp) => {
            return new Intl.NumberFormat('id-ID',
                  { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(rp);
      }

      return (
            <main>
                  <div className="container-fluid">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='far fa-building'></i> {state.opd.toUpperCase()}</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <button className='btn btn-sm btn-warning' onClick={() => { direct('/admin/kendaraan/detail', { state: state }) }}> <i className='far fa-arrow-alt-circle-left'></i> Kembali</button>
                        <hr />
                        <div className="row">
                              <div className="col-md-12">
                                    <h6 className='p-2 bg-info'><i className='fa fa-list'></i> RINCIAN LAPORAN PEMBAYARAN PAJAK</h6>
                                    <table className='tb-daftar-dinas' style={{ fontSize: '12px' }}>
                                          <thead>
                                                <tr>
                                                      <th>#</th>
                                                      <th>NAMA BARANG</th>
                                                      <th>TANGGAL JATUH TEMPO STNK</th>
                                                      <th>PKB</th>
                                                      <th>STATUS</th>
                                                      <th>BUKTI PEMBAYARAN</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                {
                                                      !is_loading
                                                            ?
                                                            data_pkb.length !== 0
                                                                  ?
                                                                  data_pkb.map((v, i) => {
                                                                        return (
                                                                              <tr key={i}>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>{v.nama_barang}</td>
                                                                                    <td>{v.tgl_tempo}</td>
                                                                                    <td>{formatRupiah(v.rp_pkb)}</td>
                                                                                    <td>
                                                                                          {v.status === "1" && <span className='badge bg-danger'>Belum Terbayar</span>}
                                                                                          {v.status === "2" && <span className='badge bg-success'>Sudah Terbayar</span>}
                                                                                          {v.status === "3" && <span className='badge bg-warning'>Jatuh Tempo</span>}
                                                                                          {v.status === "4" && <span className='badge bg-danger'>Menunggak</span>}
                                                                                    </td>
                                                                                    <td>
                                                                                          {v.file_pkb_terbayar ? <a href={v.file_pkb_terbayar} target="_blank" rel="noreferrer" className='btn btn-outline-success btn-sm '><i className='fa fa-file-alt'></i> File Bukti Pembayaran</a> : '-'}
                                                                                    </td>
                                                                              </tr>
                                                                        )
                                                                  }, [])
                                                                  :
                                                                  <tr><td colSpan={6} className="text-center">(Data Kosong.)</td></tr>

                                                            :
                                                            <tr><td colSpan={7} className='text-center'>{<Spin />}</td></tr>
                                                }

                                          </tbody>
                                    </table>

                              </div>
                        </div>
                  </div>
            </main>
      )
}

export default Admin_kendaraan_detail_pkb