import React from 'react'
import { BsArrowDownCircleFill, BsArrowLeftCircleFill, BsFillFileArrowDownFill, BsInfoCircleFill } from 'react-icons/bs';
import { useNavigate, Navigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context';
import { error, success } from '../notify';
import { ClockLoader } from 'react-spinners'

const Opd_kendaraan_import = () => {
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const direct = useNavigate();
  const [file, set_file] = React.useState("");
  const [loading, set_loading] = React.useState(false)
  const handleImport = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('format_aset', file)

    const sendData = async () => {
      set_loading(true)
      const param = {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token,
          'REST-ID-OPD': STATE_AUTH.id_opd
        },
        body: formData
      }
      const requ = await fetch(HOST + '/user/kendaraan/import', param)
      const resp = await requ.json()
      const resl = resp;
      if (resl.s) {
        success({ msg: resl.m })
        direct('/opd/kendaraan')
        set_loading(false)
      } else {
        error({ msg: resl.m })
        set_loading(false)
      }
    }

    sendData();

  }

  if (!STATE_AUTH.is_login) {
    return <Navigate to='/' />
  }

  return (
    <main>
      <div className="container-fluid mt-3">
        <button className='btn btn-sm btn-warning' onClick={() => { direct('/opd/kendaraan') }}> <BsArrowLeftCircleFill size={18} /> Kembali</button>
        <a className='btn btn-sm btn-success mx-2' href={HOST + '/template_import/format_aset.xlsx'} target="_blank" rel="noreferrer"><BsArrowDownCircleFill size={18} /> Download Format</a>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="card p-3">
              <div className="form-group mb-3">
                <form onSubmit={handleImport}>
                  <h6>Pilih Berkas: </h6>
                  <input type="file" className="form-control" id="FORMAT_ASET" name="FORMAT_ASET" accept='.xls,.xlsx' onChange={e => set_file(e.target.files[0])} required />
                  <br />

                  {
                    loading
                      ?
                      <div className="alert alert-warning">
                        <div className='d-flex justify-content-between align-items-center'><p>Mohon tunggu...<br /> <small>Proses import sementara berlangsung</small></p> <ClockLoader size={32} color={"#765f19"} /></div>
                      </div>
                      :
                      <div className="alert alert-info">
                        <div className='d-flex justify-content-between align-items-center'><p>Keterangan<br /> <small className='text-muted'>Pastikan file yang diupload adalah format file yang telah disediakan.</small></p><BsInfoCircleFill size={32} /></div>
                        <button type='submit' className='btn btn-info btn-lg'>
                          <BsFillFileArrowDownFill size={18} /> Import
                        </button>
                      </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Opd_kendaraan_import