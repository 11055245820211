import React from 'react'
import { useStateAuthContext, useStateHostContext } from '../context';
import { BeatLoader } from 'react-spinners';
import { error } from 'jquery';

const Admin_kendaraan_statistik = () => {
  window.document.title = "Kendaraan"
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [loading, set_loading] = React.useState(false)
  const [data_total, set_data_total] = React.useState({})

  const ambilDataKendaraan = React.useCallback(async () => {
    set_loading(true)
    try {
      const request = await fetch(HOST + '/admin/kendaraan', {
        method: 'GET',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        }
      })

      const response = await request.json()

      if (request.ok) {
        if (response.s) {
          let total = {
            motor: 0,
            mobil: 0,
            alat_berat: 0,
            lelang_penilaian: 0,
            lelang_proses: 0,
            lelang_selesai: 0,
            kondisi_baik: 0,
            kondisi_rusak_ringan: 0,
            kondisi_rusak_berat: 0,
            barang_tersedia: 0,
            barang_hilang: 0,
            barang_pinjam_pakai: 0,
            kendaraan_dihapus: 0,
            total_kendaraan: 0
          }
          response.d.forEach((v) => {
            if (v.jenis === "motor") { total.motor++ }
            if (v.jenis === "mobil") { total.mobil++ }
            if (v.jenis === "alat_berat") { total.alat_berat++ }
            if (v.status_lelang === "penilaian") { total.lelang_penilaian++ }
            if (v.status_lelang === "lelang") { total.lelang_proses++ }
            if (v.status_lelang === "selesai") { total.lelang_selesai++ }
            if (v.status_kondisi === "baik") { total.kondisi_baik++ }
            if (v.status_kondisi === "rusak_ringan") { total.kondisi_rusak_ringan++ }
            if (v.status_kondisi === "rusak_berat") { total.kondisi_rusak_berat++ }
            if (v.status_barang === "tersedia") { total.barang_tersedia++ }
            if (v.status_barang === "hilang") { total.barang_hilang++ }
            if (v.status_barang === "pinjam_pakai") { total.barang_pinjam_pakai++ }
            if (v.status_kendaraan === "dihapus") { total.kendaraan_dihapus++ }
            total.total_kendaraan++
          })
          set_data_total(total)
          set_loading(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }

  }, [HOST, STATE_AUTH])

  React.useEffect(() => {
    ambilDataKendaraan()
  }, [ambilDataKendaraan])

  const cek_total = Object.keys(data_total).length === 0

  console.log(data_total);

  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><i className='fas fa-car'></i> STATISTIK KENDARAAN</strong></h3>
          </div>
        </div>
        <hr />
        <div className="row mt-2">
          {
            !loading
              ?
              !cek_total
                ?
                <>
                <div className="col-md-12">
            <h5>Berdasarkan Jenis Kendaraan</h5>
            <div className='table-responsive'>
              <table className='tb-daftar-dinas mt-1'>
                <thead>
                  <tr>
                    <th width="20%">Jenis Kendaraan</th>
                    <th>Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='td-daftar-dinas'>Motor</td>
                    <td className='td-daftar-dinas'>{data_total.motor}</td>
                  </tr>
                  <tr>
                    <td className='td-daftar-dinas'>Mobil</td>
                    <td className='td-daftar-dinas'>{data_total.mobil}</td>
                  </tr>
                  <tr>
                    <td className='td-daftar-dinas'>Alat Berat</td>
                    <td className='td-daftar-dinas'>{data_total.alat_berat}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <h5>Berdasarkan Status Kondisi</h5>
            <div className='table-responsive'>
              <table className='tb-daftar-dinas mt-1'>
                <thead>
                  <tr>
                    <th width="20%">Kondisi</th>
                    <th>Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='td-daftar-dinas'>Baik</td>
                    <td className='td-daftar-dinas'>{data_total.kondisi_baik}</td>
                  </tr>
                  <tr>
                    <td className='td-daftar-dinas'>Rusak Ringan</td>
                    <td className='td-daftar-dinas'>{data_total.kondisi_rusak_ringan}</td>
                  </tr>
                  <tr>
                    <td className='td-daftar-dinas'>Rusak Berat</td>
                    <td className='td-daftar-dinas'>{data_total.kondisi_rusak_berat}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <h5>Berdasarkan Status Barang</h5>
            <div className='table-responsive'>
              <table className='tb-daftar-dinas mt-1'>
                <thead>
                  <tr>
                    <th width="20%">Barang</th>
                    <th>Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='td-daftar-dinas'>Tersedia</td>
                    <td className='td-daftar-dinas'>{data_total.barang_tersedia}</td>
                  </tr>
                  <tr>
                    <td className='td-daftar-dinas'>Hilang</td>
                    <td className='td-daftar-dinas'>{data_total.barang_hilang}</td>
                  </tr>
                  <tr>
                    <td className='td-daftar-dinas'>Pinjam Pakai</td>
                    <td className='td-daftar-dinas'>{data_total.barang_pinjam_pakai}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <h5>Berdasarkan Status Lelang</h5>
            <div className='table-responsive'>
              <table className='tb-daftar-dinas mt-1'>
                <thead>
                  <tr>
                    <th width="20%">Status Lelang</th>
                    <th>Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='td-daftar-dinas'>Penilaian</td>
                    <td className='td-daftar-dinas'>{data_total.lelang_penilaian}</td>
                  </tr>
                  <tr>
                    <td className='td-daftar-dinas'>Lelang</td>
                    <td className='td-daftar-dinas'>{data_total.lelang_proses}</td>
                  </tr>
                  <tr>
                    <td className='td-daftar-dinas'>Selesai</td>
                    <td className='td-daftar-dinas'>{data_total.lelang_selesai}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <h5>Jumlah asset kendaraan : <span className='fw-bold'>{data_total.total_kendaraan}</span></h5>
          </div>
                </>
                :
                ''
              :
              <div className='col-md-12 text-center'>
                <BeatLoader />
              </div>

          }
          
        </div>
      </div>
    </main>
  )
}

export default Admin_kendaraan_statistik