import React from 'react'
import { useStateAuthContext, useStateHostContext } from '../context';
import { FaFile, FaFileExcel, FaInfoCircle } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import { info } from '../notify';
import { Button, Form, InputGroup, Row } from 'react-bootstrap';

const Admin_laporan_kendaraan_lelang = () => {
  window.document.title = "Kendaraan"
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [data_kendaraan, set_kendaraan] = React.useState([])
  const [loading, set_loading] = React.useState(false)
  const [no_risalah, set_no_risalah] = React.useState(null)

  const handleGetDataKendaraan = React.useCallback(async () => {
    set_loading(true)
    try {
      const request = await fetch(HOST + '/admin/kendaraan/lelang/riwayat', {
        method: 'GET',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        }
      })
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          set_kendaraan(response.d)
          set_loading(false)
        } else {
          info({ msg: response.m })
          set_loading(false)
        }

      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      info({ msg: err.message })
      set_loading(false)
    }

  }, [HOST, STATE_AUTH])

  React.useEffect(() => {
    handleGetDataKendaraan()
  }, [handleGetDataKendaraan])

  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="table-responsive">

              {
                !loading ?
                  <>
                    <div className='alert alert-danger'><FaInfoCircle /> Daftar kendaraan yang sudah dihapus</div>
                    <table className='tb-daftar-dinas mt-1' >
                      <thead style={{ fontSize: '13px' }}>
                        <tr>
                          <th>NO</th>
                          <th>KODE BARANG</th>
                          <th>NAMA KENDARAAN</th>
                          <th>NOMOR REGISTER</th>
                          <th>MERK/TYPE</th>
                          <th>UKURAN/CC</th>
                          <th>TAHUN</th>
                          <th>WARNA</th>
                          <th>NOMOR POLISI</th>
                          <th>FILE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {

                          data_kendaraan.length !== 0
                            ?
                            data_kendaraan.map((v, k) => {
                              return (
                                <tr key={k} className='bg-danger text-white'>
                                  <td>
                                    {k + 1}
                                  </td>
                                  <td>
                                    {v.kode_barang} <br />
                                    <small className='text-muted'>{v.opd}</small>
                                  </td>
                                  <td><small>{v.nama_barang}</small></td>
                                  <td><small>{v.nomor_register}</small></td>
                                  <td>
                                    <small>{v.merk_type}</small>
                                  </td>
                                  <td>{v.ukuran_cc}</td>
                                  <td>
                                    <small>Pembuatan : {v.tahun_pembuatan}</small> <br />
                                    <small>Pembelian : {v.tahun_pembelian}</small>
                                  </td>
                                  <td>{v.warna}</td>
                                  <td>{v.no_polisi}</td>
                                  <td>
                                    <div className="btn-group" role="group">
                                      <button onClick={() => window.open(`${v.file_sk_penghapusan}`, '_blank')} className='btn btn-sm btn-outline-primary' title='File SK Penghapusan'><FaFile size={20} className='text-warning' /></button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                            :
                            <tr><td colSpan={10} className='text-center'>(Kendaraan tidak tersedia)</td></tr>
                        }
                      </tbody>
                    </table>
                  </>
                  :
                  <div className='text-center d-flex justify-content-center align-items-center'>
                    <BeatLoader />
                  </div>
              }
            </div>
            {/* {data_kendaraan.length !== 0 && <button className='btn btn-success btn-sm my-2' onClick={() => window.open(HOST + '/admin/kendaraan/laporan/export/riwayat_lelang', '_blank')}><FaFileExcel /> Export ke Excel</button>} */}
            {data_kendaraan.length !== 0 &&
              <Row>
                <div className='col-md-4'>
                  <InputGroup className="my-3" size='sm'>
                    <Form.Control
                      size='sm'
                      placeholder="Masukan nomor Risalah Lelang"
                      aria-label="Masukan nomor risalah lelang"
                      onChange={(e) => set_no_risalah(e.target.value === '' ? null : e.target.value.replace(/\s/g, ""))}
                      value={no_risalah}
                      aria-describedby="basic-addon2"
                    />
                    <Button
                      variant="success"
                      onClick={() => window.open(HOST + '/admin/kendaraan/laporan/export/riwayat_lelang/' + (no_risalah !== null ? no_risalah.includes('/') ? no_risalah.replace(/\//g, "-") : no_risalah : no_risalah), '_blank')}
                      id="button-addon2">
                      <FaFileExcel /> Export ke Excel
                    </Button>
                  </InputGroup>
                </div>
              </Row>
            }
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_laporan_kendaraan_lelang