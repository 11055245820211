import React, { useEffect, useCallback, useMemo } from 'react'
import { useState } from 'react'
import { FaListAlt, FaSearch } from 'react-icons/fa'
import {  useStateAuthContext, useStateHostContext } from '../context'
import { error, warning } from '../notify'
import { BeatLoader } from 'react-spinners'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
const Kendaraan_cari = () => {
  const { state } = useLocation()
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const _keyword = state?._keyword
  const [data, set_data] = useState([])
  const [loading, set_loading] = useState(false)
  const [keyword, set_keyword] = useState(_keyword ? _keyword : "")
  const direct = useNavigate();

  const AUTH_HEADERS = useMemo(() => {
    return {
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': STATE_AUTH.id_opd
      }
    }
  }, [STATE_AUTH])




  const handleCari = useCallback(async (_keyword) => {
    if (_keyword.length < 3) {
      return warning({msg:'Kata kunci minimal 3 karakter'})
    }
    set_loading(true)
    const _data = new FormData()
    _data.append("keyword", _keyword)
    const requ = await fetch(`${HOST}/admin/kendaraan/cari`, { method: "POST", ...AUTH_HEADERS, body: _data })
    try {
      const resu = await requ.json()
      if (resu.s) {
        set_data(resu.d)
        set_loading(false)
      } else {
        error({ msg: resu.m })
        set_data({})
        set_loading(false)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }

  }, [HOST, AUTH_HEADERS])

  useEffect(() => {
    if (_keyword) {
      handleCari(_keyword)
    }
  }, [_keyword, handleCari])


  if (!STATE_AUTH.is_login) {
    return <Navigate to="/login" />
  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><i className='fas fa-car'></i> CARI KENDARAAN</strong></h3>
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="alert alert-info">
              <div className="row">
                <div className="col-md-4">
                  <div className='d-flex align-items-center'>
                    <FaSearch className='me-1' size={30} />
                    <input type={"text"} className="form-control" autoComplete='off' placeholder="DB1234K" minLength={3} value={keyword} onChange={e => set_keyword(e.target.value)} />
                    <button className='btn btn-info ms-1' onClick={() => handleCari(keyword)}>Cari</button>
                  </div>
                </div>
              </div>
              <small className='text-muted'>Cari berdasarkan plat nomor yang anda ingat</small>
            </div>
          </div>
        </div>
        <div className="row">
          {
            loading
              ?
              <div className="col-md-12 d-flex justify-content-center align-items-center">
                <BeatLoader color={"#09ca"} />
              </div>
              :
              data.length > 0
                ?
                <>
                  <div className='col-md-12'><p className='alert alert-success text-center'><FaSearch className='me-1' size={15} /> Total <strong>{data.length}</strong> data pencarian ditemukan berdasarkan kata kunci</p></div>
                  <div className="col-md-12">
                    <table className='tb-daftar-dinas'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Kode Barang</th>
                          <th>Nama Barang</th>
                          <th>Merk/Type</th>
                          <th>Nomor Polisi</th>
                          <th>OPD</th>
                          <th>Pengguna Kendaraan</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.map((v, i) => {
                            return <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{v.kode_barang}</td>
                              <td>{v.nama_barang}</td>
                              <td>{v.merk_type}</td>
                              <td>{v.no_polisi}</td>
                              <td>{v.opd}</td>
                              <td>{v.nama ? v.nama : 'Tidak tersedia'}</td>
                              <td>
                                <div className="btn-group">
                                  <button className='btn btn-sm' onClick={() => { direct('/admin/kendaraan_cari/detail', { state: { _data: v, _keyword: keyword } }) }}><FaListAlt /></button>
                                </div>
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </>
                :
                ''
          }
        </div>
      </div>
    </main>
  )
}

export default Kendaraan_cari