import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { PulseLoader } from 'react-spinners'
import { error, success } from '../notify'
import { FaInfoCircle } from 'react-icons/fa'
const Admin_pengaturan = () => {

  const direct = useNavigate();

  const { state } = useLocation() //id_pengguna
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();

  const [loading, set_loading] = React.useState(false)
  const [menu_1, set_menu_1] = React.useState(false)
  const [menu_2, set_menu_2] = React.useState(false)
  const [menu_3, set_menu_3] = React.useState(false)


  const getAllMenuByPengguna = React.useCallback(async () => {
    set_loading(true)
    console.log('lagi')
    const requ = await fetch(`${HOST}/admin/pengguna/menu/ambil/${state.id_pengguna}`, { method: 'GET', headers: { "REST-TOKEN": STATE_AUTH.token } })
    const resu = await requ.json()
    if (resu.s) {
      set_menu_1(resu.d.menu_kendaraan_edit === "1" ? true : false)
      set_menu_2(resu.d.menu_kendaraan_hapus === "1" ? true : false)
      set_menu_3(resu.d.menu_update_status === "1" ? true : false)
      set_loading(false)
    } else {
      set_loading(false)
    }
  }, [HOST, STATE_AUTH, state])

  const handleProsesMenu = async (e) => {
    const nilai = { ...e, value: !e.value, id_pengguna: state.id_pengguna }
    const requ = await fetch(`${HOST}/admin/pengguna/menu/set`, { method: 'POST', headers: { "REST-TOKEN": STATE_AUTH.token }, body: JSON.stringify(nilai) })
    try {
      const resu = await requ.json()
      if (resu.s) {
        success({ msg: resu.m })
      } else {
        error({ msg: resu.m })
      }
    } catch (err) {
      error({ msg: err.message })
    }
  }

  const handleSetMenu = async (e) => {
    switch (e.menu) {
      case "menu_kendaraan_edit":
        set_menu_1(!e.value)
        handleProsesMenu(e)
        break;
      case "menu_kendaraan_hapus":
        set_menu_2(!e.value)
        handleProsesMenu(e)
        break;
      case "menu_update_status":
        set_menu_3(!e.value)
        handleProsesMenu(e)
        break;
      default:
    }
  }

  React.useEffect(() => {
    if (state) {
      getAllMenuByPengguna()
    } else {
    }
  }, [state, getAllMenuByPengguna])

  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3 className='p-0 m-0'><strong><i className='fas fa-users'></i> AKSES MENU PENGGUNA</strong></h3>
            <hr />
            <button className='btn btn-sm btn-warning' onClick={() => { direct('/admin/pengguna') }}> <i className='far fa-arrow-alt-circle-left'></i> Kembali</button>
            <hr />
            <div className='alert alert-info'>
              <FaInfoCircle /> <small>Berikan akses modul ke <span className='fw-bold'>{state?.nama_pengguna ? state.nama_pengguna : "Semua Pengguna"}</span></small>
            </div>
            {
              loading
                ?
                <div className='d-flex justify-content-center'>
                  <PulseLoader color='#444' size={24} />
                </div>
                :
                <>
                  <span className='h6'>Modul kendaraan</span>
                  <ul className='list-group'>
                    <li className='list-group-item'>
                      <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="menu_1">Ubah data</label>
                        <input className="form-check-input" type="checkbox" id="menu_1" value={menu_1} checked={menu_1 ? true : false} onChange={(e) => { handleSetMenu({ menu: "menu_kendaraan_edit", value: menu_1 }) }} style={{ cursor: 'pointer' }} />
                      </div>
                      <small className='text-muted'>Memberikan akses kepada user untuk mengubah data kendaraan</small>
                    </li>
                    {/* <li className='list-group-item'>
                      <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="menu_2">Hapus data</label>
                        <input className="form-check-input" type="checkbox" id="menu_2" value={menu_2} checked={menu_2 ? true : false} onChange={(e) => { handleSetMenu({ menu: "menu_kendaraan_hapus", value: menu_2 }) }} style={{ cursor: 'pointer' }} />
                      </div>
                      <small className='text-muted'>Memberikan akses kepada user untuk menghapus data kendaraan</small>
                    </li> */}
                    <li className='list-group-item'>
                      <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="menu_3">Ubah Status</label>
                        <input className="form-check-input" type="checkbox" id="menu_3" value={menu_3} checked={menu_3 ? true : false} onChange={(e) => { handleSetMenu({ menu: "menu_update_status", value: menu_3 }) }} style={{ cursor: 'pointer' }} />
                      </div>
                      <small className='text-muted'>Memberikan akses kepada user untuk mengubah status kendaraan</small>
                    </li>
                    <li className='list-group-item'>
                      <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="menu_2">(Akan Datang)</label>
                        <input className="form-check-input" type="checkbox" id="menu_2" disabled />
                      </div>
                      <small className='text-danger'>Modul belum tersedia</small>
                    </li>
                  </ul>
                </>
            }

          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_pengaturan