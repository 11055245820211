import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { useStateAuthContext, useStateHostContext } from '../context';
import { error } from '../notify';
import logo from '../assets/img/logo.png'
import { BeatLoader } from 'react-spinners';

const Admin_kendaraan = () => {
      const [opd, set_opd] = React.useState([])
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [loading, set_loading] = React.useState(false);
      const direct = useNavigate()
      React.useEffect(() => {
            window.document.title = "Aset Kendaraan"
            const param = {
                  method: 'get',
                  headers: {
                        'REST-TOKEN': STATE_AUTH.token,
                        'REST-ID-OPD': STATE_AUTH.id_opd
                  }
            }
            const get_opd = async () => {
                  set_loading(true)
                  const requ = await fetch(HOST + '/opd', param);
                  const resp = await requ.json()
                  const resl = resp;
                  if (resl.s) {
                        set_opd(resl.d)
                        set_loading(false)
                  } else {
                        error({ msg: resl.m })
                        set_loading(false)
                  }
            }
            get_opd()
            return () => { set_opd([]) }
      }, [STATE_AUTH, HOST])



      if (!STATE_AUTH.is_login) {
            return <Navigate to="/login" />
      }

      return (
            <main>
                  <div className="container-fluid">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><i className='far fa-building'></i> DAFTAR ORGANISASI PERANGKAT DAERAH</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    {
                                          !loading
                                                ?
                                                <div className="table-responsive">
                                                      <table className='tb-daftar-dinas' style={{ fontSize: 14 }}>
                                                            <thead>
                                                                  <tr>
                                                                        <th>#</th>
                                                                        <th>Total Motor</th>
                                                                        <th>Total Mobil</th>
                                                                        <th>Total Alat Berat</th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody>

                                                                  {

                                                                        opd.map((v, i) => {
                                                                              return (
                                                                                    <tr key={i} onClick={() => { direct('/admin/kendaraan/detail', { state: { ...v, id_opd: v.id } }) }} style={{ cursor: 'pointer' }}>
                                                                                          <td><img src={logo} width="2%" alt='' /> {v.opd}</td>
                                                                                          <td className='text-center'>{v.total_motor}</td>
                                                                                          <td className='text-center'>{v.total_mobil}</td>
                                                                                          <td className='text-center'>{v.total_alat_berat}</td>
                                                                                    </tr>
                                                                              )
                                                                        })
                                                                  }
                                                            </tbody>
                                                      </table>
                                                </div>
                                                :
                                                <>
                                                      <div className='d-flex justify-content-center'>
                                                            <BeatLoader />
                                                      </div>
                                                </>
                                    }

                              </div>
                        </div>
                  </div>
            </main>
      )
}

export default Admin_kendaraan
