import {  useStateAuthContext } from '../context'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

const Kendaraan_cari_detail = () => {
  const { state } = useLocation();
  const { STATE_AUTH } = useStateAuthContext();
  const direct = useNavigate();
  const data = state?._data;
  const keyword = state?._keyword;
  if (!STATE_AUTH.is_login) {
    return <Navigate to="/login" />
  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><i className='fas fa-car'></i> DETAIL KENDARAAN</strong></h3>
          </div>
        </div>
        <hr />
        <button className='btn btn-warning' onClick={() => direct('/admin/kendaraan_cari', { state: { _keyword: keyword } })}>Kembali</button>
        <hr />
        <div className="row mt-3">
          <div className="col-md-12">
            <ul className='list-group'>
              <li className='list-group-item d-flex flex-column'>
                <small>Kode Barang</small>
                <p className='p-0 m-0 fw-bold'>{data.kode_barang}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Nama Barang</small>
                <p className='p-0 m-0 fw-bold'>{data.nama_barang}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Merk Type</small>
                <p className='p-0 m-0 fw-bold'>{data.merk_type}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Jenis</small>
                <p className='p-0 m-0 fw-bold'>{data.jenis}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Sub Jenis</small>
                <p className='p-0 m-0 fw-bold'>{data.sub_jenis}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Aset Opd</small>
                <p className='p-0 m-0 fw-bold'>{data.opd}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Pengguna Kendaraan</small>
                <p className='p-0 m-0 fw-bold'>{data.nama ? data.nama : '(Tidak tersedia)'}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Nomor Polisi</small>
                <p className='p-0 m-0 fw-bold'>{data.no_polisi}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Nomor BPKB</small>
                <p className='p-0 m-0 fw-bold'>{data.no_bpkb}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Nomor Mesin</small>
                <p className='p-0 m-0 fw-bold'>{data.no_mesin}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Nomor Rangka</small>
                <p className='p-0 m-0 fw-bold'>{data.no_rangka}</p>
              </li>
              <li className='list-group-item d-flex flex-column'>
                <small>Status Lelang</small>
                <p className='p-0 m-0 fw-bold'>{data.status_lelang ? data.status_lelang : '(Tidak tersedia)'}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Kendaraan_cari_detail