import React from 'react'
import { useStateAuthContext, useStateHostContext } from '../context'
import { FaEdit, FaSave, FaTimes } from 'react-icons/fa'
import { error, success } from '../notify'
import { BeatLoader } from 'react-spinners'

const Opd_profil = () => {
  window.document.title = "Profil Pengguna"
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const form_ref = React.useRef()
  const [is_submit, set_submit] = React.useState(false);
  const [opd]  = React.useState(STATE_AUTH.nama_opd)
  const [nip, set_nip] = React.useState("")
  const [nama_lengkap, set_nama_lengkap] = React.useState("")
  const [nama_pengguna, set_nama_pengguna] = React.useState("")
  const [password, set_password] = React.useState("")
  const [loading, set_loading] = React.useState(false)
  const [menu_view, set_menu_view] = React.useState(1)


  const ambilDataProfile = React.useCallback(async () => {
    set_loading(true)
    try {
      const request = await fetch(`${HOST}/user/profil/` + STATE_AUTH.id_pengguna, {
        method: 'GET',
        headers: {
          "REST-ID-OPD": STATE_AUTH.id_opd,
          "REST-TOKEN": STATE_AUTH.token
        }
      })

      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          set_nip(response.d.nip)
          set_nama_lengkap(response.d.nama_lengkap)
          set_nama_pengguna(response.d.username)
          set_loading(false)
        } else {
          error({ msg: response.m })
          set_loading(false)
        }
      } else {
        throw new Error(response.m)
      }
    }
    catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }
  }, [HOST, STATE_AUTH])


  React.useEffect(() => {
    ambilDataProfile()
  }, [ambilDataProfile])

  const handleSubmit = async (e) => {
    e.preventDefault()
    set_submit(true)
    const formData = new FormData()
    formData.append('ID_PENGGUNA', STATE_AUTH.id_pengguna)
    formData.append('NIP', nip)
    formData.append('NAMA_LENGKAP', nama_lengkap)
    if (password) {
      formData.append('KATA_SANDI', password)
    }

    try {
      const request = await fetch(`${HOST}/user/profil`, {
        method: 'POST',
        headers: {
          "REST-ID-OPD": STATE_AUTH.id_opd,
          "REST-TOKEN": STATE_AUTH.token
        },
        body: formData
      })

      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          set_submit(false)
          ambilDataProfile()
          set_menu_view(1)
        } else {
          error({ msg: response.m })
          set_submit(false)
        }
      }

    } catch (error) {
      error({ msg: error.message })
      set_submit(false)
    }

  }

  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><i className='far fa-user'></i> PROFIL PENGGUNA</strong></h3>
          </div>
        </div>
        <hr />
        <div className="row">
          {
            !loading
              ?
              menu_view === 1
                ?
                <div className="col-md-12">
                  <ul className='list-group'>
                    <li className='list-group-item d-flex flex-column'><small>NIP</small><p className='p-0 m-0 fw-bold'>{nip ? nip : '(Masih Kosong)'}</p></li>
                    <li className='list-group-item d-flex flex-column'><small>NAMA LENGKAP</small><p className='p-0 m-0 fw-bold'>{nama_lengkap ? nama_lengkap : '(Masih Kosong)'}</p></li>
                    <li className='list-group-item d-flex flex-column'><small>NAMA PENGGUNA</small><p className='p-0 m-0 fw-bold'>{nama_pengguna ? nama_pengguna : '(Masih Kosong)'}</p></li>
                    <li className='list-group-item d-flex flex-column'><small>ORGANISASI PERANGKAT DAERAH</small><p className='p-0 m-0 fw-bold'>{opd ? opd : '(Masih Kosong)'}</p></li>
                  </ul>
                  <button className='btn btn-sm btn-warning my-3' onClick={() => set_menu_view(2)}> <FaEdit /> Perbaharui</button>
                </div>
                :
                <form onSubmit={handleSubmit} ref={form_ref}>
                  <div className="col-md-12">
                    <h6 className='p-2 bg-info'> <i className='fa fa-info-circle'></i>FORM UBAH INFORMASI PENGGUNA SYSTEM</h6>
                    <div className="input-group input-group-sm mb-1">
                      <span className="input-group-text" id="nip" style={{ width: '25%' }}>Nip</span>
                      <input name="nip" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" required onChange={e => set_nip(e.target.value)} value={nip} autoComplete="off" />
                    </div>
                    <div className="input-group input-group-sm mb-1">
                      <span className="input-group-text" id="nama" style={{ width: '25%' }}>Nama Lengkap</span>
                      <input name="nama" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" required onChange={e => set_nama_lengkap(e.target.value)} value={nama_lengkap} autoComplete="off" />
                    </div>
                    <div className="input-group input-group-sm mb-1">
                      <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>nama_pengguna</span>
                      <input name="nip" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" value={nama_pengguna} autoComplete="off" readOnly />
                    </div>
                    <div className="input-group input-group-sm mb-1">
                      <span className="input-group-text" id="inputGroup-sizing-sm" style={{ width: '25%' }}>Kata Sandi Baru</span>
                      <input name="password" type="password" className="form-control" minLength="5" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" onChange={e => set_password(e.target.value)} value={password} autoComplete="off" />
                    </div>

                    <button type='button' className='btn btn-warning btn-sm mt-2 me-2' onClick={() => set_menu_view(1)}><FaTimes /> Batal</button>
                    <button type='submit' className='btn btn-primary btn-sm mt-2' disabled={is_submit}>
                      <FaSave /> {is_submit ? 'Mohon tunggu...' : 'Simpan Pembaruan'}
                    </button>
                  </div>
                </form>
              :
              <div className="col-md-12 d-flex justify-content-center">
                <BeatLoader />
              </div>
          }

        </div>
      </div>

    </main>
  )
}

export default Opd_profil;