import React from 'react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useStateAuthContext, useStateHostContext } from '../context'
import { spin as Spin, formatRupiah } from '../utils'
import { Modal, Button } from 'react-bootstrap'
import { success, error } from '../notify'
import { FaArrowLeft, FaCheck, FaFile, FaFileInvoice, FaList, FaRegTimesCircle, FaUpload } from "react-icons/fa";

const Opd_kendaraan_pkb = () => {
      const { state } = useLocation()
      const { HOST } = useStateHostContext();
      const { STATE_AUTH } = useStateAuthContext();
      const [data_pkb, set_data_pkb] = React.useState([])
      const [is_get_data, set_get_data] = React.useState(false);
      const [file, setFile] = React.useState('')
      const [status_verifikasi, set_status_verifikasi] = React.useState('')
      const [show, setShow] = React.useState(false);
      const [is_submit, set_submit] = React.useState(false)
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      const form_ref = React.useRef()
      const direct = useNavigate()
      const [id_pkb, set_id_pkb] = React.useState('')
      const [cek_perubahan, set_perubahan] = React.useState(false)

      React.useEffect(() => {
            set_get_data(true)
            const getPkb = async () => {
                  const param = {
                        method: 'GET',
                        headers: {
                              "REST-ID-OPD": STATE_AUTH.id_opd,
                              "REST-TOKEN": STATE_AUTH.token
                        }
                  }
                  const requ = await fetch(HOST + '/user/kendaraan/pkb/report/' + state.id_kendaraan, param)
                  try {
                        const resp = await requ.json()
                        const resl = resp;
                        set_get_data(false);
                        set_data_pkb(resl.d || []);
                  } catch (e) {
                        console.log('disini_err', e);
                        set_get_data(false)
                  }

            }
            getPkb()
      }, [STATE_AUTH, HOST, state, cek_perubahan])

      const handleFormUpload = (obj) => {
            set_id_pkb(obj.id)
            set_status_verifikasi(obj.status_verifikasi)
            handleShow()
      }

      const checkSameYear = (date) => {
            const now = new Date()
            const tem = new Date(date)
            const now_date = now.getFullYear()
            const tem_date = tem.getFullYear()

            if (now_date === tem_date) {
                  return true
            } else {
                  return false
            }
      }

      const handleSubmit = (e) => {
            e.preventDefault()
            set_submit(true)
            const d = new FormData()
            d.append('FILE_UPLOAD', file)
            d.append('STATUS_VERIFIKASI', status_verifikasi)
            const param = {
                  method: 'post',
                  headers: {
                        "REST-ID-OPD": STATE_AUTH.id_opd,
                        "REST-TOKEN": STATE_AUTH.token
                  },
                  body: d
            }

            const uploadData = async () => {
                  const requ = await fetch(HOST + '/user/kendaraan/pkb/upload/' + id_pkb, param)
                  const resp = await requ.json()
                  const resl = resp;
                  if (resl.s) {
                        success({ msg: resl.m })
                        set_submit(false)
                        form_ref.current.reset()
                        handleClose()
                        set_perubahan(!cek_perubahan)

                  } else {
                        error({ msg: resl.m })
                        set_submit(false)
                  }

            }

            uploadData()
      }

      if (!STATE_AUTH.is_login) {
            return <Navigate to='/' />
      }

      return (
            <main>
                  <div className="container-fluid mt-3">
                        <div className="row mt-3">
                              <div className="col-md-12">
                                    <h3><strong><FaFileInvoice /> LAPORAN PEMBAYARAN PKB</strong></h3>
                              </div>
                        </div>
                        <hr />
                        <button className='btn btn-sm btn-warning' onClick={() => { direct('/opd/kendaraan') }}> <FaArrowLeft/> Kembali</button>
                        <hr />
                        <div className="row">
                              <div className="col-md-12">
                                    <p style={{ fontSize: '14px', fontWeight: 'bold' }} className='p-2 bg-info'><FaList size={16}/> RINCIAN PEMBAYARAN PAJAK KENDARAAN BERMOTOR (PKB)</p>
                                    <table className='table table-striped table-bordered table-hover'>
                                          <thead style={{ fontSize: '14px' }}>
                                                <tr>
                                                      <th>#</th>
                                                      <th>NAMA BARANG</th>
                                                      <th>TANGGAL JATUH TEMPO PKB</th>
                                                      <th>PKB</th>
                                                      <th>STATUS</th>
                                                      <th>BUKTI PEMBAYARAN</th>
                                                      <th></th>
                                                </tr>
                                          </thead>
                                          <tbody style={{ fontSize: '12px' }}>
                                                {
                                                      !is_get_data
                                                            ?
                                                            data_pkb.length !== 0
                                                                  ?
                                                                  data_pkb.map((v, i) => {
                                                                        return (
                                                                              <tr key={i}>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>{v.nama_barang}</td>
                                                                                    <td>{v.tgl_tempo}</td>
                                                                                    <td>{formatRupiah(v.rp_pkb)}</td>
                                                                                    <td>
                                                                                          {
                                                                                                v.status_verifikasi !== '1'
                                                                                                      ?
                                                                                                      v.status_verifikasi !== '3'
                                                                                                            ?
                                                                                                            <>
                                                                                                                  {v.status === "1" && <span className='badge bg-danger'>Belum Terbayar</span>}
                                                                                                                  {v.status === "2" && <span className='badge bg-success'>Sudah Terbayar</span>}
                                                                                                                  {v.status === "3" && <span className='badge bg-warning'>Jatuh Tempo</span>}
                                                                                                                  {v.status === "4" && <span className='badge bg-danger'>Menunggak</span>}
                                                                                                            </>
                                                                                                            :
                                                                                                            <span className='badge bg-danger' style={{ cursor: 'pointer' }} onClick={() => alert('Bukti laporan yang diupload tidak sesuai, silahkan upload kembali')}>Ditolak</span>
                                                                                                      :
                                                                                                      <span className='badge bg-warning'>Menunggu verifikasi</span>
                                                                                          }
                                                                                    </td>
                                                                                    <td>{v.file_pkb_terbayar ? <a href={v.file_pkb_terbayar} target="_blank" rel="noreferrer" className='btn btn-outline-success btn-sm '><FaFile /> File Bukti Pembayaran</a> : '-'}</td>
                                                                                    <td>
                                                                                          {
                                                                                                v.status !== '2'
                                                                                                      ?
                                                                                                      checkSameYear(v.tgl_tempo_temp)
                                                                                                            ?
                                                                                                            <button className='btn btn-outline-info btn-sm ' onClick={handleFormUpload.bind(this, v)}><FaUpload /> Upload Bukti Pembayaran</button>
                                                                                                            :
                                                                                                            v.status === '4'
                                                                                                                  ?
                                                                                                                  <button className='btn btn-outline-info btn-sm ' onClick={handleFormUpload.bind(this, v)}><FaUpload /> Upload Bukti Pembayaran</button>
                                                                                                                  :
                                                                                                                  <button className='btn btn-outline-danger btn-sm ' onClick={() => alert('Pembayaran Berikut-nya Tahun Depan')}> <FaRegTimesCircle /> Upload Bukti Pembayaran</button>
                                                                                                      :
                                                                                                      <button className='btn btn-outline-success btn-sm ' onClick={() => alert('Bukti Pembyaran Berhasil Diupload')}> <FaCheck /> Berhasil Diupload</button>
                                                                                          }
                                                                                    </td>
                                                                              </tr>
                                                                        )
                                                                  }, [])
                                                                  :
                                                                  <tr><td colSpan={7} className="text-center">(Riwayat Pembayaran PKB Tidak ada)</td></tr>
                                                            :
                                                            <tr><td colSpan={7} className='text-center'>{<Spin />}</td></tr>
                                                }
                                          </tbody>
                                    </table>
                              </div>
                        </div>
                  </div>
                  <div className="container-fluid">
                        <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                    <Modal.Title>UPLOAD BUKTI PEMBAYARAN</Modal.Title>
                              </Modal.Header>
                              <form onSubmit={handleSubmit} ref={form_ref}>
                                    <Modal.Body>
                                          <div className="form-group">
                                                <input type="file" className="form-control" onChange={e => setFile(e.target.files[0])} required />
                                          </div>
                                          <small className='text-mutted'>Pastikan file yang diupload format extensi-nya adadalah : (*.jpg, *.png, *.pdf)</small>
                                    </Modal.Body>
                                    <Modal.Footer>
                                          <Button variant="secondary" onClick={handleClose}>
                                          <FaRegTimesCircle size={18}/> Batal
                                          </Button>
                                          {
                                                <Button type="submit" variant="primary" disabled={is_submit}>
                                                      {is_submit ? 'Mohon tunggu...' : <><FaUpload size={18}/> Upload</>}
                                                </Button>
                                          }
                                    </Modal.Footer>
                              </form>
                        </Modal>
                  </div>
            </main>
      )
}

export default Opd_kendaraan_pkb