import React from 'react'
import ADMIN_LAPORAN_KENDARAAN_SEMUA from './admin_laporan_kendaraan_semua';
import ADMIN_LAPORAN_KENDARAAN_HILANG from './admin_laporan_kendaraan_hilang';
import ADMIN_LAPORAN_KENDARAAN_RUSAK_RINGAN from './admin_laporan_kendaraan_rusak_ringan';
import ADMIN_LAPORAN_KENDARAAN_RUSAK_BERAT from './admin_laporan_kendaraan_rusak_berat';
import ADMIN_LAPORAN_KENDARAAN_PINJAM_PAKAI from './admin_laporan_kendaraan_pinjam_pakai';

const Admin_laporan_kendaraan = () => {
  const [menu_laporan, set_menu_laporan] = React.useState(1)
  return (
    <main>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong><i className='fas fa-car'></i> LAPORAN KENDARAAN</strong></h3>
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          <div className="col-md-12">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button onClick={() => set_menu_laporan(1)} className="nav-link active" id="kendaraan-tab" data-bs-toggle="tab" data-bs-target="#kendaraan" type="button" role="tab" aria-controls="kendaraan" aria-selected="true">Semua Kendaraan</button>
                <button onClick={() => set_menu_laporan(2)} className="nav-link" id="kendaraan_rusak_ringan-tab" data-bs-toggle="tab" data-bs-target="#kendaraan_rusak_ringan" type="button" role="tab" aria-controls="kendaraan_rusak_ringan" aria-selected="false">Rusak Ringan</button>
                <button onClick={() => set_menu_laporan(3)} className="nav-link" id="kendaraan_rusak_berat-tab" data-bs-toggle="tab" data-bs-target="#kendaraan_rusak_berat" type="button" role="tab" aria-controls="kendaraan_berat" aria-selected="false">Rusak Berat</button>
                <button onClick={() => set_menu_laporan(4)} className="nav-link" id="kendaraan_hilang-tab" data-bs-toggle="tab" data-bs-target="#kendaraan_hilang" type="button" role="tab" aria-controls="kendaraan_hilang" aria-selected="false">Hilang</button>
                <button onClick={() => set_menu_laporan(5)} className="nav-link" id="kendaraan_pinjam_pakai-tab" data-bs-toggle="tab" data-bs-target="#kendaraan_pinjam_pakai" type="button" role="tab" aria-controls="pinjam_pakai" aria-selected="false">Pinjam Pakai</button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">

              <div className="tab-pane fade show  active border-start border-bottom border-end pb-3 pt-1" id="kendaraan" role="tabpanel" aria-labelledby="kendaraan-tab" tabIndex="0">
                {menu_laporan === 1 && <ADMIN_LAPORAN_KENDARAAN_SEMUA />}
              </div>
              <div className="tab-pane fade border-start border-bottom border-end pb-3 pt-1" id="kendaraan_rusak_ringan" role="tabpanel" aria-labelledby="kendaraan_rusak_ringan-tab" tabIndex="0">
                {menu_laporan === 2 && <ADMIN_LAPORAN_KENDARAAN_RUSAK_RINGAN />}
              </div>
              <div className="tab-pane fade border-start border-bottom border-end pb-3 pt-1" id="kendaraan_rusak_berat" role="tabpanel" aria-labelledby="kendaraan_rusak_berat-tab" tabIndex="0">
                {menu_laporan === 3 && <ADMIN_LAPORAN_KENDARAAN_RUSAK_BERAT />}
              </div>
              <div className="tab-pane fade border-start border-bottom border-end pb-3 pt-1" id="kendaraan_hilang" role="tabpanel" aria-labelledby="kendaraan_hilang-tab" tabIndex="0">
                {menu_laporan === 4 && <ADMIN_LAPORAN_KENDARAAN_HILANG />}
              </div>
              <div className="tab-pane fade border-start border-bottom border-end pb-3 pt-1" id="kendaraan_pinjam_pakai" role="tabpanel" aria-labelledby="kendaraan_pinjam_pakai-tab" tabIndex="0">
                {menu_laporan === 5 && <ADMIN_LAPORAN_KENDARAAN_PINJAM_PAKAI />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_laporan_kendaraan