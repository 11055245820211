import React from 'react'
import Route from './routes';
import { useStateAuthContext } from './context';
import { useEffect } from 'react';


const App = () => {
  const { DISPATCH_AUTH } = useStateAuthContext()
  useEffect(() => {
    const dataLocal = localStorage.getItem("_SIKENDIS_USER_LOGIN_")
    if (dataLocal) {
      const oldData = localStorage.getItem("_SIKENDIS_USER_DATA_")
      const restoreData = JSON.parse(oldData)
      DISPATCH_AUTH({ type: 'LOGIN', payload: restoreData })
    }
  }, [DISPATCH_AUTH])
  return (
    <Route />
  )
}

export default App
